import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { BannerConstant } from "../../constant";
import { errorToast, successToast } from "../../../utils";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* getBanner(payload) {
  const { body, setTotalCount } = payload;
  try {
    yield put(actions.GetBannerAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url.getBanner);
    if (res?.statusCode === 200) {
      yield put(actions.GetBannerAction.success(res?.data?.banner));
      // setTotalCount(res?.data?.totalEvent);
    } else {
      yield put(actions.GetBannerAction.error(res?.message));
    }
  } catch (error) {
    yield put(actions.GetBannerAction.error(error));
  }
}

export function* addBanner(payload) {
  const { body, navigate } = payload;
  try {
    yield put(actions.AddBannerAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url?.addBanner);
    if (res?.statusCode === 200) {
      yield put(actions.AddBannerAction.success());
      successToast(res?.message || "");
      navigate("/banner");
    } else {
      yield put(actions.AddBannerAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.AddBannerAction.error(error));
  }
}

export function* deleteBanner(payload) {
  const { id } = payload;
  try {
    yield put(actions.DeleteBannerAction.loading(true));
    yield delay(1000);
    const deleteUrl = `${url.deleteBanner}/${id}`;
    const res = yield call(apis.delete_form, deleteUrl);
    if (res?.statusCode === 200) {
      successToast(res?.message);
      yield put(actions.DeleteBannerAction.success(res));
      yield put(actions.GetBannerAction.request());
    } else {
      errorToast(res?.message || "");
    }
  } catch (error) {
    yield put(actions.DeleteBannerAction.error(error));
  }
}

function* BannerSaga() {
  yield all([takeEvery(BannerConstant.GET_BANNER_REQUEST, getBanner)]);
  yield all([takeEvery(BannerConstant.ADD_BANNER_REQUEST, addBanner)]);
  yield all([takeEvery(BannerConstant.DELETE_BANNER_REQUEST, deleteBanner)]);
}

export default BannerSaga;
