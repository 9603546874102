import { VenueConstant } from "../constant/VenueConstant";

const GetVenueAction = {
  request: (body, setTotalCount) => ({
    type: VenueConstant.GET_VENUE_REQUEST,
    body,
    setTotalCount,
  }),
  loading: () => ({
    type: VenueConstant.GET_VENUE_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: VenueConstant.GET_VENUE_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: VenueConstant.GET_VENUE_ERROR,
    loading: false,
  }),
};


const GetVenueActionById = {
  request: (id,body) => ({
    type: VenueConstant.GET_VENUE_REQUEST_By_Id,
    id,
    body,
   
  }),
  loading: () => ({
    type: VenueConstant.GET_VENUE_LOADING_By_Id,
    loading: true,
  }),
  success: (res) => ({
    type: VenueConstant.GET_VENUE_SUCCESS_By_Id,
    loading: false,
    res,
  }),
  error: () => ({
    type: VenueConstant.GET_VENUE_ERROR_By_Id,
    loading: false,
  }),
};

const AddVenueAction = {
  request: (body, navigate) => ({
    type: VenueConstant.ADD_VENUE_REQUEST,
    body,
    navigate,
  }),
  loading: () => ({
    type: VenueConstant.ADD_VENUE_LOADING,
    loading: true,
  }),
  success: () => ({
    type: VenueConstant.ADD_VENUE_SUCCESS,
    loading: false,
  }),
  error: () => ({
    type: VenueConstant.ADD_VENUE_ERROR,
    loading: false,
  }),
};

const EditVenueAction = {
  request: (id, body, navigate) => ({
    type: VenueConstant.EDIT_VENUE_REQUEST,
    id,
    body,
    navigate,
  }),
  loading: () => ({
    type: VenueConstant.EDIT_VENUE_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: VenueConstant.EDIT_VENUE_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: VenueConstant.EDIT_VENUE_ERROR,
    loading: false,
  }),
};

const DeleteVenueAction = {
  request: (id) => ({
    type: VenueConstant.DELETE_VENUE_REQUEST,
    id,
  }),
  loading: () => ({
    type: VenueConstant.DELETE_VENUE_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: VenueConstant.DELETE_VENUE_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: VenueConstant.DELETE_VENUE_ERROR,
    loading: false,
  }),
};

export { GetVenueAction, AddVenueAction, EditVenueAction, DeleteVenueAction, GetVenueActionById };
