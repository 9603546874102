export const BannerConstant = {
  GET_BANNER_REQUEST: "GET_BANNER_REQUEST",
  GET_BANNER_LOADING: "GET_BANNER_LOADING",
  GET_BANNER_SUCCESS: "GET_BANNER_SUCCESS",
  GET_BANNER_ERROR: "GET_BANNER_ERROR",

  ADD_BANNER_REQUEST: "ADD_BANNER_REQUEST",
  ADD_BANNER_LOADING: "ADD_BANNER_LOADING",
  ADD_BANNER_SUCCESS: "ADD_BANNER_SUCCESS",
  ADD_BANNER_ERROR: "ADD_BANNER_ERROR",

  DELETE_BANNER_REQUEST: "DELETE_BANNER_REQUEST",
  DELETE_BANNER_LOADING: "DELETE_BANNER_LOADING",
  DELETE_BANNER_SUCCESS: "DELETE_BANNER_SUCCESS",
  DELETE_BANNER_ERROR: "DELETE_BANNER_ERROR",
};
