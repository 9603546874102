import { NotificationConstant } from "../constant/NotificationConstant"

const initialState = {
    isLoading : false, 
    getNotifData: null
}

export const NotificationReducer = (state = initialState, action) => {
    switch(action.type){
        case NotificationConstant.GET_NOTIFICATION_LOADING:
            return{
                ...state,
                isLoading: action.loading
            }
        case NotificationConstant.GET_NOTIFICATION_SUCCESS:
            return{
                ...state,
                isLoading: action.loading,
                getNotifData: action.res
            }
        case NotificationConstant.GET_NOTIFICATION_ERROR:
            return{
                ...state,
                isLoading: action.loading,
                getNotifData: null
            }
        case NotificationConstant.ADD_NOTIFICATION_LOADING:
            return{
                ...state,
                isLoading: action.loading
            }
        case NotificationConstant.ADD_NOTIFICATION_SUCCESS:
            return{
                ...state,
                isLoading: action.loading,
            }
        case NotificationConstant.ADD_NOTIFICATION_ERROR:
            return{
                ...state,
                isLoading: action.loading,
            }
        default :
        return state;
    }
}