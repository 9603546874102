import { AuthConstant } from "../../constant";
import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { deleteCookie, setCookie } from "cookies-next";
import { errorToast, successToast } from "../../../utils";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* loginCall(payload) {
  const { navigate, body } = payload;
  try {
    yield put(actions.LoginAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url?.Login);
    if (res?.statusCode === 200) {
      yield put(actions.LoginAction.success());
      console.log(res, "login");
      setCookie("token", res?.data?.accessToken);
      setCookie("UserData", JSON.stringify(res?.data));
      successToast(res?.message);
      navigate("/events", { replace: true });
    } else {
      yield put(actions.LoginAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.LoginAction.error(error));
  }
}

export function* logoutCall(payload) {
  const { navigate } = payload;
  try {
    yield put(actions.LogoutAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.getApi, null, url?.Logout);
    if (res?.statusCode === 200) {
      yield put(actions.LogoutAction.success());
      deleteCookie("token");
      deleteCookie("UserData");
      yield put(actions.modalVisible.modalClose());
      navigate("/");
    } else {
      yield put(actions.LogoutAction.error(res?.message));
    }
  } catch (error) {
    yield put(actions.LogoutAction.error(error));
  }
}

export function* forgoutCall(payload) {
  const { navigate, body } = payload;
  try {
    yield put(actions.ForgotAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url?.forgot);
    if (res?.statusCode === 200) {
      yield put(actions.ForgotAction.success());
      // successToast(res?.message);
      navigate("/reset-password");
    } else {
      yield put(actions.ForgotAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.ForgotAction.error(error));
  }
}

export function* ResetPasswordCall(payload) {
  const { navigate, body } = payload;
  try {
    yield put(actions.ResetPassword.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url?.reset);
    if (res?.statusCode === 200) {
      console.log("res,resetttt", res);
      yield put(actions.ResetPassword.success());
      successToast(res?.message);

      navigate("/");
    } else {
      yield put(actions.ResetPassword.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.ResetPassword.error(error));
  }
}

export function* ChangePasswordCall(payload) {
  const { body } = payload;
  try {
    yield put(actions.ChangePassword.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url?.changepassword);
    if (res?.statusCode === 200) {
      successToast(res?.message);
    } else {
      yield put(actions.ChangePassword.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.ChangePassword.error(error));
  }
}

export function* updateProfileCall(payload) {
  const { body, setProfile } = payload;
  try {
    yield put(actions.updateProfileAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.putApi, body, url?.updateProfile);
    if (res?.statusCode === 200) {
      yield put(actions.getProfileAction.success(res?.data));
      setCookie("UserData", JSON.stringify(res?.data));
      successToast(res?.message);
      setProfile(true);
    } else {
      yield put(actions.updateProfileAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.updateProfileAction.error(error));
  }
}

export function* getProfileCall() {
  try {
    yield put(actions.getProfileAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.getApi, null, url?.getProfile);

    if (res?.statusCode === 200) {
      yield put(actions.getProfileAction.success(res?.data));
    } else {
      yield put(actions.getProfileAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.getProfileAction.error(error));
  }
}

function* AuthSaga() {
  yield all([takeEvery(AuthConstant.LOGIN_REQUEST, loginCall)]);
  yield all([takeEvery(AuthConstant.LOGOUT_REQUEST, logoutCall)]);
  yield all([takeEvery(AuthConstant.FORGOT_REQUEST, forgoutCall)]);
  yield all([
    takeEvery(AuthConstant.RESET_PASSWORD_REQUEST, ResetPasswordCall),
  ]);
  yield all([
    takeEvery(AuthConstant.CHANGE_PASSWORD_REQUEST, ChangePasswordCall),
  ]);
  yield all([
    takeEvery(AuthConstant.UPDATE_PROFILE_REQUEST, updateProfileCall),
  ]);
  yield all([takeEvery(AuthConstant.GET_PROFILE_REQUEST, getProfileCall)]);
}

export default AuthSaga;
