import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import Title from "../../component/Title";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import Button from "../../component/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteVenueAction,
  EditVenueAction,
  GetVenueAction,
  modalVisible,
} from "../../redux/actions";
import { Modules } from "../../constants";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import LockSharpIcon from "@mui/icons-material/LockSharp";
import LockOpenSharpIcon from "@mui/icons-material/LockOpenSharp";
import { Pagination, SwitchBtn } from "../../component";
import SearchBar from "../../component/SearchBar";
import { isValidInput } from "../../utils/validations";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VenueManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const venueData = useSelector((state) => state.VenueReducer.venueData);
  console.log("venueData event", venueData);

  const { venueDelete } = useSelector((state) => state.VenueReducer);
  let totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const headings = [
    "#",
    "Venue Info",
    "Google Location",
    "Detail",
    "City/Location",
    "Venue Type",
    "Venue Feature",
    "Following",
    "Manager Info",
    "Is Venue Priority",
    "Venue Timing",
    "Floor Plan",
    "Banners",
    "Blocked/Unblocked",
    "Action",
  ];

  const handleVenueGet = () => {
    const body = {
      page: page,
      limit: 10,
      search: debouncedSearchTerm.trim(),
    };
    dispatch(GetVenueAction.request(body, setTotalCount));
  };

  // block fun
  const handlerBlock = (id, block) => {
    let body = {
      isBlocked: !block,
    };
    dispatch(EditVenueAction.request(id, body));
    handleVenueGet();
  };

  // priority fun
  const handlerPriority = (id, isPriority) => {
    let body = {
      isPriority: !isPriority,
    };
    dispatch(EditVenueAction.request(id, body));
    handleVenueGet();
  };
  useEffect(() => {
    handleVenueGet();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (venueDelete?.statusCode === 200) {
      handleVenueGet();
    }
  }, [venueDelete]);

  return (
    <Layout>
      <div className="serachMain">
        <Title title="Venue Management" />
        <SearchBar
          value={searchTerm}
          searchTerm={searchTerm}
          onCross={() => setSearchTerm("")}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          onChange={(val) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
      </div>

      <div className="rightSide">
        <Button name="+" onClick={() => navigate("/venueadd")} />
      </div>
      {/* <Table data={venueData} headings={headings} /> */}
      <div className="table-responsive centerCls">
        <table>
          <thead>
            {headings.map((headings) => (
              <th key={headings}>{headings}</th>
            ))}
          </thead>
          <tbody>
            {venueData?.length ? (
              venueData?.map((item, i) => {
                return (
                  <tr key={item?.id}>
                    <td>{(page - 1) * 10 + i + 1}</td>
                    <td>
                      <p>
                        <strong>Name: </strong> {item?.name || ""}
                      </p>
                      <p>
                        <strong>Email: </strong> {item?.email || ""}
                      </p>
                      <p>
                        <strong>Contact: </strong>
                        {item?.dialCode || ""} {item?.phoneNo || ""}
                      </p>
                      <p>
                        <strong>Address: </strong> {item?.address}
                      </p>
                    </td>
                    <td className="center">
                      <GpsFixedIcon
                        onClick={() =>
                          dispatch(
                            modalVisible.modalOpen(Modules.MAP, {
                              id: item?._id,
                            })
                          )
                        }
                      />
                      {/* <div id="map"></div> */}

                      {/* <GpsFixedIcon
                        onClick={() => {
                          setMap(true);
                          setOpen(true);
                        }}
                      />
                      <Modal
                        open={open}
                        onClose={() => setOpen(true)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <h1 onClick={() => setOpen(false)}>close</h1>
                          jkjk */}
                      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB6emRUjW-F6fFkBSVh_srIOwy2KRk3xJA" }}
        defaultCenter={{ lat: 30.7017355, lng: 76.7247759 }}
        defaultZoom={15}
      >
        <CustomMarker
          lat={59.9413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact> */}
                      {/* </Box>
                      </Modal> */}
                    </td>
                    <td className="bigNote">
                      <Tooltip title={item?.details}>
                        <p>{item?.details || ""}</p>
                      </Tooltip>
                    </td>
                    <td>
                      {item?.venueCity?.value || ""},<br />
                      {item?.venueLocation?.subValue?.name || ""}
                    </td>
                    <td>{item?.venueType?.value || ""}</td>
                    <td>{item?.venueFeatures[0]?.value || "kj"}</td>
                    <td>{item?.following}</td>
                    <td>
                      <p>
                        <strong>Name: </strong> {item?.managerId?.name || ""}
                      </p>
                      <p>
                        <strong>Email: </strong> {item?.managerId?.email || ""}
                      </p>
                      <p>
                        <strong>Address: </strong>{" "}
                        {item?.managerId?.dialCode || ""}{" "}
                        {item?.managerId?.phoneNo || ""}
                      </p>
                    </td>
                    <td className="center">
                      <SwitchBtn
                        checked={item?.isPriority}
                        onChange={() =>
                          handlerPriority(item?._id, item?.isPriority)
                        }
                      />
                    </td>
                    <td>
                      {item?.openTime} - {item?.closeTime}
                      {/* {moment(item?.openTime).format("LT")} -{" "} */}
                      {/* {moment(item?.closeTime).format("LT")} */}
                      {/* {item?.openTime || ""} - {item?.closeTime || ""} */}
                    </td>

                    <td className="center">
                      <LocationSearchingIcon
                        onClick={() =>
                          dispatch(
                            modalVisible.modalOpen(Modules.FLOOR, {
                              id: item?._id,
                            })
                          )
                        }
                      />
                    </td>
                    <td className="center">
                      <PhotoSizeSelectActualIcon
                        onClick={() =>
                          dispatch(
                            modalVisible.modalOpen(Modules.BANNER, {
                              id: item?._id,
                            })
                          )
                        }
                      />
                    </td>
                    <td className="center pauseBooking">
                      {item?.isBlocked ? (
                        <Tooltip title="Blocked">
                          <IconButton
                            onClick={() =>
                              handlerBlock(item?._id, item?.isBlocked)
                            }
                          >
                            <LockSharpIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Unblocked">
                          <IconButton>
                            <LockOpenSharpIcon
                              onClick={() =>
                                handlerBlock(item?._id, item?.isBlocked)
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </td>
                    <td>
                      <Tooltip title="Edit">
                        <IconButton>
                          <EditIcon
                            style={{ fill: "green" }}
                            onClick={() => navigate(`/editvenue/${item?._id}`)}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton>
                          <DeleteIcon
                            style={{ fill: "red" }}
                            onClick={() =>
                              dispatch(DeleteVenueAction.request(item?._id))
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="no_data">
                <td colSpan="16">
                  <div className="noData">
                    <figure>
                      <img src="./images/noData.jpg" alt="No Data" />
                    </figure>
                    <p>No venues Yet</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        module={venueData}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </Layout>
  );
};

export default VenueManagement;
