import React from "react";
import { useDispatch } from "react-redux";
import {
  DeleteEntitiesAction,
  GetEntitiesAction,
  modalVisible,
} from "../../redux/actions";

const DeleteModal = () => {
  const dispatch = useDispatch();

  const deleteItem = (_id) => {
    dispatch(DeleteEntitiesAction.request(_id));
  };

  return (
    <div className="logout_modal">
      <h3>Are you sure, you want to delete?</h3>
      <div className="btn_div">
        <button className="btn" onClick={deleteItem}>
          Yes
        </button>
        <button
          className="out-btn"
          onClick={() => dispatch(modalVisible.modalClose())}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
