import Routing from "./Route";
import "./App.scss";
import { useEffect } from "react";
import { getCookie } from "cookies-next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth } from "./redux/actions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = window.location.pathname;

  const isAuthorised = useSelector((state) => state.CommonReducer.isAuthorised);
  // useEffect(() => {
  //   const token = getCookie("token");
  //   if (token) {
  //     dispatch(resetAuth.authorise());
  //     if (isAuthorised) {
  //       navigate("/events");
  //     }
  //   } else {
  //     navigate("/");
  //   }
  // }, [isAuthorised]);

  return <Routing />;
}

export default App;

// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { GetEntitiesAction } from "./redux/actions";
// import { useDispatch, useSelector } from "react-redux";

// const App = () => {
//   const dispatch = useDispatch();

//   const { entitiesData } = useSelector((state) => state.EntitiesReducer);
//   console.log(entitiesData, "entitiesData");
//   const [venueType, setVenueType] = useState("");
//   const [venueTypeArr, setVenueTypeArr] = useState([]);

//   useEffect(() => {
//     let body = {
//       type: [0, 2, 3],
//     };
//     dispatch(GetEntitiesAction.request(body));
//   }, []);

//   // useEffect(() => {
//   //  if(entitiesData?.length){
//   //   entitiesData?.filter((item) => item?.type === 3){
//   //     console.log(item,'itemmm');
//   //   }
//   //  }
//   // }, [entitiesData])

//   useEffect(() => {
//     if (entitiesData?.length) {
//       const filteredItems = entitiesData.filter((item) => item.type === 3);
//       console.log(filteredItems, "filteredItems");
//       setVenueTypeArr(filteredItems);
//     }
//   }, [entitiesData]);
//   return (
//     <div>
//       <FormControl sx={{ width: "100%" }}>
//         <InputLabel id="demo-simple-select-standard-label">
//           Choose Venue Type
//         </InputLabel>
//         <Select
//           value={venueType}
//           label="venue type"
//           onChange={(e) => setVenueType(e.target.value)}
//         >
//           {venueTypeArr.map((item) => {
//             return <MenuItem value={item?._id}>{item?.value}</MenuItem>;
//           })}
//         </Select>
//       </FormControl>
//     </div>
//   );
// };

// export default App;
