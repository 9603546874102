import React from 'react'
import { useDispatch } from 'react-redux'
import { LogoutAction, modalVisible } from '../../redux/actions'
import { useNavigate } from 'react-router-dom'

const LogoutModal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  return (
    <div className='logout_modal'>
      <h3>Are you sure, you want to logout?</h3>
      <div className='btn_div'>
        <button className='btn' onClick={() =>   
           
            dispatch(LogoutAction.request(navigate))} >Yes</button>
        <button className='out-btn' onClick={() => dispatch(modalVisible.modalClose())}>No</button>
      </div>
    </div>
  )
}

export default LogoutModal
