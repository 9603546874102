import React, { useState, useEffect } from "react";
import Layout from "../../layout";
import Button from "../../component/Button";
import Title from "../../component/Title";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AddBannerAction, uploadImageAction } from "../../redux/actions";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import { InputField } from "../../component";

const AddHomeBanner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [imageType, setImageType] = useState(0);
  const [bannerImages, setBannerImages] = useState("");

  const { imageRes } = useSelector((state) => state.CommonReducer);

  const uploadBannerImage = (val) => {
    setImageType(1);
    const files = val.target;
    const file = files?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadImageAction.request(formData));
  };

  const formik = useFormik({
    initialValues: {
      bannerImage: bannerImages,
      bannerName: "",
      description: "",
    },
    onSubmit: async () => {
      formik.setSubmitting(true);
      const body = {
        bannerImage: bannerImages,
        eventId: "64c65de0f0ca3143fc464f4a",
        name: formik.values.bannerName,
        description: formik.values.description,
        isActive: false,
      };
      dispatch(AddBannerAction.request(body, navigate));
    },
  });

  useEffect(() => {
    if (imageRes && imageType === 1) {
      setBannerImages(imageRes.image);
    }
  }, [imageRes]);

  return (
    <Layout>
      <div className="addData">
        <Title title="Add Banner" />
        <div className="rightSide">
          <Button name="<" title="Back" onClick={() => navigate("/banner")} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="formGroup">
            <div className="formControl">
              <label>Banner Name</label>
              <InputField
                placeholder="Enter Name"
                name="bannerName"
                value={formik.values.bannerName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.bannerName && formik.errors.bannerName
                }
              />
            </div>
            <div className="formControl">
              <label>Banner Description</label>
              <InputField
                placeholder="Enter Name"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </div>
            <div className="formControl">
              <label>Upload Banners (max 6)</label>
              {bannerImages?.length < 6 ? (
                <label htmlFor="icon-button-file5">
                  <TextField
                    inputProps={{
                      accept: "image/*",
                    }}
                    id="icon-button-file5"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={uploadBannerImage}
                  />
                  <div className="upload">
                    <AddIcon />
                  </div>
                </label>
              ) : null}
              <ul className="imgMain">
                {bannerImages?.length ? (
                  <li style={{ width: "100%" }}>
                    <div className="uploaded">
                      <CloseIcon
                        onClick={() => setBannerImages("")}
                        className="close"
                      />
                      <figure>
                        <img src={bannerImages} alt="" />
                      </figure>
                    </div>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          <div className="btnRow">
            <Button name="Save" />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddHomeBanner;
