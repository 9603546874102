import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { NotificationConstant } from "../../constant/NotificationConstant";
import { errorToast, successToast } from "../../../utils";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* getNotification(payload) {
  const { body, setTotalCount } = payload;
  try {
    yield put(actions.GetNotificationAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url.getNotification);
    if (res?.statusCode === 200) {
      yield put(actions.GetNotificationAction.success(res?.data?.notification));
      setTotalCount(res?.data?.totalNotification);
    } else {
      yield put(actions.GetNotificationAction.error(res?.message));
    }
  } catch (error) {
    yield put(actions.GetNotificationAction.error(error));
  }
}

export function* addNotificationCall(payload) {
  const { body, navigate } = payload;
  try {
    yield put(actions.AddNotificationAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url.addNotification);
    if (res?.statusCode === 200) {
      successToast(res?.message);
      navigate("/notification");
    } else {
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.AddNotificationAction.error(error));
  }
}

function* NotificationSaga() {
  yield all([
    takeEvery(NotificationConstant.GET_NOTIFICATION_REQUEST, getNotification),
  ]);
  yield all([
    takeEvery(
      NotificationConstant.ADD_NOTIFICATION_REQUEST,
      addNotificationCall
    ),
  ]);
}

export default NotificationSaga;
