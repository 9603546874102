import { BookingConstant } from "../constant";

const initialState = {
  bookingLoading: false,
  bookingData: null,
};

export const BookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BookingConstant.GET_BOOKING_LOADING:
      return {
        ...state,
        bookingLoading: action.loading,
      };
    case BookingConstant.GET_BOOKING_SUCCESS:
      return {
        ...state,
        bookingLoading: action.loading,
        bookingData: action.res,
      };
    case BookingConstant.GET_BOOKING_ERROR:
      return {
        ...state,
        bookingLoading: action.loading,
        bookingData: null,
      };

    default:
      return state;
  }
};
