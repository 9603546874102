import { BannerConstant } from "../constant/BannerConstant";

const initialState = {
  bannerLoading: false,
  bannerData: null,
  bannerDelete: null,
};

export const BannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case BannerConstant.GET_BANNER_LOADING:
      return {
        ...state,
        bannerLoading: action.loading,
      };
    case BannerConstant.GET_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: action.loading,
        bannerData: action.res,
      };
    case BannerConstant.GET_BANNER_ERROR:
      return {
        ...state,
        bannerLoading: action.loading,
        bannerData: null,
      };
    case BannerConstant.ADD_BANNER_LOADING:
      return {
        ...state,
        bannerLoading: action.loading,
      };
    case BannerConstant.ADD_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: action.loading,
      };
    case BannerConstant.ADD_BANNER_ERROR:
      return {
        ...state,
        bannerLoading: action.loading,
      };
    case BannerConstant.DELETE_BANNER_LOADING:
      return {
        ...state,
        bannerLoading: action.loading,
      };
    case BannerConstant.DELETE_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: action.loading,
        bannerDelete: action.res,
      };
    case BannerConstant.DELETE_BANNER_ERROR:
      return {
        ...state,
        bannerLoading: action.loading,
        bannerDelete: null,
      };
    default:
      return state;
  }
};
