import { all } from "redux-saga/effects";
import AuthSaga from "./AuthSaga";
import NotificationSaga from "./NotificationSaga";
import EventSaga from "./EventSaga";
import CmsSaga from "./CmsSaga";
import CommonSaga from "./CommonSaga";
import VenueSaga from "./VenueSaga";
import EntitiesSaga from "./EntitiesSaga";
import BannerSaga from "./Bannersaga";
import BookingSaga from "./BookingSaga";
import FaqSaga from "./FaqSaga";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    NotificationSaga(),
    EventSaga(),
    CmsSaga(),
    CommonSaga(),
    VenueSaga(),
    EntitiesSaga(),
    BannerSaga(),
    BookingSaga(),
    FaqSaga(),
  ]);
}
