import { EntitiesConstant } from "../constant/EntitiesConstant";

const GetEntitiesAction = {
    request: (body) => ({
        type: EntitiesConstant.GET_Entities_REQUEST,
        body
    }),
    loading: () => ({
        type: EntitiesConstant.GET_Entities_LOADING,
        loading: true
    }),
    success: (res) => ({
        type: EntitiesConstant.GET_Entities_SUCCESS,
        loading: false,
        res
    }),
    error: () => ({
        type: EntitiesConstant.GET_Entities_ERROR,
        loading: false
    })
}

const AddEntitiesAction = {
    request: (body, navigate) => ({
        type: EntitiesConstant.ADD_Entities_REQUEST,
        body,
        navigate
    }),
    loading: () => ({
        type: EntitiesConstant.ADD_Entities_LOADING,
        loading: true
    }),
    success: (res) => ({
        type: EntitiesConstant.ADD_Entities_SUCCESS,
        loading: false,
        res
    }),
    error: () => ({
        type: EntitiesConstant.ADD_Entities_ERROR,
        loading: false
    })
}

const EditEntitiesAction = {
    request: (id, body, navigate) => ({
        type: EntitiesConstant.EDIT_Entities_REQUEST,
        id,
         body, 
         navigate
    }),
    loading: () => ({
        type: EntitiesConstant.EDIT_Entities_LOADING,
        loading: true
    }),
    success: (res) => ({
        type: EntitiesConstant.EDIT_Entities_SUCCESS,
        loading: false,
        res
    }),
    error: () => ({
        type: EntitiesConstant.EDIT_Entities_ERROR,
        loading: false
    })
}

const DeleteEntitiesAction = {
    request: (id) => ({
        type: EntitiesConstant.DELETE_Entities_REQUEST,
        id
    }),
    loading: () => ({
        type: EntitiesConstant.DELETE_Entities_LOADING,
        loading: true
    }),
    success: (res) => ({
        type: EntitiesConstant.DELETE_Entities_SUCCESS,
        loading: false,
        res
    }),
    error: () => ({
        type: EntitiesConstant.DELETE_Entities_ERROR,
        loading: false
    })
}

export {GetEntitiesAction, AddEntitiesAction, DeleteEntitiesAction, EditEntitiesAction}