import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useSelector } from "react-redux";

const MenuModal = () => {
  const venueData = useSelector((state) => state.VenueReducer.venueData);
  const {modalPayload} = useSelector((state) => state.CommonReducer);

  const baseUrl = "./images";
  const settings = {
    // customPaging: function (i) {
    //   return <img src={`${baseUrl}/banner0${i + 1}.jpg`} alt="" />;
    // },
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
 
  return (
    <div className="menuModal">
      <h2> Menus</h2>
      {venueData?.map((item) => {
        return (
          <Slider {...settings}>
          {item?._id === modalPayload?.id?    item?.menu?.map((x) => {
            return(
              <div>
                <figure>
                <img src={x} alt='img'/>
                </figure>
              </div>
           
            )
          }) : null}
          
          </Slider>
        )
      })}
      </div>
      );
    };
    
    export default MenuModal;
    