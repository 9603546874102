import React from 'react'
import Layout from '../layout'
import Title from '../component/Title'

const Dashboard = () => {
  return (
    <Layout>
      <Title title="Dashbaord" />
    </Layout>
  )
}

export default Dashboard
