import Booking from "./booking/Booking";
import ChangePassword from "./ChangePassword";
import Cms from "./Cms";
import Dashboard from "./Dashboard";
import EventManagement from "./event/EventManagement";
import Profile from "./Profile";
import Setting from "./setting/Setting";
import Transaction from "./Transaction";
import UserManagement from "./UserManagement";
import VenueManagement from "./venue/VenueManagement";
import Notification from "./notification/Notification";
import AddNotification from "./notification/AddNotification";
import AddEvent from "./event/AddEvent";
import VenueAdd from "./venue/VenueAdd";
import AddCityLocation from "./setting/AddCityLocation";
import AddGuestList from "./booking/AddGuestList";
import AddComment from "./booking/AddComment";
import HomeBanner from "./banner/HomeBanner";
import AddHomeBanner from "./banner/AddHomeBanner";
import AddFaq from "./AddFaq";
import "./pages.scss";

export default {
  Booking,
  ChangePassword,
  Cms,
  Dashboard,
  EventManagement,
  Notification,
  Profile,
  Setting,
  Transaction,
  UserManagement,
  VenueManagement,
  AddNotification,
  AddEvent,
  VenueAdd,
  AddCityLocation,
  AddGuestList,
  AddComment,
  HomeBanner,
  AddHomeBanner,
  AddFaq,
};
