import React from "react";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import FestivalIcon from "@mui/icons-material/Festival";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import PaidIcon from "@mui/icons-material/Paid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DvrIcon from "@mui/icons-material/Dvr";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

const Sidebar = () => {
  const navigate = useNavigate();
  const sidebar = [
    // {
    //   name: "Dashboard",
    //   icon: <DashboardIcon />,
    //   link: "/dashboard",
    // },
    // {
    //   name: "Users",
    //   icon: <GroupIcon />,
    //   link: "/users",
    // },
    {
      id: 3,
      name: "Events",
      icon: <FestivalIcon />,
      link: "/events",
    },
    {
      id: 4,
      name: "Venues",
      icon: <EditCalendarIcon />,
      link: "/venues",
    },
    // {
    //   name: "Transactions",
    //   icon: <PaidIcon />,
    //   link: "/transaction",
    // },
    // {
    //   id: 6,
    //   name: "Booking",
    //   icon: <CalendarMonthIcon />,
    //   link: "/booking",
    // },
    {
      id: 11,
      name: "Banner",
      icon: <ViewCarouselIcon />,
      link: "/banner",
    },
    {
      id: 7,
      name: "Cms",
      icon: <DvrIcon />,
      link: "/cms",
    },

    {
      id: 8,
      name: "Setting",
      icon: <SettingsIcon />,
      link: "/setting",
    },
    {
      id: 9,
      name: "Notification",
      icon: <NotificationsIcon />,
      link: "/notification",
    },
    {
      id: 10,
      name: "Self Service",
      icon: <PersonIcon />,
      link: "/profile",
    },
  ];
  return (
    <aside>
      <ul>
        {sidebar.map((item) => {
          return (
            <li key={item?.id} onClick={() => navigate(item?.link)}>
              <div className="sideText">
                <div className="sIcon">{item.icon}</div>
                <div className="sTxt">
                  <h5>{item?.name}</h5>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
