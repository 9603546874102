import React from "react";
import Layout from "../../layout";
import { Title, InputField, SwitchBtn, Button } from "../../component";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  AddVenueAction,
  GetEntitiesAction,
  uploadImageAction,
} from "../../redux/actions";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { errorToast } from "../../constants";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  EditVenueAction,
  GetVenueActionById,
} from "../../redux/actions/VenueAction";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Autocomplete } from "@react-google-maps/api";

const VenueAdd = () => {
  const { id } = useParams();
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const [venuefeature, setVenuefeature] = useState([]);
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [venueType, setVenueType] = useState("");
  const [error, setError] = useState(false);
  const [floorPlanImage, setFloorPlanImage] = useState("");
  const [myImages, setMyImages] = useState([]);
  const [venuePhoneCode, setVenuePhoneCode] = useState("+91");
  const [managerPhoneCode, setManagerPhoneCode] = useState("+91");
  const [CityArr, setCityArr] = useState([]);
  const [venueTypeArr, setVenueTypeArr] = useState([]);
  const [venuefeatureArr, setVenuefeatureArr] = useState([]);
  const [imageType, setImageType] = useState(0);
  const [bannerImages, setBannerImages] = useState([]);

  const { imageRes } = useSelector((state) => state.CommonReducer);

  console.log("imageRes:", imageRes);

  const { venueData } = useSelector((state) => state.VenueReducer);
  const { editVenue } = useSelector((state) => state.VenueReducer);
  const { entitiesData } = useSelector((state) => state.EntitiesReducer);
  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(() => {
    let body = {
      type: [0, 2, 3],
    };
    dispatch(GetEntitiesAction.request(body));
  }, []);

  useEffect(() => {
    if (entitiesData?.length) {
      const venueTypeFilter = entitiesData.filter((item) => item.type === 3);
      const cityFilter = entitiesData.filter((item) => item.type === 2);
      const venueFeatureFilter = entitiesData.filter((item) => item.type === 0);
      setVenueTypeArr(venueTypeFilter);
      setCityArr(cityFilter);
      setVenuefeatureArr(venueFeatureFilter);
    }
  }, [entitiesData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: pathname === `/editvenue/${id}` ? editVenue?.name : "",
      email: pathname === `/editvenue/${id}` ? editVenue?.email : "",
      venueContact: pathname === `/editvenue/${id}` ? editVenue?.phoneNo : "",
      // address: pathname === `/editvenue/${id}` ? editVenue?.address : "",
      geoLocation: pathname === `/editvenue/${id}` ? editVenue?.address : "",
      detail: pathname === `/editvenue/${id}` ? editVenue?.details : "",
      managerName:
        pathname === `/editvenue/${id}` ? editVenue?.managerId?.name : "",
      managerEmail:
        pathname === `/editvenue/${id}` ? editVenue?.managerId?.email : "",
      managerPhone:
        pathname === `/editvenue/${id}` ? editVenue?.managerId?.phoneNo : "",
      managerPassword: pathname === `/editvenue/${id}` ? "" : "",
      knownFor: pathname === `/editvenue/${id}` ? editVenue?.knownFor : "",
      isVenuePriority:
        pathname === `/editvenue/${id}` ? editVenue?.isPriority : false,

      startTime:
        editVenue?.openTime && pathname === `/editvenue/${id}`
          ? editVenue?.openTime
          : "",
      endTime:
        editVenue?.closeTime && pathname === `/editvenue/${id}`
          ? editVenue?.closeTime
          : "",
      // editVenue?.closeTime && pathname === `/editvenue/${id}`
      //   ? moment(editVenue?.closeTime).format("YYYY-MM-DDTHH:mm")
      //   : "",
      city: "",
      area: "",
      long: "",
      lat: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required!"),
      email: Yup.string().required("Required!"),
      geoLocation: Yup.string().required("Required!"),
      detail: Yup.string().required("Required!"),
      managerName: Yup.string().required("Required!"),
      managerEmail: Yup.string().required("Required!"),
      managerPassword: !id
        ? Yup.string().required("Required!")
        : Yup.string().notRequired(),
      knownFor: Yup.string().required("Required!"),
      venueContact: Yup.string()
        .required("Required!")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      managerPhone: Yup.string()
        .required("Required!")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      endTime: Yup.string().required("Required!"),
      startTime: Yup.string().required("Required!"),
      // isVenuePriority:Yup.boolean()
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      // let images = [];

      // if (myImages?.length) {
      //   await Promise.all(
      //     myImages?.map(async (item) => {
      //       console.log(item,'itemm');
      //       const formData = new FormData();
      //       formData.append("file", item);
      //       let headers = {
      //         Accept: "application/json",
      //       };
      //       try {
      //         const res = await fetch(UPLOAD_URL + UPLOAD_MEDIA, {
      //           method: "POST",
      //           headers,
      //           body: formData,
      //         });
      //         let response = await res.json();
      //         if (response?.statusCode === 200) {
      //           images?.push(response?.data?.image);
      //         }
      //       } catch (error) {
      //         console.log(error, ">>>>>>>>>");
      //       }
      //     })
      //   );
      // }

      if (
        !bannerImages?.length ||
        !floorPlanImage ||
        !venueType ||
        !venuefeature?.length ||
        !area
      ) {
        return;
      }

      const body = {
        email: formik.values.email,
        phoneNo: formik.values.venueContact,
        dialCode: venuePhoneCode,
        managerInfo: {
          email: formik.values.managerEmail,
          phoneNo: formik.values.managerPhone,
          dialCode: managerPhoneCode,
          name: formik.values.managerName,
          ...(!id && { password: formik.values.managerPassword }),
        },
        name: formik.values.name,
        address: formik.values.geoLocation,
        details: formik.values.detail,
        knownFor: formik.values.knownFor,
        venueType: venueType,
        venueCity: city,
        venueLocation: area,
        venueFeatures: venuefeature,
        openTime: formik.values.startTime,
        closeTime: formik.values.endTime,
        // closeTime: moment(formik.values.endTime)
        //   .utc()
        //   .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        isAvailable: false,
        isPriority: formik.values.isVenuePriority,
        menu: myImages,
        floorPlan: floorPlanImage,
        banners: bannerImages,
        venueLatitude: formik.values.lat,
        venueLongitude: formik.values.long,
      };
      console.log("body----", body.openTime);
      if (pathname === `/editvenue/${id}`) {
        dispatch(EditVenueAction.request(id, body, navigate));
      } else {
        dispatch(AddVenueAction.request(body, navigate));
      }
    },
  });

  // to upload  image to api
  const uploadImage = (val) => {
    setImageType(1);
    const files = val.target;
    const file = files?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadImageAction.request(formData));
  };

  const uploadMenuImage = (val) => {
    setImageType(2);
    const files = val.target;
    const file = files?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadImageAction.request(formData));
  };

  const uploadBannerImage = (val) => {
    setImageType(3);
    const files = val.target;
    const file = files?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadImageAction.request(formData));
  };

  useEffect(() => {
    if (imageRes && imageType === 1) {
      console.log("1");
      setFloorPlanImage(imageRes?.image);
    } else if (imageRes && imageType === 2) {
      console.log("2");
      setMyImages((prevImages) => [...prevImages, imageRes.image]);
    } else if (imageRes && imageType === 3) {
      console.log("3");
      setBannerImages((prevImages) => [...prevImages, imageRes.image]);
    }
  }, [imageRes]);

  useEffect(() => {
    if (pathname === `/editvenue/${id}`) {
      const body = {
        page: 1,
        limit: 10,
        search: "",
      };
      dispatch(GetVenueActionById.request(id, body));
      // dispatch(EditVenueAction.request(id));
    }
  }, [id]);

  useEffect(() => {
    if (pathname === `/editvenue/${id}`) {
      setCity(editVenue?.venueCity?._id);
      setVenuefeature(
        editVenue?.venueFeatures?.length
          ? editVenue?.venueFeatures?.map((item) => item._id)
          : []
      );
      setArea(editVenue?.venueLocation?.subValue?._id);
      setVenueType(editVenue?.venueType?._id);

      setFloorPlanImage(editVenue?.floorPlan);
      setBannerImages(editVenue?.banners);
      setMyImages(editVenue?.menu);
      formik.setFieldValue("long", editVenue?.venueLongitude);
      formik.setFieldValue("lat", editVenue?.venueLatitude);
    }
  }, [editVenue]);
  // console.log(formik.values.lat, formik.values.long, "kkkkkkk");

  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files) {
      const uploadedImages = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setMyImages([...myImages, ...uploadedImages]);
    }
  };

  const handleCrossClick = async (e, i) => {
    e.stopPropagation();
    if (bannerImages.includes(i)) {
      let arr = [];
      arr = bannerImages.filter((name) => name !== i);
      setBannerImages(arr);
    }
  };

  const handleCrossMenuClick = async (e, i) => {
    e.stopPropagation();
    if (myImages.includes(i)) {
      let arr = [];
      arr = myImages.filter((name) => name !== i);
      setMyImages(arr);
    }
  };

  const handleChangePhone = (phone, country) => {
    formik.setFieldValue("venueContact", phone?.replace(country.dialCode, ""));
    setVenuePhoneCode(country?.dialCode);
  };

  const handleChangeManagerPhone = (phone, country) => {
    formik.setFieldValue("managerPhone", phone?.replace(country.dialCode, ""));
    setManagerPhoneCode(country?.dialCode);
  };

  const onLoad = (autocompleteObj) => {
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = async (e, val) => {
    if (autocomplete) {
      console.log(autocomplete, "");
      let place = await autocomplete.getPlace();
      console.log(place, "place");
      let address = place.address_components;
      let city, state, zip, addLine2;
      address.forEach(function (component) {
        console.log(component, "compo");
        let types = component.types;
        if (types.indexOf("locality") > -1) {
          city = component.long_name;
        }

        formik.setFieldValue("geoLocation", `${place?.formatted_address}`);

        if (types.indexOf("long") > -1) {
          addLine2 = component?.geometry.location.lng;
        }

        if (types.indexOf("administrative_area_level_1") > -1) {
          state = component.long_name;
        }

        if (types.indexOf("lat") > -1) {
          zip = component.geometry.location.lat;
        }
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("zipCode", zip || "");
        formik.setFieldValue("area", state || "");
        formik.setFieldValue("addressLine2", addLine2 || "");
        formik.setFieldValue("long", place.geometry.location.lng());
        formik.setFieldValue("lat", place.geometry.location.lat());
      });

      // get lat
      var lat = place.geometry.location.lat();
      // get lng
      var lng = place.geometry.location.lng();
      var latLong = {
        lat: lat,
        lng: lng,
      };
    }
  };

  return (
    <Layout>
      <div className="addData">
        {pathname === `/editvenue/${id}` ? (
          <Title title="Edit Venue" />
        ) : (
          <Title title="Add Venue" />
        )}
        <div className="rightSide">
          <Button name="<" title="Back" onClick={() => navigate("/venues")} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="formGroup">
            <div className="formControl">
              <label>Venue Name </label>
              <InputField
                placeholder="Enter Venue Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className="formControl">
              <label>Venue Email </label>
              <InputField
                placeholder="Enter Venue Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className="formControl">
              <label>Venue Contact </label>
              <PhoneInput
                enableSearch={true}
                value={venuePhoneCode + formik.values.venueContact}
                country={"us"}
                inputClass="phoneInput"
                buttonClass="phoneBtn"
                placeholder="Phone Number"
                onChange={(phone, country) => handleChangePhone(phone, country)}
                onBlur={formik.handleBlur}
                inputStyle={{ marginBottom: "10px" }}
              />
              {formik.touched.venueContact && formik.errors.venueContact ? (
                <p className="errorMsg">
                  {formik.touched.venueContact && formik.errors.venueContact}
                </p>
              ) : (
                ""
              )}
            </div>
            {/* <div className="formControl">
              <label>Venue Address </label>
             
              <InputField
                placeholder="Enter Venue Address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                helperText={formik.touched.address && formik.errors.address}
              />
       
            </div> */}
            <div className="formControl">
              <label>Details </label>
              <InputField
                placeholder="Enter Details"
                name="detail"
                value={formik.values.detail}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                helperText={formik.touched.detail && formik.errors.detail}
              />
            </div>
            <div className="formControl">
              <label>Venue Address </label>
              <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                <InputField
                  placeholder="Enter Venue Address"
                  name="geoLocation"
                  value={formik.values.geoLocation}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  helperText={
                    formik.touched.geoLocation && formik.errors.geoLocation
                  }
                />
              </Autocomplete>
            </div>

            <div className="location_div">
              <div className="formControl venueSelect">
                <label style={{ marginBottom: 0 }}>City </label>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    City
                  </InputLabel>
                  <Select
                    value={city}
                    label="city"
                    onChange={(e) => setCity(e.target.value)}
                  >
                    {CityArr.map((item) => {
                      return (
                        <MenuItem value={item?._id}>{item?.value}</MenuItem>
                      );
                    })}
                  </Select>
                  {error && !city ? <p className="errorMsg">Required!</p> : ""}
                </FormControl>
              </div>
              <div className="formControl">
                <label style={{ marginBottom: 0 }}>Area </label>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Area
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={area}
                    placeholder="Select Area"
                    label="Age"
                    onChange={(e) => setArea(e.target.value)}
                    variant="standard"
                  >
                    {CityArr &&
                      CityArr.find((item) => item._id === city)?.subValue.map(
                        (sub) => (
                          <MenuItem key={sub._id} value={sub._id}>
                            {sub.name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>

                {error && !area ? (
                  <p className="errorMsg">Area is required</p>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="formControl venueSelect">
              <label>Venue Type </label>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Choose Venue Type
                </InputLabel>
                <Select
                  value={venueType}
                  label="venue type"
                  onChange={(e) => setVenueType(e.target.value)}
                >
                  {venueTypeArr.map((item) => {
                    return <MenuItem value={item?._id}>{item?.value}</MenuItem>;
                  })}
                </Select>
                {error && !venueType ? (
                  <p className="errorMsg">Required!</p>
                ) : (
                  ""
                )}
              </FormControl>
              {/* <SelectField
                label={"Enter Location"}
                names={venueTypeArr}
                value={venueType}
                setValue={setVenueType}
                // typeCount={3}
                // setType={setType}
              />
              {error && !venueType ? (
                <p className="errorMsg">Venue Type is required</p>
              ) : (
                ""
              )} */}
            </div>

            <div className="formControl venueSelect">
              <label>Venue Features </label>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Choose Venue Features (Multiple)
                </InputLabel>
                <Select
                  multiple
                  value={venuefeature}
                  label="venue feature"
                  onChange={(e) => setVenuefeature(e.target.value)}
                >
                  {venuefeatureArr.map((item) => {
                    return <MenuItem value={item?._id}>{item?.value}</MenuItem>;
                  })}
                </Select>
                {error && !venuefeature?.length ? (
                  <p className="errorMsg">Required!</p>
                ) : (
                  ""
                )}
              </FormControl>
              {/* <SelectField
                label={"Enter Venue Features"}
                names={venuefeatureArr}
                multiple={true}
                value={venuefeature}
                setValue={setVenuefeature}
                typeCount={0}
                setType={setType}
              />
              {error && !venuefeature?.length ? (
                <p className="errorMsg">Venue Features is required</p>
              ) : (
                ""
              )} */}
            </div>
            <div className="formControl dateTimeValue">
              <label style={{ marginBottom: 0 }}>Venue Timing </label>
              <div className="vTiming">
                <div className="formControl">
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"time"}
                    name="startTime"
                    variant="outlined"
                    fullWidth
                    id="startTime"
                    value={formik.values.startTime}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.startTime && formik.errors.startTime
                    }
                  />
                </div>
                <div className="formControl dateTimeValue">
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"time"}
                    variant="outlined"
                    fullWidth
                    name="endTime"
                    id="endTime"
                    value={formik.values.endTime}
                    onChange={formik.handleChange}
                    helperText={formik.touched.endTime && formik.errors.endTime}
                  />
                </div>
              </div>
            </div>
            <div className="formControl">
              <label>Manager Name</label>
              <InputField
                placeholder="Enter Manager Name"
                name="managerName"
                value={formik.values.managerName}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                helperText={
                  formik.touched.managerName && formik.errors.managerName
                }
              />
            </div>
            <div className="formControl">
              <label>Manager Email</label>
              <InputField
                placeholder="Enter Manager Email"
                name="managerEmail"
                value={formik.values.managerEmail}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                helperText={
                  formik.touched.managerEmail && formik.errors.managerEmail
                }
              />
            </div>
            <div className="formControl">
              <label>Manager Phone </label>
              <PhoneInput
                enableSearch={true}
                value={managerPhoneCode + formik.values.managerPhone}
                country={"us"}
                inputClass="phoneInput"
                buttonClass="phoneBtn"
                placeholder="Phone Number"
                onChange={(phone, country) =>
                  handleChangeManagerPhone(phone, country)
                }
                onBlur={formik.handleBlur}
                inputStyle={{ marginBottom: "10px" }}
              />
              {formik.touched.managerPhone && formik.errors.managerPhone ? (
                <p className="errorMsg">
                  {formik.touched.managerPhone && formik.errors.managerPhone}
                </p>
              ) : (
                ""
              )}
            </div>
            {id ? (
              ""
            ) : (
              <div className="formControl">
                <label>Manager Password </label>
                <InputField
                  placeholder="Enter Manager Password"
                  name="managerPassword"
                  value={formik.values.managerPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  helperText={
                    formik.touched.managerPassword &&
                    formik.errors.managerPassword
                  }
                />
              </div>
            )}
            <div className="formControl">
              <label>Is Venue Priority </label>
              <SwitchBtn
                name="isVenuePriority"
                onChange={formik.handleChange}
                checked={formik.values.isVenuePriority}
              />
            </div>
            <div className="formControl">
              <label>Known For</label>
              <InputField
                placeholder="Enter Known For"
                name="knownFor"
                value={formik.values.knownFor}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                helperText={formik.touched.knownFor && formik.errors.knownFor}
              />
            </div>
            {/* <div className="formControl">
              <label>Upload Menu (max 3)</label>
              {myImages?.length < 3 ? (
                <label htmlFor="icon-button-file">
                  <TextField
                    inputProps={{
                      accept: "image/*",
                    }}
                    id="icon-button-file"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={uploadMenuImage}
                  />
                  <div className="upload">
                    <AddIcon />
                  </div>
                </label>
              ) : null}
              <ul className="imgMain">
                {myImages?.length
                  ? myImages?.map((item) => {
                      return (
                        <li>
                          <div className="uploaded">
                            <CloseIcon
                              onClick={(e) => handleCrossMenuClick(e, item)}
                              className="close"
                            />
                            <figure>
                              <img src={item} alt="" />
                            </figure>
                          </div>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div> */}
            <div className="formControl">
              <label>Upload Floor image</label>
              {!floorPlanImage ? (
                <label htmlFor="icon-button-file3">
                  <TextField
                    inputProps={{
                      accept: "image/*",
                    }}
                    id="icon-button-file3"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={(el) => {
                      if (el.target.files[0].type.includes("image")) {
                        el.target.files?.length &&
                          // setFloorPlanImage(Array.from(el.target.files));
                          uploadImage(el);
                      } else {
                        errorToast("Only images are allowed");
                      }
                    }}
                  />
                  <div className="upload">
                    <AddIcon />
                  </div>
                </label>
              ) : (
                <div className="uploadedImg">
                  <CloseIcon onClick={() => setFloorPlanImage("")} />
                  <figure>
                    <img src={floorPlanImage || "ksdf"} alt="" />
                  </figure>
                </div>
              )}
              {error && !floorPlanImage ? (
                <p className="errorMsg">Required!</p>
              ) : (
                ""
              )}
            </div>
            <div className="formControl">
              <label>Upload Banners (max 6)</label>
              <ul className="imgMain">
                {bannerImages?.length
                  ? bannerImages?.map((item) => {
                      console.log(item);
                      return (
                        <li>
                          <div className="uploaded">
                            <CloseIcon
                              onClick={(e) => handleCrossClick(e, item)}
                              className="close"
                            />
                            <figure>
                              <img src={item} alt="" />
                            </figure>
                          </div>
                        </li>
                      );
                    })
                  : null}
              </ul>
              {bannerImages?.length < 6 ? (
                <label htmlFor="icon-button-file5">
                  <TextField
                    inputProps={{
                      accept: "image/*",
                    }}
                    id="icon-button-file5"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={uploadBannerImage}
                  />
                  <div className="upload">
                    <AddIcon />
                  </div>
                  {error && !bannerImages?.length ? (
                    <p className="errorMsg">Required!</p>
                  ) : (
                    ""
                  )}
                </label>
              ) : null}
            </div>
          </div>
          <Button name="Save" onClick={() => setError(true)} />
        </form>
      </div>
    </Layout>
  );
};

export default VenueAdd;
