export const FAQConstant = {
  ADD_FAQ_REQUEST: "ADD_FAQ_REQUEST",
  ADD_FAQ_LOADING: "ADD_FAQ_LOADING",
  ADD_FAQ_SUCCESS: "ADD_FAQ_SUCCESS",
  ADD_FAQ_ERROR: "ADD_FAQ_ERROR",

  EDIT_FAQ_REQUEST: "EDIT_FAQ_REQUEST",
  EDIT_FAQ_LOADING: "EDIT_FAQ_LOADING",
  EDIT_FAQ_SUCCESS: "EDIT_FAQ_SUCCESS",
  EDIT_FAQ_ERROR: "EDIT_FAQ_ERROR",

  DELETE_FAQ_REQUEST: "DELETE_FAQ_REQUEST",
  DELETE_FAQ_LOADING: "DELETE_FAQ_LOADING",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_ERROR: "DELETE_FAQ_ERROR",
};
