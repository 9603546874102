import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducers";
import rootSaga from "./saga";
import createSagaMiddleware from 'redux-saga';
import "regenerator-runtime/runtime";

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: reducer,
    middleware: [sagaMiddleware],

})

sagaMiddleware.run(rootSaga)

export const action = (type) => store.dispatch({ type });


