import React from 'react';
import './component.scss';

const Title = (props) => {
  return (
    <div className='titleBar'>
      <h2>{props.title}</h2>
    </div>
  )
}

export default Title
