export const BASE_URL = "https://api.sceneson.com/api/v1/admin/";
export const GOOGLE_API_KEY = "AIzaSyB6emRUjW-F6fFkBSVh_srIOwy2KRk3xJA";

export const UPLOAD_URL = "https://api.sceneson.com/api/v1/";
export const Login = "login";
export const Logout = "logout";
export const forgot = "forgotPassword";
export const reset = "resetPassword";
export const changepassword = "changePassword";
export const updateProfile = "updateProfile";
export const getProfile = "getProfile";
//notification
export const getNotification = "getNotification";
export const addNotification = "addNotification";
//events
export const getEvent = "getEvent";
export const addEvent = "addEvent";
export const deleteEvent = "deleteEvent/";
export const editEvent = "editEvent/";
export const editTickets = "editTickets/";
//venue
export const getVenue = "getVenue";
export const addVenue = "addVenue";
export const editVenue = "editVenue/";
export const deleteVenue = "deleteVenue/";
// cms
export const getCms = "getCms";
export const addCms = "addCms";
//upload media
export const UPLOAD_MEDIA = "upload/uploadFile";
export const MULTI_UPLOAD_MEDIA = "upload/uploadFiles";
// settings
export const getEntities = "getEntities";
export const addEntities = "addEntities";
export const deleteEntities = "deleteEntities";
export const editEntities = "editEntities/";
//banners
export const getBanner = "getBanner";
export const addBanner = "addBanner";
export const deleteBanner = "deleteBanner";
//booking
export const getBooking = "getBooking";
//faq
export const addFaq = "addFAQ";
export const deleteFaq = "deleteFAQ";
export const editFaq = "editFAQ/";
