import React, { useEffect, useState } from "react";
import Layout from "../layout";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import Button from "../component/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangePassword,
  getProfileAction,
  updateProfileAction,
  uploadImageAction,
} from "../redux/actions";
import { errorToast } from "../constants";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Profile = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [profile, setProfile] = useState(true);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [image, setimage] = useState(null);
  const [selectedDocs, setSelectedDocs] = useState("");
  const [imageType, setImageType] = useState(0);

  const { getProfileData } = useSelector((state) => state.AuthReducer);
  const { imageRes } = useSelector((state) => state.CommonReducer);

  console.log("imageRes:", imageRes);

  // to handle eye icon in password field
  const handleClickShowPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const editProfile = () => {
    setProfile(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: getProfileData?.name || "",
      email: getProfileData?.email || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
    }),
    onSubmit: (values) => {
      const body = {
        phoneNo: "9998887796",
        dialCode: "+91",
        name: formik.values.name,
        email: formik.values.email,
        image: imageRes?.image,
        address: "Punjab",
      };
      dispatch(updateProfileAction.request(body, setProfile));
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password is required!"),
      newPassword: Yup.string()
        .required("New Password is required!")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password should be same as old password  "
        ),
      confirmPassword: Yup.string()
        .required("Confirm Password is required!")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
      const body = {
        oldPassword: passwordFormik.values.oldPassword,
        newPassword: passwordFormik.values.newPassword,
      };
      dispatch(ChangePassword.request(body));
    },
  });

  useEffect(() => {
    dispatch(getProfileAction.request());
  }, [dispatch]);

  // imageRes is the response from useSelector and set it acc to fields
  useEffect(() => {
    if (imageRes && imageType === 1) {
      setimage(imageRes[0]);

      const pdfUri = [{ uri: imageRes[0]?.media_file_url }];
      setSelectedDocs(pdfUri);
    }
  }, [imageRes]);

  // to upload profile imgae to api
  const uploadImage = (val) => {
    setImageType(1);
    const files = val.target;
    const file = files?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadImageAction.request(formData));
  };

  return (
    <Layout>
      <div className="pSec">
        <div className="pHead">
          {!profile ? (
            <div className="upload">
              <div className="pImg">
                <figure className="userImg">
                  <img
                    alt="user"
                    src={
                      imageRes?.image
                        ? imageRes?.image
                        : getProfileData?.image || "./images/user.png"
                    }
                  />
                </figure>
                <label htmlFor="icon-button-file">
                  <TextField
                    inputProps={{
                      accept: "image/*",
                    }}
                    id="icon-button-file"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={(el) => {
                      if (el.target.files[0].type.includes("image")) {
                        el.target.files?.length && uploadImage(el);
                      } else {
                        errorToast("Only images are allowed");
                      }
                    }}
                  />
                  <div className="uploader">
                    <PhotoCameraIcon style={{ color: "white" }} />
                  </div>
                </label>
              </div>
            </div>
          ) : (
            <figure className="userImg sec">
              <img
                alt="user"
                src={getProfileData?.image || "./images/user.png"}
              />
            </figure>
          )}

          <h4>{getProfileData?.name || "Admin"} </h4>
          {profile ? (
            <div className="center">
              <Button name="Edit" onClick={editProfile} />
            </div>
          ) : (
            ""
          )}
        </div>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: 224,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Change Password" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0} className="mainTab">
            {profile ? (
              <div className="bio">
                <div className="bMain">
                  <div className="bLeft">
                    <PersonIcon />
                  </div>
                  <div className="bRight">
                    <h4>{getProfileData?.name || "Admin"}</h4>
                  </div>
                </div>
                <div className="bMain">
                  <div className="bLeft">
                    <EmailIcon />
                  </div>
                  <div className="bRight">
                    <h4>{getProfileData?.email || "--"}</h4>
                  </div>
                </div>
              </div>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className="formArea">
                  <div className="formControl">
                    <label>Name</label>
                    <TextField
                      className="text_field"
                      placeholder="Enter Name"
                      sx={{ p: "0px", width: "100%" }}
                      variant="standard"
                      type="text"
                      margin="none"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </div>
                  <div className="formControl">
                    <label>Email</label>
                    <TextField
                      className="text_field"
                      placeholder="Enter Email"
                      sx={{ p: "0px", width: "100%" }}
                      variant="standard"
                      type="text"
                      margin="none"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <Button name="Save" type="submit" />
                </div>
              </form>
            )}
          </TabPanel>
          <TabPanel value={value} index={1} className="mainTab">
            <form onSubmit={passwordFormik.handleSubmit}>
              <div className="formArea">
                <div className="formControl" style={{ width: "48%" }}>
                  <label>Old Password</label>
                  <TextField
                    className="text_field"
                    placeholder="Enter Old Password"
                    sx={{ p: "0px", width: "100%" }}
                    variant="standard"
                    type={showOldPassword ? "text" : "password"}
                    margin="none"
                    name="oldPassword"
                    onChange={passwordFormik.handleChange}
                    onBlur={passwordFormik.handleBlur}
                    value={passwordFormik.values.oldPassword}
                    helperText={
                      passwordFormik.touched.oldPassword &&
                      passwordFormik.errors.oldPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showOldPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="formControl" style={{ width: "48%" }}>
                  <label>New Password</label>
                  <TextField
                    className="text_field"
                    placeholder="Enter New Password"
                    sx={{ p: "0px", width: "100%" }}
                    variant="standard"
                    type={showNewPassword ? "text" : "password"}
                    margin="none"
                    name="newPassword"
                    onChange={passwordFormik.handleChange}
                    onBlur={passwordFormik.handleBlur}
                    value={passwordFormik.values.newPassword}
                    helperText={
                      passwordFormik.touched.newPassword &&
                      passwordFormik.errors.newPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="formControl" style={{ width: "48%" }}>
                  <label>Confirm Password</label>
                  <TextField
                    className="text_field"
                    placeholder="Enter Confirm Password"
                    sx={{ p: "0px", width: "100%" }}
                    variant="standard"
                    type={showConfirmPassword ? "text" : "password"}
                    margin="none"
                    name="confirmPassword"
                    onChange={passwordFormik.handleChange}
                    onBlur={passwordFormik.handleBlur}
                    value={passwordFormik.values.confirmPassword}
                    helperText={
                      passwordFormik.touched.confirmPassword &&
                      passwordFormik.errors.confirmPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <Button name="Save" type="submit" />
            </form>
          </TabPanel>
        </Box>
      </div>
    </Layout>
  );
};

export default Profile;
