import { FAQConstant } from "../constant";

const initialState = {
  eventLoading: false,
  faqData: null,
  faqDelete: null,
  editFaq: null,
};

export const FaqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAQConstant.ADD_FAQ_LOADING:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case FAQConstant.ADD_FAQ_SUCCESS:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case FAQConstant.ADD_FAQ_ERROR:
      return {
        ...state,
        eventLoading: action.loading,
      };
    //by id
    case FAQConstant.EDIT_FAQ_LOADING:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case FAQConstant.EDIT_FAQ_SUCCESS:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case FAQConstant.EDIT_FAQ_ERROR:
      return {
        ...state,
        eventLoading: action.loading,
      };
    //
    case FAQConstant.DELETE_FAQ_LOADING:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case FAQConstant.DELETE_FAQ_SUCCESS:
      return {
        ...state,
        eventLoading: action.loading,
        editEvent: action.res,
      };
    case FAQConstant.DELETE_FAQ_ERROR:
      return {
        ...state,
        eventLoading: action.loading,
      };
    default:
      return state;
  }
};
