import { EntitiesConstant } from "../constant"

const initialState  ={
    entitiesLoading : false,
    entitiesData: null,
    entitiesDelete: null,
    editEntities: null
}

export const EntitiesReducer = (state = initialState, action) => {
    switch(action.type){
        case EntitiesConstant.GET_Entities_LOADING:
            return{
                ...state,
                entitiesLoading: action.loading
            }
            case EntitiesConstant.GET_Entities_SUCCESS:
                return{
                    ...state,
                    entitiesLoading: action.loading,
                    entitiesData: action.res
            }
            case EntitiesConstant.GET_Entities_ERROR:
            return{
                ...state,
                entitiesLoading: action.loading,
                entitiesData: null
            }
            case EntitiesConstant.ADD_Entities_LOADING:
                return{
                    ...state,
                    entitiesLoading: action.loading,
            }
            case EntitiesConstant.ADD_Entities_SUCCESS:
                return{
                    ...state,
                    entitiesLoading: action.loading,
            }
            case EntitiesConstant.ADD_Entities_ERROR:
                return{
                    ...state,
                    entitiesLoading: action.loading,
            }
            case EntitiesConstant.EDIT_Entities_LOADING:
                return{
                    ...state,
                    entitiesLoading: action.loading
                }
            case EntitiesConstant.EDIT_Entities_SUCCESS:
                return{
                    ...state,
                    entitiesLoading: action.loading,
                    editEntities: action.res
                }
            case EntitiesConstant.EDIT_Entities_ERROR:
                return{
                    ...state,
                    entitiesLoading: action.loading,
                    editEntities: null
                }
            case EntitiesConstant.DELETE_Entities_LOADING:
                console.log('load sss');
                return{
                    ...state,
                    entitiesLoading: action.loading
                }
            case EntitiesConstant.DELETE_Entities_SUCCESS:
                console.log('successsss');
                return{
                    ...state,
                    entitiesLoading: action.loading,
                    entitiesDelete: action.res
                }
            case EntitiesConstant.DELETE_Entities_ERROR:
                console.log('errorrr');
                return{
                    ...state,
                    entitiesLoading: action.loading,
                    entitiesDelete: null
                }
        default :
        return state;
    }
}