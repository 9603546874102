import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { BannerConstant, BookingConstant } from "../../constant";
import { errorToast, successToast } from "../../../utils";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* getBooking(payload) {
  const { body, setTotalCount } = payload;
  try {
    yield put(actions.GetBookingAction.loading(true));
    yield delay(1000);
    const res = yield call(
      apis.postApi,
      body,
      url.getBooking + "?eventId=652264103dfe4838e9c0b7ae"
    );
    console.log("booking res", res);
    if (res?.statusCode === 200) {
      yield put(actions.GetBookingAction.success(res?.data?.banner));
    } else {
      yield put(actions.GetBookingAction.error(res?.message));
    }
  } catch (error) {
    yield put(actions.GetBookingAction.error(error));
  }
}

function* BookingSaga() {
  yield all([takeEvery(BookingConstant.GET_BOOKING_REQUEST, getBooking)]);
}

export default BookingSaga;
