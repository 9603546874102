import { CommonConstant } from "../constant";

const initialState = {
  uploadLoading: false,
  isDialogeOpen: false,
  modalCategory: null,
  isAuthorised: false,
  errorMsg: null,
  imageRes: null,
  multiImageRes: null,
  modalPayload: null,
};

export const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case CommonConstant.SHOW_DIALOG:
      return {
        ...state,
        isDialogeOpen: action.isOpen,
        modalCategory: action.dialogeType,
        modalPayload: action.payload,
      };
    case CommonConstant.HIDE_DIALOG:
      return {
        ...state,
        isDialogeOpen: action.isClose,
        modalPayload: null,
      };

    //authorization
    case CommonConstant.UPDATE_AUTHORIZATION:
      return {
        ...state,
        isAuthorised: action.isAuthorised,
      };

    case CommonConstant.UPDATE_TEMP_DATA:
      return {
        ...state,
        tempData: { ...state.tempData, ...action.data },
      };

    // upload image
    case CommonConstant.UPLOAD_IMAGE_LOADING:
      return {
        ...state,
        uploadLoading: action.loading,
      };
    case CommonConstant.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadLoading: action.loading,
        errorMsg: null,
        imageRes: action.res,
      };
    case CommonConstant.UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        uploadLoading: action.loading,
        errorMsg: action.data,
        imageRes: null,
      };

    // multi upload image
    case CommonConstant.UPLOAD_MULTI_IMAGE_LOADING:
      return {
        ...state,
        uploadLoading: action.loading,
      };
    case CommonConstant.UPLOAD_MULTI_IMAGE_SUCCESS:
      return {
        ...state,
        uploadLoading: action.loading,
        errorMsg: null,
        multiImageRes: action.res,
      };
    case CommonConstant.UPLOAD_MULTI_IMAGE_ERROR:
      return {
        ...state,
        uploadLoading: action.loading,
        errorMsg: action.data,
        multiImageRes: null,
      };
    default:
      return state;
  }
};
