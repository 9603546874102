import { AuthConstant } from "../constant";

const LoginAction = {
  request: (body, navigate) => ({
    type: AuthConstant.LOGIN_REQUEST,
    body,
    navigate,
  }),
  loading: () => ({
    type: AuthConstant.LOGIN_LOADING,
    loading: true,
  }),
  success: () => ({
    type: AuthConstant.LOGIN_SUCCESS,
    loading: false,
  }),
  error: () => ({
    type: AuthConstant.LOGIN_ERROR,
    loading: false,
  }),
};
const LogoutAction = {
  request: (navigate) => ({
    type: AuthConstant.LOGOUT_REQUEST,
    navigate,
  }),
  loading: () => ({
    type: AuthConstant.LOGOUT_LOADING,
    loading: true,
  }),
  success: () => ({
    type: AuthConstant.LOGOUT_SUCCESS,
    loading: false,
  }),
  error: () => ({
    type: AuthConstant.LOGOUT_ERROR,
    loading: false,
  }),
};
const ForgotAction = {
  request: (body, navigate) => ({
    type: AuthConstant.FORGOT_REQUEST,
    body,
    navigate,
  }),
  loading: () => ({
    type: AuthConstant.FORGOT_LOADING,
    loading: true,
  }),
  success: () => ({
    type: AuthConstant.FORGOT_SUCCESS,
    loading: false,
  }),
  error: () => ({
    type: AuthConstant.FORGOT_ERROR,
    loading: false,
  }),
};

const ChangePassword = {
  request: (body) => ({
    type: AuthConstant.CHANGE_PASSWORD_REQUEST,
    body,
  }),
  loading: () => ({
    type: AuthConstant.CHANGE_PASSWORD_LOADING,
    loading: true,
  }),
  success: () => ({
    type: AuthConstant.CHANGE_PASSWORD_SUCCESS,
    loading: false,
  }),
  error: () => ({
    type: AuthConstant.CHANGE_PASSWORD_ERROR,
    loading: false,
  }),
};

const ResetPassword = {
  request: (body, navigate) => ({
    type: AuthConstant.RESET_PASSWORD_REQUEST,
    body,
    navigate
  }),
  loading: () => ({
    type: AuthConstant.RESET_PASSWORD_LOADING,
    loading: true,
  }),
  success: () => ({
    type: AuthConstant.RESET_PASSWORD_SUCCESS,
    loading: false,
  }),
  error: () => ({
    type: AuthConstant.RESET_PASSWORD_ERROR,
    loading: false,
  }),
};

const updateProfileAction = {
  request: (body, setProfile) => ({
    type: AuthConstant.UPDATE_PROFILE_REQUEST,
    body,
    setProfile,
  }),
  loading: () => ({
    type: AuthConstant.UPDATE_PROFILE_LOADING,
    loading: true,
  }),
  success: () => ({
    type: AuthConstant.UPDATE_PROFILE_SUCCESS,
    loading: false,
  }),
  error: () => ({
    type: AuthConstant.UPDATE_PROFILE_ERROR,
    loading: false,
  }),
};

const getProfileAction = {
  request: () => ({
    type: AuthConstant.GET_PROFILE_REQUEST,
  }),
  loading: () => ({
    type: AuthConstant.GET_PROFILE_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: AuthConstant.GET_PROFILE_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: AuthConstant.GET_PROFILE_ERROR,
    loading: false,
  }),
};

export {
  LoginAction,
  LogoutAction,
  ForgotAction,
  ChangePassword,
  ResetPassword,
  updateProfileAction,
  getProfileAction,
};
