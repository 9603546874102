import React from "react";
import Layout from "../../layout";
import Title from "../../component/Title";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import Button from "../../component/Button";
import CommonTab from "../../component/CommonTab";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetEntitiesAction } from "../../redux/actions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const [selectedDocs, setSelectedDocs] = useState([]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Setting = () => {
  const [value, setValue] = useState(0);
  const [venue, setVenue] = useState(false);
  const [music, SetMusic] = useState(false);
  const [venueVal, setVenueVal] = useState("");
  const [tempArr, setTempArr] = useState([]);
  const [musicVal, setMusicVal] = useState("");
  const [musicArr, setMusicArr] = useState([]);
  console.log(musicArr, "music");
  console.log(tempArr, "venue");

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    console.log(newValue, "newvalue");
    setValue(newValue);
  };

  const addVenue = () => {
    setVenue(true);
  };

  const addMusic = () => {
    SetMusic(true);
  };
  const handlePush = () => {
    setTempArr(tempArr);
    tempArr.push(venueVal);
    setVenueVal("");
  };

  const handleMusic = () => {
    setMusicArr(musicArr);
    console.log("2");
    musicArr.push(musicVal);
    console.log("3");
    setMusicVal("");
    console.log("4");
  };

  return (
    <Layout>
      <div className="table-responsive">
        <div className="serachMain">
          <Title title="Settings" />
        </div>
        <div className="settings">
          <CommonTab />
        </div>
      </div>
    </Layout>
  );
};

export default Setting;
