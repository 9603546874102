import React from "react";
import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import Auth from "./auth";
import { Loader } from "./constants";

const Routing = () => {
  return (
    <div>
      <Loader />
      <Routes>
        <Route path="/" element={<Auth.Login />} />
        <Route path="/forgot" element={<Auth.Forgot />} />
        <Route path="/reset-password" element={<Auth.Reset />} />
        <Route path="/booking" element={<Pages.Booking />} />
        <Route path="/eventbooking/:id" element={<Pages.Booking />} />
        <Route path="/eventbooking" element={<Pages.Booking />} />
        <Route path="/changepassword" element={<Pages.ChangePassword />} />
        <Route path="/Cms" element={<Pages.Cms />} />
        <Route path="/dashboard" element={<Pages.Dashboard />} />
        <Route path="/events" element={<Pages.EventManagement />} />
        <Route path="/notification" element={<Pages.Notification />} />
        <Route path="/profile" element={<Pages.Profile />} />
        <Route path="/setting" element={<Pages.Setting />} />
        <Route path="/transaction" element={<Pages.Transaction />} />
        <Route path="/users" element={<Pages.UserManagement />} />
        <Route path="/venues" element={<Pages.VenueManagement />} />
        <Route path="/addnotification" element={<Pages.AddNotification />} />
        <Route path="/addevent" element={<Pages.AddEvent />} />
        <Route path="/editevent/:id" element={<Pages.AddEvent />} />
        <Route path="/venueadd" element={<Pages.VenueAdd />} />
        <Route path="/editvenue/:id" element={<Pages.VenueAdd />} />
        <Route path="/editvenue" element={<Pages.VenueAdd />} />
        <Route path="/addCityLocation" element={<Pages.AddCityLocation />} />
        <Route
          path="/editCityLocation/:id"
          element={<Pages.AddCityLocation />}
        />
        <Route path="/AddGuestList" element={<Pages.AddGuestList />} />
        <Route path="/AddComment" element={<Pages.AddComment />} />
        <Route path="/banner" element={<Pages.HomeBanner />} />
        <Route path="/add-banner" element={<Pages.AddHomeBanner />} />
        <Route path="/add-faq" element={<Pages.AddFaq />} />
        <Route path="/edit-faq/:id" element={<Pages.AddFaq />} />
      </Routes>
    </div>
  );
};

export default Routing;
