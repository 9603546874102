import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";

const BannerModal = () => {
  const venueData = useSelector((state) => state.VenueReducer.venueData);
  const { modalPayload } = useSelector((state) => state.CommonReducer);
  const settings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="menuModal">
      <h2>Banners</h2>
      {venueData &&
        venueData?.map((item) => {
          return (
            <Slider {...settings}>
              {item?._id === modalPayload?.id
                ? item?.banners?.map((x) => {
                    return (
                      <div key={item?._id}>
                        <figure>
                          <img src={x} alt="img" />
                        </figure>
                      </div>
                    );
                  })
                : null}
            </Slider>
          );
        })}
    </div>
  );
};

export default BannerModal;
