import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { CommonConstant } from "../../constant";
import { errorToast } from "../../../utils";
import { successToast } from "../../../constants";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* mediaUploadCall(payload) {
  try {
    yield put(actions.uploadImageAction.loading(true));
    yield delay(1000);
    const res = yield call(
      apis.multipartFormApi,
      payload.body,
      url.UPLOAD_MEDIA,
      ""
    );
    if (res?.statusCode === 200) {
      yield put(actions.uploadImageAction.success(res?.data));
      // yield put(actions.updateProfileAction.success(res?.data));
      // successToast(res?.message || "");
    } else {
      errorToast(res?.message);
      yield put(actions.uploadImageAction.error(res.message));
    }
  } catch (error) {
    errorToast(error);
    yield put(actions.uploadImageAction.error(error));
  }
}

export function* multiMediaUploadCall(payload) {
  console.log("multiMediaUploadCall");
  try {
    yield put(actions.uploadMultipleImageAction.loading(true));
    yield delay(1000);
    const res = yield call(
      apis.multipartFormApi,
      payload.body,
      url.MULTI_UPLOAD_MEDIA,
      ""
    );
    console.log(res, "multiress");
    if (res?.statusCode === 200) {
      console.log("woww");
      yield put(actions.uploadMultipleImageAction.success(res?.data));
      // yield put(actions.updateProfileAction.success(res?.data));
      successToast(res?.message || "");
    } else {
      errorToast(res?.message);
      yield put(actions.uploadMultipleImageAction.error(res.message));
    }
  } catch (error) {
    errorToast(error);
    yield put(actions.uploadMultipleImageAction.error(error));
  }
}

function* CommonSaga() {
  yield all([takeEvery(CommonConstant.UPLOAD_IMAGE_REQUEST, mediaUploadCall)]);
  yield all([
    takeEvery(CommonConstant.UPLOAD_MULTI_IMAGE_REQUEST, multiMediaUploadCall),
  ]);
}

export default CommonSaga;
