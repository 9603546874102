import { CmsConstant } from "../constant/CmsConstant"

const GetCmsAction ={
    request:() => ({
        type: CmsConstant.GET_CMS_REQUEST,
    }),
    loading: () => ({
        type: CmsConstant.GET_CMS_LOADING,
        loading: true
    }),
    success: (res) => ({
        type: CmsConstant.GET_CMS_SUCCESS,
        loading: false,
        res
    }),
    error:() => ({
        type: CmsConstant.GET_CMS_ERROR,
        loading: false
    })
}

const AddCmsAction ={
    request:(body) => ({
        type: CmsConstant.Add_CMS_REQUEST,
        body
    }),
    loading: () => ({
        type: CmsConstant.Add_CMS_LOADING,
        loading: true
    }),
    success: () => ({
        type: CmsConstant.Add_CMS_SUCCESS,
        loading: false,
        
    }),
    error:() => ({
        type: CmsConstant.Add_CMS_ERROR,
        loading: false
    })
}

export {GetCmsAction, AddCmsAction}