import React from 'react';
import { FormControlLabel, Switch } from "@mui/material";

const SwitchBtn = (props) => {
  const {onChange, checked, name} = props;
  return (
    <FormControlLabel control={<Switch  checked ={checked} name={name}  onChange={onChange}/>}/>
  )
}

export default SwitchBtn
