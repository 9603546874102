import { deleteCookie } from "cookies-next";
import { useDispatch } from "react-redux";
import * as Url from "./Urls";
import { errorToast } from "../utils";

export const get = async (url, token, body) => {
  var headers;
  if (token == "" || token == null || token == undefined) {
    headers = {
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }
  const completeUrl = Url.BASE_URL + url;

  try {
    const res = await fetch(completeUrl, {
      method: "GET",
      headers,
    });
    const response = await res.json();
    // if (res?.status === 400) {
    //   const errors = Object.values(response);
    //   if (errors?.length) {
    //     const error = errors[0];
    //     if (error?.length) {
    //       errorToast(error[0]);
    //     }
    //   }
    // }

    if (res?.status === 401) {
      const errors = Object.values(response);
      if (errors?.length) {
        const error = errors[0];
        console.log(error);
        if (error) {
          errorToast(error);
          deleteCookie("token");
          deleteCookie("userData");
          window?.location?.replace("/");
        }
      }
    }

    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>>");
    errorToast(error || "Something went wrong!");
    if (error && error?.originalStatus === 502) {
      errorToast("Server error!");
    }
    return error;
  }
};

export const getoldchat = async (url, token, hide = false) => {
  var headers;
  if (token == "" || token == null || token == undefined) {
    headers = {
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }
  const completeUrl = Url.BASE_URL + url;

  try {
    const res = await fetch(completeUrl, {
      method: "GET",
      headers,
    });
    const response = await res.json();

    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};

export const post = async (url, token, body) => {
  var headers;
  if (token == "" || token == null || token == undefined) {
    headers = {
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
  const completeUrl = Url.BASE_URL + url;
  let data = JSON.stringify(body);
  try {
    const res = await fetch(completeUrl, {
      method: "POST",
      headers,
      body: data,
    });
    const response = await res.json();
    if (res?.status === 401) {
      const errors = Object.values(response);
      if (errors?.length) {
        const error = errors[0];
        console.log(error);
        if (error) {
          errorToast(error);
          deleteCookie("token");
          deleteCookie("userData");
          window?.location?.replace("/");
        }
      }
    }

    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};

export const put = async (url, token, body, hide = false) => {
  var headers;
  if (token == "" || token == null || token == undefined) {
    headers = {
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
  const completeUrl = Url.BASE_URL + url;
  let data = JSON.stringify(body);
  try {
    const res = await fetch(completeUrl, {
      method: "PUT",
      headers,
      body: data,
    });
    const response = await res.json();
    if (res?.status === 400) {
      const errors = Object.values(response);
      if (errors?.length) {
        const error = errors[0];
        if (error?.length) {
          errorToast(error[0]);
        }
      }
    } else if (res?.status === 401) {
      const errors = Object.values(response);
      if (errors?.length) {
        const error = errors[0];
        console.log(error);
        if (error) {
          errorToast(error);
          deleteCookie("token");
          deleteCookie("userData");
          window?.location?.replace("/");
        }
      }
    }
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};

export const uploadImageApi = async (url, token, body, hide = false) => {
  var headers;

  if (token == "" || token == null || token == undefined) {
    headers = {
      Accept: "application/json",
    };
  } else {
    headers = {
      // Accept: "application/json",
      Authorization: "Bearer " + token,
      // "Content-Type": "multipart/form-data",
      Accept: "application/json",
      // "Access-Control-Allow-Origin": "*",
    };
  }
  const completeUrl = Url.UPLOAD_URL + url;
  try {
    const res = await fetch(completeUrl, {
      method: "POST",
      headers,
      body,
    });
    let response = await res.json();
    if (res?.status === 401) {
      const errors = Object.values(response);
      if (errors?.length) {
        const error = errors[0];
        console.log(error);
        if (error) {
          errorToast(error);
          deleteCookie("token");
          deleteCookie("userData");
          window?.location?.replace("/");
        }
      }
    }
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};

export const del = async (url, body, token, hide = false) => {
  console.log(body, "bodyyyy");
  var headers;
  if (token == "" || token == null || token == undefined) {
    headers = {
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
  const completeUrl = Url.BASE_URL + url;
  let data = body ? JSON.stringify(body) : null;
  try {
    const res = await fetch(completeUrl, {
      method: "DELETE",
      headers,
      body: data,
    });
    const response = await res.json();
    if (res?.status === 401) {
      const errors = Object.values(response);
      if (errors?.length) {
        const error = errors[0];
        console.log(error);
        if (error) {
          errorToast(error);
          deleteCookie("token");
          deleteCookie("userData");
          window?.location?.replace("/");
        }
      }
    }
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};
