import { CmsConstant } from "../constant/CmsConstant";

const initialState = {
  isLoadingcms: false,
  getCms: null,
};

export const CmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CmsConstant.GET_CMS_LOADING:
      return {
        ...state,
        isLoadingcms: action.loading,
      };
    case CmsConstant.GET_CMS_SUCCESS:
      return {
        ...state,
        isLoadingcms: action.loading,
        getCms: action.res,
      };
    case CmsConstant.GET_CMS_ERROR:
      return {
        ...state,
        isLoadingcms: action.loading,
        getCms: null,
      };
    case CmsConstant.Add_CMS_LOADING:
      return {
        ...state,
        isLoadingcms: action.loading,
      };
    case CmsConstant.Add_CMS_SUCCESS:
      return {
        ...state,
        isLoadingcms: action.loading,
      };
    case CmsConstant.Add_CMS_ERROR:
      return {
        ...state,
        isLoadingcms: action.loading,
      };
    default:
      return state;
  }
};
