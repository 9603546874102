import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { CmsConstant } from "../../constant/CmsConstant";
import { errorToast, successToast } from "../../../constants";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* getCms() {
  try {
    yield put(actions.GetCmsAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.getApi, null, url.getCms);
    console.log("cms res", res);
    if (res?.statusCode === 200) {
      yield put(actions.GetCmsAction.success(res?.data));
    } else {
      yield put(actions.GetCmsAction.error(res?.message));
    }
  } catch (error) {
    yield put(actions.GetCmsAction.error(error));
  }
}

export function* addCms(payload) {
  const { body } = payload;
  try {
    yield put(actions.AddCmsAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url?.addCms);
    if (res?.statusCode === 200) {
      yield put(actions.AddCmsAction.success());
    } else {
      yield put(actions.AddCmsAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.AddEventAction.error(error));
  }
}

function* CmsSaga() {
  yield all([takeEvery(CmsConstant.GET_CMS_REQUEST, getCms)]);
  yield all([takeEvery(CmsConstant.Add_CMS_REQUEST, addCms)]);
}

export default CmsSaga;
