import { TextareaAutosize } from '@mui/material'
import React from 'react'

const TextareaField = (props) => {
  return (
    <>
    <TextareaAutosize
    className='text_field'
    placeholder={props.placeholder}
    sx={{ p: "0px", width: "100%" }}
    type="text"
    margin="none"
    variant="standard"
    name={props.name}
    value={props.value}
    onChange={props.onChange}
    onBlur={props.onBlur}
  />
  
  </>
  )
}

export default TextareaField
