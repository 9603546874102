import React from 'react';
import Layout from '../layout'
import Title from '../component/Title';


const ChangePassword = () => {
  return (
    <Layout>
      <Title title="Change Password" />
    </Layout>
  )
}

export default ChangePassword
