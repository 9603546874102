export const NotificationConstant = {
    GET_NOTIFICATION_REQUEST: 'GET_NOTIFICATION_REQUEST',
    GET_NOTIFICATION_LOADING: 'GET_NOTIFICATION_LOADING',
    GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
    GET_NOTIFICATION_ERROR: 'GET_NOTIFICATION_ERROR',

    ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
    ADD_NOTIFICATION_LOADING: 'ADD_NOTIFICATION_LOADING',
    ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
    ADD_NOTIFICATION_ERROR: 'ADD_NOTIFICATION_ERROR',
}