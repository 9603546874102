import React from "react";
import Layout from "../../layout";
import Title from "../../component/Title";
import InputField from "../../component/InputField";
import Button from "../../component/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddEntitiesAction,
  EditEntitiesAction,
  GetEntitiesAction,
} from "../../redux/actions";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";

const AddCityLocation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const pathname = location.pathname;
  const [venueVal, setVenueVal] = useState("");
  const [tempArr, setTempArr] = useState([]);

  const [cityVal, setCityVal] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [editedIndex, setEditedIndex] = useState(null);

  const { editEntities } = useSelector((state) => state.EntitiesReducer);

  const handlePush2 = () => {
    setTempArr([...tempArr, { name: venueVal }]);
    setVenueVal("");
    setIsDisabled(false);
  };

  const handlePush = () => {
    console.log("klkl");
    if (editedIndex !== null) {
      const updatedArr = tempArr.map((item, i) => {
        console.log("i", i);
        console.log("ccc", editedIndex);
        if (item?._id === editedIndex) {
          console.log("same id");
          return { ...item, name: venueVal };
        }
        return item;
      });
      setTempArr(updatedArr);
      setEditedIndex(null);
      setVenueVal("");
    }
  };

  const deleteItem = (index) => {
    const updatedArr = tempArr?.filter((_, i) => i !== index);
    setTempArr(updatedArr);
    if (updatedArr.length === 0) {
      setIsDisabled(true);
    }
  };

  const EditItem = (selectedItem) => {
    const itemToEdit = tempArr[selectedItem];
    console.log("itemToEdit", itemToEdit);
    if (itemToEdit) {
      setVenueVal(itemToEdit?.name);
      setEditedIndex(itemToEdit?._id);
    }
  };

  const arrOfObjects = tempArr.map((str) => {
    return { name: str };
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      const body = {
        value: cityVal,
        type: 2,
        subValue: tempArr,
      };
      dispatch(EditEntitiesAction.request(id, body));
      navigate("/setting", { state: "city" });
    } else {
      const body = {
        value: cityVal,
        type: 2,
        subValue: tempArr,
      };

      dispatch(AddEntitiesAction.request(body));
      setVenueVal("");
      navigate("/setting", { state: "city" });
    }
  };

  useEffect(() => {
    if (id) {
      if (editEntities) {
        setCityVal(editEntities?.value);
        setTempArr(editEntities?.subValue);
      }
    }
  }, [editEntities]);

  useEffect(() => {
    if (id) {
      dispatch(EditEntitiesAction.request(id));
    }
  }, [id]);

  return (
    <Layout>
      <form onSubmit={handleSubmit}>
        <div className="addData">
          {pathname === `/editCityLocation/${id}` ? (
            <Title title="Edit City & Location" />
          ) : (
            <Title title="Add City & Location" />
          )}
          <div className="rightSide">
            <Button
              name="<"
              title="Back"
              onClick={() => navigate("/setting", { state: "city" })}
            />
          </div>
          <div className="formGroup">
            <div className="formControl">
              <label>City Name</label>
              <InputField
                placeholder="Please Enter City Name"
                value={cityVal}
                onChange={(e) => setCityVal(e.target.value)}
              />
            </div>
            <div className="formControl">
              <label>Location Name</label>
              <div className="locationMain">
                <TextField
                  placeholder="Venue Features"
                  sx={{ p: "0px", width: "100%" }}
                  variant="standard"
                  type="text"
                  margin="none"
                  value={venueVal}
                  onChange={(e) => setVenueVal(e.target.value)}
                />
                <h4 className="addLocation" onClick={() => handlePush()}>
                  Add
                </h4>

                {pathname === "addCityLocation" ? <Button name="Add" /> : ""}
              </div>

              {pathname === "addCityLocation" ? <Button name="Add" /> : ""}
              <ul className="locationBubble">
                {tempArr?.length
                  ? tempArr?.map((item, i) => {
                      return (
                        <li key={item?._id}>
                          <p>{item?.name || item}</p>
                          <span className="deleteItem">
                            <EditIcon onClick={() => EditItem(i)} />
                            <CancelIcon onClick={() => deleteItem(i)} />
                          </span>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
          <button style={{ marginLeft: 10 }} type="submit">
            {id ? "Update" : "Save"}
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default AddCityLocation;
