import { getCookie } from "cookies-next";
import * as Service from "../../constants/Service";

export const postApi = async (body, url, tempToken) => {
  const token = getCookie("token");

  try {
    const response = await Service.post(url, token || "", body);
    return response;
  } catch (error) {
    return { message: error };
  }
};

export const getApi = async (body, url) => {
  const token = getCookie("token");

  try {
    const response = await Service.get(url, token || "", body || "");
    return response;
  } catch (error) {
    return { message: error };
  }
};

export const multipartFormApi = async (body, url) => {
  const token = getCookie("token");

  try {
    const response = await Service.uploadImageApi(url, token, body);

    return response;
  } catch (error) {
    return { message: error };
  }
};

export const putApi = async ( body, url, tempToken) => {
  const token = getCookie("token");
  try {
    const response = await Service.put(url, token, body);
    if (response) {
      // console.log(response, `${url}-----------`);
    }
    return response;
  } catch (error) {
    // console.log(error, ‘err-----------’);
    return { message: error };
  }
};

export const deleteApi = async (url, body) => {
  console.log(url,'urlll');
  const token = getCookie("token");
  try {
    const response = await Service.del(url, body, token);
    if (response) {
      // console.log(response, `${url}-----------`);
    }
    return response;
  } catch (error) {
    // console.log(error, ‘err-----------’);
    return { message: error };
  }
};


export const delete_form = async (url, body) => {

  const token = getCookie("token");
  try {
    const response = await Service.del(url,body, token);
    if (response) {
    }
    return response;
  } catch (error) {
    return { message: error };
  }
};

