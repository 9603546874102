import React from "react";
import { Box, Modal } from "@mui/material";
import { useSelector } from "react-redux";

const Loader = () => {
  const { isLoading } = useSelector((state) => state.AuthReducer);
  const isNotificationLoad = useSelector(
    (state) => state.NotificationReducer.isLoading
  );
  const { venueLoading } = useSelector((state) => state.VenueReducer);
  const { eventLoading } = useSelector((state) => state.EventReducer);
  const { entitiesLoading } = useSelector((state) => state.EntitiesReducer);
  const { uploadLoading } = useSelector((state) => state.CommonReducer);
  const { isLoadingcms } = useSelector((state) => state.CmsReducer);
  const { bannerLoading } = useSelector((state) => state.BannerReducer);
  const { faqLoading } = useSelector((state) => state.FaqReducer);

  const style = {
    outline: "none",
  };

  return (
    <Box>
      {isLoading ||
      venueLoading ||
      bannerLoading ||
      eventLoading ||
      entitiesLoading ||
      uploadLoading ||
      isLoadingcms ||
      // faqLoading ||
      isNotificationLoad ? (
        <Modal open>
          <Box className="loader" style={style}>
            <figure>
              <img src="/images/loader.gif" alt="Loader" />
            </figure>
          </Box>
        </Modal>
      ) : null}
    </Box>
  );
};

export default Loader;
