import React, { useEffect, useState } from "react";
import "./layout.scss";
import { useNavigate } from "react-router-dom";
import CommonDialoge from "./CommonDialoge";
import { getCookie } from "cookies-next";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAction, LogoutAction, modalVisible } from "../redux/actions";
import { Modules } from "../constants";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getProfileData } = useSelector((state) => state.AuthReducer);

  // useEffect(() => {
  //   const token = getCookie("token");
  //   console.log(token, 'ttto');
  //   if (token) {
  //     dispatch(resetAuth.authorise());
  //   } else {
  //   }
  // }, [isAuthorised, getCookie]);

  // useEffect(() => {
  //   const token = getCookie("token");
  //   if (token) {
  //     navigate("/events");
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    dispatch(getProfileAction.request());
  }, [dispatch]);

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  return (
    <div className="header">
      <div className="logo">
        <figure>
          <img src="/images/logo.png" alt="logo" />
        </figure>
      </div>
      <CommonDialoge />
      <div className="profile">
        <div className="profile" onClick={() => navigate("/profile")}>
          <figure>
            <img
              src={getProfileData?.image || "./images/user.png"}
              alt="Admin"
            />
          </figure>
          <h5>{getProfileData?.name || "Admin"}</h5>
        </div>
        <div className="logout">
          <LogoutIcon
            onClick={() => dispatch(modalVisible.modalOpen(Modules.LOGOUT))}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
