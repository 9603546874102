import { EventConstant } from "../constant/EventConstant";

const GetEventAction = {
  request: (body, setTotalCount) => ({
    type: EventConstant.GET_EVENT_REQUEST,
    body,
    setTotalCount,
  }),
  loading: () => ({
    type: EventConstant.GET_EVENT_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: EventConstant.GET_EVENT_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: EventConstant.GET_EVENT_ERROR,
    loading: false,
  }),
};

const GetEventActionById = {
  request: (id, body) => ({
    type: EventConstant.GET_EVENT_REQUEST_BY_ID,
    id,
    body,
  }),
  loading: () => ({
    type: EventConstant.GET_EVENT_LOADING_BY_ID,
    loading: true,
  }),
  success: (res) => ({
    type: EventConstant.GET_EVENT_SUCCESS_BY_ID,
    loading: false,
    res,
  }),
  error: () => ({
    type: EventConstant.GET_EVENT_ERROR_BY_ID,
    loading: false,
  }),
};

const AddEventAction = {
  request: (body, navigate) => ({
    type: EventConstant.ADD_EVENT_REQUEST,
    body,
    navigate,
  }),
  loading: () => ({
    type: EventConstant.ADD_EVENT_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: EventConstant.ADD_EVENT_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: EventConstant.ADD_EVENT_ERROR,
    loading: false,
  }),
};

const EditEventAction = {
  request: (id, body, navigate) => ({
    type: EventConstant.EDIT_EVENT_REQUEST,
    id,
    body,
    navigate,
  }),
  loading: () => ({
    type: EventConstant.EDIT_EVENT_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: EventConstant.EDIT_EVENT_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: EventConstant.EDIT_EVENT_ERROR,
    loading: false,
  }),
};

const DeleteEventAction = {
  request: (id) => ({
    type: EventConstant.DELETE_EVENT_REQUEST,
    id,
  }),
  loading: () => ({
    type: EventConstant.DELETE_EVENT_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: EventConstant.DELETE_EVENT_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: EventConstant.DELETE_EVENT_ERROR,
    loading: false,
  }),
};

const EditTicketAction = {
  request: (id, body, navigate) => ({
    type: EventConstant.EDIT_TICKET_REQUEST,
    id,
    body,
    navigate,
  }),
  loading: () => ({
    type: EventConstant.EDIT_TICKET_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: EventConstant.EDIT_TICKET_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: EventConstant.EDIT_TICKET_ERROR,
    loading: false,
  }),
};

const DeleteTicketAction = {
  request: (id, body) => ({
    type: EventConstant.DELETE_TICKET_REQUEST,
    id,
    body,
  }),
  loading: () => ({
    type: EventConstant.DELETE_TICKET_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: EventConstant.DELETE_TICKET_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: EventConstant.DELETE_TICKET_ERROR,
    loading: false,
  }),
};

export {
  GetEventAction,
  AddEventAction,
  DeleteEventAction,
  EditEventAction,
  GetEventActionById,
  EditTicketAction,
  DeleteTicketAction,
};
