import React from "react";
import Layout from "../layout";
import Title from "../component/Title";

const UserManagement = () => {
  return (
    <Layout>
      <Title title="Users" />
    </Layout>
  );
};

export default UserManagement;
