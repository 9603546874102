
import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { useSelector } from 'react-redux';

const EventBanner = () => {

  const eventData = useSelector((state) => state.EventReducer.eventData);
  const {modalPayload} = useSelector((state) => state.CommonReducer);
  const baseUrl = './images'
  const settings = {
      dots: false,
      dotsClass: "slick-dots slick-thumb",
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      
    };
      
  return (
    <div className='menuModal'>
        <h2>Banner</h2>
        { eventData && eventData?.map((item) => {
          return (
            <Slider {...settings}>
            {item?._id === modalPayload?.id ? item?.banners?.map((x) => {
              return(
                <div>
                  <figure>
                     <img src={x} alt='img'/>
                  </figure>
                </div>
             
              )
            }) : null}
            </Slider>
          )
        }) 
      }
      </div>
  )
}

export default EventBanner;
