import { NotificationConstant } from "../constant/NotificationConstant";

const GetNotificationAction = {
  request: (body, setTotalCount) => ({
    type: NotificationConstant.GET_NOTIFICATION_REQUEST,
    body,
    setTotalCount,
  }),
  loading: () => ({
    type: NotificationConstant.GET_NOTIFICATION_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: NotificationConstant.GET_NOTIFICATION_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: NotificationConstant.GET_NOTIFICATION_ERROR,
    loading: false,
  }),
};

const AddNotificationAction = {
  request: (body, navigate) => ({
    type: NotificationConstant.ADD_NOTIFICATION_REQUEST,
    body,
    navigate,
  }),
  loading: () => ({
    type: NotificationConstant.ADD_NOTIFICATION_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: NotificationConstant.ADD_NOTIFICATION_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: NotificationConstant.ADD_NOTIFICATION_ERROR,
    loading: false,
  }),
};

export { GetNotificationAction, AddNotificationAction };
