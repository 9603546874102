import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { EventConstant } from "../../constant/EventConstant";
import { errorToast, successToast } from "../../../utils";
import { EntitiesConstant } from "../../constant";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* getEntities(payload){
    const {body} = payload;
    try{
        yield put(actions.GetEntitiesAction.loading(true));
        yield delay(1000);
        const res = yield call(apis.postApi, body, url.getEntities);
        if(res?.statusCode === 200){
            yield put(actions.GetEntitiesAction.success(res?.data?.entities))
        }else{
            yield put(actions.GetEntitiesAction.error(res?.message))
        }
    }catch(error){
        yield put(actions.GetEntitiesAction.error(error))
    }
}

export function* AddEntities(payload){
    const {body} = payload;
    try{
        yield put(actions.AddEntitiesAction.loading(true));
        yield delay(1000);
        const res = yield call(apis.postApi, body, url.addEntities);
        if(res?.statusCode === 200){
          successToast(res?.message || "");
        }
        else{
            yield put(actions.AddEntitiesAction.error(res?.message))
            errorToast(res?.message || "")
        }
    }catch(error){
      yield put(actions.AddEventAction.error(error))
    }
}

export function* EditEntities(payload){
  console.log(payload,'editsagaaaa');
  const {id, body, navigate} = payload;
  console.log(body,'sagabodydydyd', id);
     try{
      yield put(actions.EditEntitiesAction.loading(true));
      const res = yield call(apis.putApi, body, url.editEntities + id);
      yield delay(1000);
      if(res?.statusCode === 200){
        yield put(actions.EditEntitiesAction.success(res?.data));
        if(body){
            successToast(res?.message)
            navigate('/setting')
        }
      }
  }catch(error){
    yield put(actions.EditEntitiesAction.error(error))
  }
}

export function* DeleteEntities(payload){
    const {id} = payload;
    try{
      yield put(actions.DeleteEntitiesAction.loading(true));
      yield delay(1000);
      const deleteUrl = `${url.deleteEntities}/${id}`;
      const res = yield call(apis.delete_form, deleteUrl);
      if(res?.statusCode === 200){
        successToast(res?.message)
        yield put(actions.DeleteEntitiesAction.success(res));
      }else{
        errorToast(res?.message || "")
      }
    }catch(error){
      yield put(actions.DeleteEntitiesAction.error(error))
    }
  }

function* EntitiesSaga() {
    yield all([takeEvery(EntitiesConstant.GET_Entities_REQUEST, getEntities)]);
    yield all([takeEvery(EntitiesConstant.ADD_Entities_REQUEST, AddEntities)]);
    yield all([takeEvery(EntitiesConstant.DELETE_Entities_REQUEST, DeleteEntities)]);
    yield all([takeEvery(EntitiesConstant.EDIT_Entities_REQUEST, EditEntities)]);
  }

  export default EntitiesSaga;