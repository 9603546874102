import React from "react";
import Layout from "../../layout";
import { Title, Button, InputField, TextareaField } from "../../component";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AddNotificationAction } from "../../redux/actions";

const AddNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Required!"),
      description: Yup.string().required("Required!"),
    }),
    onSubmit: (values) => {
      const body = {
        title: formik.values.title,
        message: formik.values.description,
      };
      dispatch(AddNotificationAction.request(body, navigate));
    },
  });
  return (
    <Layout>
      <div className="rightSide">
        <Button name=">" onClick={() => navigate("/notification")} />
      </div>
      <div className="table-responsive">
        <form onSubmit={formik.handleSubmit}>
          <div className="addData">
            <Title title="Add Notification" />
            <div className="formGroup">
              <div className="formControl">
                <label>Title</label>
                <InputField
                  placeholder="Enter Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </div>
              <div className="formControl">
                <label>Description</label>
                <TextareaField
                  placeholder="Enter Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.description && formik.errors.description ? (
                  <p className="errorMsg">
                    {" "}
                    {formik.touched.description && formik.errors.description}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="btnRow">
              <Button name="Save" />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddNotification;
