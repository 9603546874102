import React from "react";
import "./Auth.scss";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ForgotAction } from "../redux/actions";

const Forgot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
    }),
    onSubmit: (values) => {
      const body = {
        email: formik.values.email,
      };
      dispatch(ForgotAction.request(body, navigate));
    },
  });
  return (
    <div className="loginScreen">
      <div className="login">
        <div className="lLeft">
          <div className="logo">
            <figure>
              <img src="../images/logo.png" alt="logo" />
            </figure>
          </div>
          <div className="title">
            <h2>
              Forgot <span>lorem ipsum is a dummy</span>
            </h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="authForm">
              <div className="formControl">
                <TextField
                  className="text_field"
                  placeholder="Enter Email Address"
                  sx={{ p: "0px", width: "100%" }}
                  type="text"
                  margin="none"
                  name="email"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="saveBtn">
                <button type="submit">Save</button>
              </div>
              <div className="forgot">
                <h5 onClick={() => navigate("/")}>Back to Login?</h5>
              </div>
            </div>
          </form>
        </div>
        <div className="right"></div>
      </div>
    </div>
  );
};

export default Forgot;
