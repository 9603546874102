export const EntitiesConstant = {
    GET_Entities_REQUEST: "GET_Entities_REQUEST",
    GET_Entities_LOADING: "GET_Entities_LOADING",
    GET_Entities_SUCCESS: "GET_Entities_SUCCESS",
    GET_Entities_ERROR: "GET_Entities_ERROR",

    ADD_Entities_REQUEST: "ADD_Entities_REQUEST",
    ADD_Entities_LOADING: "ADD_Entities_LOADING",
    ADD_Entities_SUCCESS: "ADD_Entities_SUCCESS",
    ADD_Entities_ERROR: "ADD_Entities_ERROR",

    EDIT_Entities_REQUEST: "EDIT_Entities_REQUEST",
    EDIT_Entities_LOADING: "EDIT_Entities_LOADING",
    EDIT_Entities_SUCCESS: "EDIT_Entities_SUCCESS",
    EDIT_Entities_ERROR: "EDIT_Entities_ERROR",

    DELETE_Entities_REQUEST: "DELETE_Entities_REQUEST",
    DELETE_Entities_LOADING: "DELETE_Entities_LOADING",
    DELETE_Entities_SUCCESS: "DELETE_Entities_SUCCESS",
    DELETE_Entities_ERROR: "DELETE_Entities_ERROR",

}

