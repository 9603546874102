import { BookingConstant } from "../constant";

const GetBookingAction = {
  request: (body, setTotalCount) => ({
    type: BookingConstant.GET_BOOKING_REQUEST,
    body,
    setTotalCount,
  }),
  loading: () => ({
    type: BookingConstant.GET_BOOKING_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: BookingConstant.GET_BOOKING_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: BookingConstant.GET_BOOKING_ERROR,
    loading: false,
  }),
};

export { GetBookingAction };
