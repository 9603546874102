import { Box, Modal } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modules } from "../../constants";
import { modalVisible } from "../../redux/actions";
import {
  BannerModal,
  DeleteModal,
  EventBanner,
  FloorModal,
  LogoutModal,
  MapModal,
  MenuModal,
} from "../../component/modals";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  outline: "none",
  p: 4,
  borderRadius: 1,
};

const CommonDialoge = () => {
  const dispatch = useDispatch();

  const isDialogeOpen = useSelector(
    (state) => state.CommonReducer.isDialogeOpen
  );
  const dialogType = useSelector((state) => state.CommonReducer.modalCategory);

  const Modaltype = () => {
    switch (dialogType) {
      case Modules.MENU:
        return <MenuModal />;
      case Modules.FLOOR:
        return <FloorModal />;
      case Modules.BANNER:
        return <BannerModal />;
      case Modules.LOGOUT:
        return <LogoutModal />;
      case Modules.EVENT_BANNER:
        return <EventBanner />;
      case Modules.DELETE:
        return <DeleteModal />;
      case Modules.MAP:
        return <MapModal />;
      default:
        return <p>No data found</p>;
    }
  };

  return (
    <div>
      <Modal
        open={isDialogeOpen}
        onClose={() => dispatch(modalVisible.modalClose())}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="crossBtn">
            <ClearIcon onClick={() => dispatch(modalVisible.modalClose())} />
          </div>
          {Modaltype()}
        </Box>
      </Modal>
    </div>
  );
};

export default CommonDialoge;
