import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Pagination = ({ module, page, onPageChange, totalPages }) => {
  return (
    <div className="pagination">
      <p>
        {/* <span>Showing {module?.length ? `${module?.length}` : 0} items </span> */}
      </p>
      <div className="pages">
        <button
          className="prevbtn"
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
        >
          <ArrowBackIosIcon />
        </button>
        <div className="count">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              className={page === index + 1 ? "actv" : "inActv"}
              key={index}
              onClick={() => onPageChange(index + 1)}
              disabled={page === index + 1}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button
          className="prevbtn"
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages}
        >
          <ArrowForwardIosIcon />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
