export const VenueConstant ={
    GET_VENUE_REQUEST: "GET_VENUE_REQUEST",
    GET_VENUE_LOADING: "GET_VENUE_LOADING",
    GET_VENUE_SUCCESS: "GET_VENUE_SUCCESS",
    GET_VENUE_ERROR: "GET_VENUE_ERROR",

    GET_VENUE_REQUEST_By_Id: "GET_VENUE_REQUEST_By_Id",
    GET_VENUE_LOADING_By_Id: "GET_VENUE_LOADING_By_Id",
    GET_VENUE_SUCCESS_By_Id: "GET_VENUE_SUCCESS_By_Id",
    GET_VENUE_ERROR_By_Id: "GET_VENUE_ERROR_By_Id",

    ADD_VENUE_REQUEST: "ADD_VENUE_REQUEST",
    ADD_VENUE_LOADING: "ADD_VENUE_LOADING",
    ADD_VENUE_SUCCESS: "ADD_VENUE_SUCCESS",
    ADD_VENUE_ERROR: "ADD_VENUE_ERROR",

    EDIT_VENUE_REQUEST: "EDIT_VENUE_REQUEST",
    EDIT_VENUE_LOADING: "EDIT_VENUE_LOADING",
    EDIT_VENUE_SUCCESS: "EDIT_VENUE_SUCCESS",
    EDIT_VENUE_ERROR: "EDIT_VENUE_ERROR",

    DELETE_VENUE_REQUEST: "DELETE_VENUE_REQUEST",
    DELETE_VENUE_LOADING: "DELETE_VENUE_LOADING",
    DELETE_VENUE_SUCCESS: "DELETE_VENUE_SUCCESS",
    DELETE_VENUE_ERROR: "DELETE_VENUE_ERROR",

}