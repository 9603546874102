import React, { useState } from "react";
import Layout from "../layout";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Button, InputField } from "../component";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AddCmsAction,
  DeleteFaqAction,
  GetCmsAction,
  uploadImageAction,
} from "../redux/actions";
import { TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Cms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [imageType, setImageType] = useState(0);

  const [selectedDocs, setSelectedDocs] = useState([]);
  const [termsPdf, setTermsPdf] = useState([]);
  const [aboutPdf, setAboutPdf] = useState([]);
  const [faq, setFaq] = useState([]);
  const { getCms } = useSelector((state) => state.CmsReducer);
  const { imageRes } = useSelector((state) => state.CommonReducer);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      facebookLink: getCms ? getCms?.contactUs?.facebook : "",
      instagramLink: getCms?.contactUs?.instagram || "",
    },
    validationSchema: Yup.object({
      facebookLink: Yup.string()
        .required("Facebook Link is required")
        .matches(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
          "Enter correct url!"
        ),
      instagramLink: Yup.string()
        .required("Instagram Link is required")
        .matches(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
          "Enter correct url!"
        ),
    }),
    onSubmit: async () => {
      const body = {
        contactUs: {
          facebook: formik.values.facebookLink,
          instagram: formik.values.instagramLink,
        },
      };
      dispatch(AddCmsAction.request(body));
    },
  });

  //update Privacy policy
  const updatePrivacyPolicy = (pdf) => {
    if (pdf) {
      let body = {
        privacyPolicy: pdf,
      };
      dispatch(AddCmsAction.request(body));
      setTimeout(() => {
        dispatch(GetCmsAction.request());
        // successToast("Update successfully");
      }, 1000);
    }
  };

  //update Privacy policy
  const updateTerms = (pdf) => {
    if (pdf) {
      let body = {
        termsAndConditions: pdf,
      };
      dispatch(AddCmsAction.request(body));
      setTimeout(() => {
        dispatch(GetCmsAction.request());
        // successToast("Update successfully");
      }, 1000);
    }
  };

  //update Aboutus
  const updateAboutUs = (pdf) => {
    if (pdf) {
      let body = {
        aboutUs: pdf,
      };
      dispatch(AddCmsAction.request(body));
      setTimeout(() => {
        dispatch(GetCmsAction.request());
        // successToast("Update successfully");
      }, 1000);
    }
  };

  const uploadImage = (val) => {
    setImageType(1);
    const files = val.target;
    const file = files?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadImageAction.request(formData));
  };
  useEffect(() => {
    if (imageRes && imageType === 1) {
      if (value === 0) {
        updatePrivacyPolicy(imageRes?.image);
      } else if (value === 1) {
        updateTerms(imageRes?.image);
      } else {
        updateAboutUs(imageRes?.image);
      }
    }
  }, [imageRes]);

  useEffect(() => {
    setSelectedDocs([{ uri: getCms?.privacyPolicy }]);
    setTermsPdf([{ uri: getCms?.termsAndConditions }]);
    setAboutPdf([{ uri: getCms?.aboutUs }]);
    setFaq(getCms?.faq);
  }, [getCms]);

  useEffect(() => {
    dispatch(GetCmsAction.request());
  }, []);

  return (
    <Layout>
      <div className="cms">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Privacy Policy" {...a11yProps(0)} />
              <Tab label="Terms & Condition" {...a11yProps(1)} />
              <Tab label="About Us" {...a11yProps(2)} />
              <Tab label="FAQ's" {...a11yProps(3)} />
              <Tab label="Contact Us" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="rightBtn"></div>
            <div className="pdfUploader">
              <div className="pdf">
                {selectedDocs?.length ? (
                  <DocViewer
                    className="docdiv"
                    documents={selectedDocs}
                    initialActiveDocument={selectedDocs[0]}
                    pluginRenderers={DocViewerRenderers}
                    prefetchMethod="GET"
                    resetOnUnmount={false}
                  />
                ) : null}

                <div className="pdfAdd">
                  <lable>
                    <TextField
                      className="uploadder"
                      inputProps={{
                        accept: "application/pdf",
                      }}
                      id="icon-button-file"
                      style={{ position: "relative", zIndex: 1 }}
                      type="file"
                      onChange={(el) => {
                        if (el.target.files[0].type.includes("application")) {
                          el.target.files?.length && uploadImage(el);
                        }
                      }}
                    />
                    <EditIcon className="addIcon" />
                  </lable>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div className="rightBtn"></div>
            <div className="pdfUploader">
              <div className="pdf">
                <DocViewer
                  className="docdiv"
                  documents={termsPdf}
                  initialActiveDocument={termsPdf[0]}
                  pluginRenderers={DocViewerRenderers}
                  prefetchMethod="GET"
                  resetOnUnmount={false}
                />
                <div className="pdfAdd">
                  <lable>
                    <TextField
                      className="uploadder"
                      inputProps={{
                        accept: "application/pdf",
                      }}
                      id="icon-button-file"
                      style={{ position: "relative", zIndex: 1 }}
                      type="file"
                      onChange={(el) => {
                        if (el.target.files[0].type.includes("application")) {
                          el.target.files?.length && uploadImage(el);
                        }
                      }}
                    />
                    <EditIcon className="addIcon" />
                  </lable>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <div className="rightBtn"></div>
            <div className="pdfUploader">
              <div className="pdf">
                <DocViewer
                  className="docdiv"
                  documents={aboutPdf}
                  initialActiveDocument={aboutPdf[0]}
                  pluginRenderers={DocViewerRenderers}
                  prefetchMethod="GET"
                  resetOnUnmount={false}
                />
                <div className="pdfAdd">
                  <lable>
                    <TextField
                      className="uploadder"
                      inputProps={{
                        accept: "application/pdf",
                      }}
                      id="icon-button-file"
                      style={{ position: "relative", zIndex: 1 }}
                      type="file"
                      onChange={(el) => {
                        if (el.target.files[0].type.includes("application")) {
                          el.target.files?.length && uploadImage(el);
                        }
                      }}
                    />
                    <EditIcon className="addIcon" />
                  </lable>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <div className="faq">
              {faq && faq.length
                ? faq.map((item) => {
                    return (
                      <div className="fmain">
                        <div className="fLeft">
                          <Accordion key={item?._id}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <div className="headerMain">{item?.question}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{item?.answer}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <div className="faqIcon">
                          <EditIcon
                            className="deleteIcon"
                            style={{ fill: "green" }}
                            onClick={() => navigate(`/edit-faq/${item?._id}`)}
                          />
                          <DeleteIcon
                            className="deleteIcon"
                            onClick={() =>
                              dispatch(DeleteFaqAction.request(item?._id))
                            }
                          />
                        </div>
                      </div>
                    );
                  })
                : ""}
              <div className="faqic">
                <AddIcon
                  className="addIcon"
                  onClick={() => navigate("/add-faq")}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <form onSubmit={formik.handleSubmit}>
              <div className="formArea">
                <div className="formControl">
                  <label>Facebook Link check</label>
                  <InputField
                    placeholder="Enter Facebook Link"
                    name="facebookLink"
                    value={formik.values.facebookLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.facebookLink && formik.errors.facebookLink
                    }
                  />
                </div>
                <div className="formControl">
                  <label>Instagram Link</label>
                  <InputField
                    placeholder="Enter Instagram Link"
                    name="instagramLink"
                    value={formik.values.instagramLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.instagramLink &&
                      formik.errors.instagramLink
                    }
                  />
                </div>
              </div>
              {getCms ? <Button name="Update" /> : <Button name="Save" />}
            </form>
          </TabPanel>
        </Box>
      </div>
    </Layout>
  );
};

export default Cms;
