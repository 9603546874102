import { EventConstant } from "../constant/EventConstant";

const initialState = {
  eventLoading: false,
  eventData: null,
  eventDelete: null,
  editEvent: null,
  editTicket: null,
  ticketDelete: null,
};

export const EventReducer = (state = initialState, action) => {
  switch (action.type) {
    case EventConstant.GET_EVENT_LOADING:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.GET_EVENT_SUCCESS:
      return {
        ...state,
        eventLoading: action.loading,
        eventData: action.res,
      };
    case EventConstant.GET_EVENT_ERROR:
      return {
        ...state,
        eventLoading: action.loading,
        eventData: null,
      };
    case EventConstant.ADD_EVENT_LOADING:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.ADD_EVENT_SUCCESS:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.ADD_EVENT_ERROR:
      return {
        ...state,
        eventLoading: action.loading,
      };
    //by id
    case EventConstant.GET_EVENT_LOADING_BY_ID:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.GET_EVENT_SUCCESS_BY_ID:
      return {
        ...state,
        eventLoading: action.loading,
        editEvent: action.res,
      };
    case EventConstant.GET_EVENT_ERROR_BY_ID:
      return {
        ...state,
        eventLoading: action.loading,
      };
    //
    case EventConstant.EDIT_EVENT_LOADING:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        eventLoading: action.loading,
        editEvent: action.res,
      };
    case EventConstant.EDIT_EVENT_ERROR:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.DELETE_EVENT_LOADING:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        eventLoading: action.loading,
        eventDelete: action.res,
      };
    case EventConstant.DELETE_EVENT_ERROR:
      return {
        ...state,
        eventLoading: action.loading,
        eventDelete: null,
      };
    //ticket
    case EventConstant.EDIT_TICKET_LOADING:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.EDIT_TICKET_SUCCESS:
      return {
        ...state,
        eventLoading: action.loading,
        editTicket: action.res,
      };
    case EventConstant.EDIT_TICKET_ERROR:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.DELETE_TICKET_LOADING:
      return {
        ...state,
        eventLoading: action.loading,
      };
    case EventConstant.DELETE_TICKET_SUCCESS:
      return {
        ...state,
        eventLoading: action.loading,
        ticketDelete: action.res,
      };
    case EventConstant.DELETE_TICKET_ERROR:
      return {
        ...state,
        eventLoading: action.loading,
        ticketDelete: null,
      };
    default:
      return state;
  }
};
