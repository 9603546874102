import { CommonConstant } from "../constant";

const modalVisible = {
  modalOpen: (dialogeType, payload) => ({
    type: CommonConstant.SHOW_DIALOG,
    isOpen: true,
    dialogeType: dialogeType,
    payload,
  }),
  modalClose: () => ({
    type: CommonConstant.HIDE_DIALOG,
    isClose: false,
  }),
};

const uploadImageAction = {
  request: (body) => ({
    type: CommonConstant.UPLOAD_IMAGE_REQUEST,
    body,
  }),
  loading: () => ({
    type: CommonConstant.UPLOAD_IMAGE_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: CommonConstant.UPLOAD_IMAGE_SUCCESS,
    loading: false,
    res,
  }),
  error: (data) => ({
    type: CommonConstant.UPLOAD_IMAGE_ERROR,
    loading: false,
    data,
  }),
};

const uploadMultipleImageAction = {
  request: (body) => ({
    type: CommonConstant.UPLOAD_MULTI_IMAGE_REQUEST,
    body,
  }),
  loading: () => ({
    type: CommonConstant.UPLOAD_MULTI_IMAGE_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: CommonConstant.UPLOAD_MULTI_IMAGE_SUCCESS,
    loading: false,
    res,
  }),
  error: (data) => ({
    type: CommonConstant.UPLOAD_MULTI_IMAGE_ERROR,
    loading: false,
    data,
  }),
};

const resetAuth = {
  authorise: () => ({
    type: CommonConstant.UPDATE_AUTHORIZATION,
    isAuthorised: true,
  }),
  unAuthorise: () => ({
    type: CommonConstant.UPDATE_AUTHORIZATION,
    isAuthorised: false,
  }),
};

export {
  modalVisible,
  uploadImageAction,
  uploadMultipleImageAction,
  resetAuth,
};
