import React from "react";
import Layout from "../../layout";
import Title from "../../component/Title";
import InputField from "../../component/InputField";
import Button from "../../component/Button";

const AddGuestList = () => {
  return (
    <Layout>
      <Title title="Add Guest List" />
      <div className="addData">
        <div className="formGroup">
          <div className="formControl">
            <label>Person Name</label>
            <InputField placeholder="Enter Person Name" />
          </div>
          <div className="formControl">
            <label>Person Phone Number</label>
            <InputField placeholder="Enter Person Phone Number" />
          </div>
          <div className="formControl">
            <label>Number of Male</label>
            <InputField placeholder="Enter Number of Male" />
          </div>
          <div className="formControl">
            <label>Number of Female</label>
            <InputField placeholder="Enter Number of Female" />
          </div>
          <div className="formControl">
            <label>Reference</label>
            <InputField placeholder="Enter Reference" />
          </div>
        </div>
        <Button name="Save" />
      </div>
    </Layout>
  );
};

export default AddGuestList;
