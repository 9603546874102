import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useSelector } from "react-redux";

const FloorModal = () => {
  const venueData = useSelector((state) => state.VenueReducer.venueData);
  const { modalPayload } = useSelector((state) => state.CommonReducer);
  const baseUrl = "./images";
  const settings = {
    // customPaging: function (i) {
    //   return (
    //     <a>
    //       <img src={`${baseUrl}/banner0${i + 1}.jpg`} alt="" />
    //     </a>
    //   );
    // },
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="menuModal">
      <h2> Floor Plan</h2>
      {venueData?.length
        ? venueData?.map((item) => {
            return (
              <Slider {...settings} key={item?.id}>
                {item?._id === modalPayload?.id ? (
                  <div>
                    <figure>
                      <img
                        src={item?.floorPlan || "/images/floor.jpg"}
                        alt=""
                      />
                    </figure>
                  </div>
                ) : null}
              </Slider>
            );
          })
        : null}
    </div>
  );
};

export default FloorModal;
