export const EventConstant = {
  GET_EVENT_REQUEST: "GET_EVENT_REQUEST",
  GET_EVENT_LOADING: "GET_EVENT_LOADING",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_ERROR: "GET_EVENT_ERROR",

  GET_EVENT_REQUEST_BY_ID: "GET_EVENT_REQUEST_BY_ID",
  GET_EVENT_LOADING_BY_ID: "GET_EVENT_LOADING_BY_ID",
  GET_EVENT_SUCCESS_BY_ID: "GET_EVENT_SUCCESS_BY_ID",
  GET_EVENT_ERROR_BY_ID: "GET_EVENT_ERROR_BY_ID",

  ADD_EVENT_REQUEST: "ADD_EVENT_REQUEST",
  ADD_EVENT_LOADING: "ADD_EVENT_LOADING",
  ADD_EVENT_SUCCESS: "ADD_EVENT_SUCCESS",
  ADD_EVENT_ERROR: "ADD_EVENT_ERROR",

  EDIT_EVENT_REQUEST: "EDIT_EVENT_REQUEST",
  EDIT_EVENT_LOADING: "EDIT_EVENT_LOADING",
  EDIT_EVENT_SUCCESS: "EDIT_EVENT_SUCCESS",
  EDIT_EVENT_ERROR: "EDIT_EVENT_ERROR",

  DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
  DELETE_EVENT_LOADING: "DELETE_EVENT_LOADING",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_ERROR: "DELETE_EVENT_ERROR",

  EDIT_TICKET_REQUEST: "EDIT_TICKET_REQUEST",
  EDIT_TICKET_LOADING: "EDIT_TICKET_LOADING",
  EDIT_TICKET_SUCCESS: "EDIT_TICKET_SUCCESS",
  EDIT_TICKET_ERROR: "EDIT_TICKET_ERROR",

  DELETE_TICKET_REQUEST: "DELETE_TICKET_REQUEST",
  DELETE_TICKET_LOADING: "DELETE_TICKET_LOADING",
  DELETE_TICKET_SUCCESS: "DELETE_TICKET_SUCCESS",
  DELETE_TICKET_ERROR: "DELETE_TICKET_ERROR",
};
