import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { VenueConstant } from "../../constant/VenueConstant";
import { errorToast, successToast } from "../../../utils";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* getVenue(payload) {
  const { body, setTotalCount } = payload;
  try {
    yield put(actions.GetVenueAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url.getVenue);
    if (res?.statusCode === 200) {
      yield put(actions.GetVenueAction.success(res?.data?.Venue));
      setTotalCount(res?.data?.totalVenue);
    } else {
      yield put(actions.GetVenueAction.error(res?.message));
    }
  } catch (error) {
    yield put(actions.GetVenueAction.error(error));
  }
}

export function* getVenueById(payload) {
  const { id, body } = payload;
  try {
    yield put(actions.GetVenueActionById.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url.getVenue + "/" + id);
    if (res?.statusCode === 200) {
      yield put(actions.GetVenueActionById.success(res?.data));
    } else {
      yield put(actions.GetVenueActionById.error(res?.message));
    }
  } catch (error) {
    yield put(actions.GetVenueActionById.error(error));
  }
}

export function* AddVenue(payload) {
  const { body, navigate } = payload;
  try {
    yield put(actions.AddVenueAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url.addVenue);
    if (res?.statusCode === 200) {
      yield put(actions.AddVenueAction.success());
      successToast(res?.message);
      navigate("/venues");
    } else {
      yield put(actions.AddVenueAction.error(res?.message));
      errorToast(res.message);
    }
  } catch (error) {
    yield put(actions.AddVenueAction.error(error));
  }
}

export function* EditVenue(payload) {
  const { id, body, navigate } = payload;
  try {
    yield put(actions.EditVenueAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.putApi, body, url.editVenue + id + "/");
    if (res?.statusCode === 200) {
      yield put(actions.EditVenueAction.success(res?.data));
      if (body) {
        successToast("Venue updated successfully");
        navigate("/venues");
      }
    } else {
      yield put(actions.EditVenueAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.EditVenueAction.error(error));
  }
}

export function* deleteVenue(payload) {
  const { id } = payload;
  try {
    yield put(actions.DeleteVenueAction.loading(true));
    yield delay(1000);
    const deleteUrl = `${url.deleteVenue}/${id}`;
    const res = yield call(apis.delete_form, deleteUrl);
    if (res?.statusCode === 200) {
      successToast(res?.message || "");
      yield put(actions.DeleteVenueAction.success(res));
    } else {
      errorToast(res?.message || "");
    }
  } catch (error) {
    yield put(actions.DeleteVenueAction.error(error));
  }
}

function* VenueSaga() {
  yield all([takeEvery(VenueConstant.GET_VENUE_REQUEST, getVenue)]);
  yield all([takeEvery(VenueConstant.GET_VENUE_REQUEST_By_Id, getVenueById)]);
  yield all([takeEvery(VenueConstant.ADD_VENUE_REQUEST, AddVenue)]);
  yield all([takeEvery(VenueConstant.DELETE_VENUE_REQUEST, deleteVenue)]);
  yield all([takeEvery(VenueConstant.EDIT_VENUE_REQUEST, EditVenue)]);
}

export default VenueSaga;
