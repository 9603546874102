import { FAQConstant } from "../constant";

const AddFaqAction = {
  request: (body, navigate) => ({
    type: FAQConstant.ADD_FAQ_REQUEST,
    body,
    navigate,
  }),
  loading: () => ({
    type: FAQConstant.ADD_FAQ_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: FAQConstant.ADD_FAQ_REQUEST,
    loading: false,
    res,
  }),
  error: () => ({
    type: FAQConstant.ADD_FAQ_ERROR,
    loading: false,
  }),
};

const DeleteFaqAction = {
  request: (id) => ({
    type: FAQConstant.DELETE_FAQ_REQUEST,
    id,
  }),
  loading: () => ({
    type: FAQConstant.DELETE_FAQ_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: FAQConstant.DELETE_FAQ_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: FAQConstant.DELETE_FAQ_ERROR,
    loading: false,
  }),
};

const UpdateFaqAction = {
  request: (id, body, navigate) => ({
    type: FAQConstant.EDIT_FAQ_REQUEST,
    id,
    body,
    navigate,
  }),
  loading: () => ({
    type: FAQConstant.EDIT_FAQ_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: FAQConstant.EDIT_FAQ_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: FAQConstant.EDIT_FAQ_ERROR,
    loading: false,
  }),
};

export { AddFaqAction, DeleteFaqAction, UpdateFaqAction };
