import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { errorToast, successToast } from "../../../utils";
import { FAQConstant } from "../../constant";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* addFaq(payload) {
  const { body, navigate } = payload;
  try {
    yield put(actions.AddFaqAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url.addFaq);
    if (res?.statusCode === 200) {
      successToast(res?.message);
      navigate("/cms");
    } else {
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.AddFaqAction.error(error));
  }
}

export function* deleteFaq(payload) {
  const { id } = payload;
  try {
    yield put(actions.DeleteFaqAction.loading(true));
    yield delay(1000);
    const deleteUrl = `${url.deleteFaq}/${id}`;
    const res = yield call(apis.delete_form, deleteUrl);
    if (res?.statusCode === 200) {
      yield put(actions.DeleteFaqAction.success(res));
      successToast(res?.message);
      yield put(actions.GetCmsAction.request());
    } else {
      errorToast(res?.message || "");
    }
  } catch (error) {
    yield put(actions.DeleteFaqAction.error(error));
  }
}

export function* updatedFaq(payload) {
  const { id, body, navigate } = payload;
  try {
    yield put(actions.UpdateFaqAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.putApi, body, url.editFaq + id + "/");
    if (res?.statusCode === 200) {
      yield put(actions.UpdateFaqAction.success(res?.data));
      if (body) {
        successToast("Faq updated successfully");
        navigate("/cms");
      }
    } else {
      yield put(actions.UpdateFaqAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.UpdateFaqAction.error(error));
  }
}

function* FaqSaga() {
  yield all([takeEvery(FAQConstant.ADD_FAQ_REQUEST, addFaq)]);
  yield all([takeEvery(FAQConstant.DELETE_FAQ_REQUEST, deleteFaq)]);
  yield all([takeEvery(FAQConstant.EDIT_FAQ_REQUEST, updatedFaq)]);
}

export default FaqSaga;
