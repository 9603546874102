import React from "react";
import "./component.scss";

const Table = ({ data, headings }) => {
  const columns = Object.keys(data[0] || {});
  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            {headings?.map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row) => (
            <tr key={row.id}>
              {columns.map((column) => (
                <td key={column}>{row[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
