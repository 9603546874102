export const CommonConstant = {
    SHOW_DIALOG : "SHOW_DIALOG",
    HIDE_DIALOG : "HIDE_DIALOG", 

    UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
    UPLOAD_IMAGE_LOADING: "UPLOAD_IMAGE_LOADING",
    UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
    UPLOAD_IMAGE_ERROR: "UPLOAD_IMAGE_ERROR",

    UPLOAD_MULTI_IMAGE_REQUEST: "UPLOAD_MULTI_IMAGE_REQUEST",
    UPLOAD_MULTI_IMAGE_LOADING: "UPLOAD_MULTI_IMAGE_LOADING",
    UPLOAD_MULTI_IMAGE_SUCCESS: "UPLOAD_MULTI_IMAGE_SUCCESS",
    UPLOAD_MULTI_IMAGE_ERROR: "UPLOAD_MULTI_IMAGE_ERROR",


    UPDATE_AUTHORIZATION: 'UPDATE_AUTHORIZATION',
    UPDATE_TEMP_DATA:'UPDATE_TEMP_DATA'
}