import React from "react";
import Layout from "../../layout";
import Title from "../../component/Title";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import InputField from "../../component/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../component/Button";
import Table from "../../component/Table";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetBookingAction } from "../../redux/actions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Booking = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location.pathname;
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const guestList = [
    {
      id: 1,
      eventId: 1234,
      eventName: "Dummy",
      reference: "dummy data",
      userDetail: "Jerry (9876543210)",
      Status: "Pending",
      bookingId: 2,
      dateTime: "23-09-2023/09:00",
    },
  ];

  const ticketHeadings = [
    "#",
    "Booking ID",
    "Booking Date/Time",
    "Event ID",
    "Event Name",
    "Event Date",
    "User Details",
    "Slot",
    "Males",
    "Females",
    "Couples",
    "Tickets Amount",
    "Convenience fee",
    "GST",
    "Total Amount",
    "Transaction ID",
  ];
  const ticketData = [
    {
      id: 1,
      bookingId: 123,
      dateTime: "09-09-2022 / 09:00",
      eventId: 12,
      eventName: "dummy",
      dateTim2e: "09-09-2022 / 09:00",
      userDetail: "Herry (987654321)",
      slot: "Upto 07 PM",
      male: 2,
      female: 2,
      couple: 2,
      amount: 2,
      fee: 2,
      gst: 3,
      gst2: 3,
      amount: 10,
      transcation: "ASD0909",
    },
  ];

  const guestHeadings = [
    "#",
    "User Detail",
    "No. of people",
    "Reference",
    "Date/Time",
    "Event Name",
    "Event Date/Time",
    "Venue Name",
  ];
  const guestData = [
    {
      id: 1,
      userDetail: "Jerry (9087654321)",
      people: 4,
      ref: "-",
      dateTime: "09-09-2022 / 09:00",
      eventName: "dummy",
      dateTime2: "09-09-2022 / 09:00",
      eventName1: "dummy",
    },
  ];

  useEffect(() => {
    dispatch(GetBookingAction.request());
  }, []);

  return (
    <Layout>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Guest List" {...a11yProps(0)} />
            <Tab label="Is Table" {...a11yProps(1)} />
            <Tab label="Is Ticket" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="rightSide">
            <Button name="+" onClick={() => navigate("/AddGuestList")} />
          </div>
          <div className="search">
            <div className="formControl">
              {pathname === "/eventbooking" ? (
                <InputField placeholder="Search with Booking Id, Person Name" />
              ) : (
                <InputField placeholder="Search with Person Name" />
              )}
            </div>
          </div>
          <Table data={guestData} headings={guestHeadings} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="table-responsive centerCls">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  {pathname === "/eventbooking" ? <th>Booking Id</th> : ""}
                  <th>Booking ID</th>
                  <th>Booking Date/Time</th>
                  <th>Event ID</th>
                  <th>Event Name</th>
                  <th>Event Date </th>
                  <th>User details</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {guestList.map((item, i) => {
                  return (
                    <tr key={item.id}>
                      <td>{i + 1}</td>
                      {pathname === "/eventbooking" ? <td>0967</td> : ""}
                      <td
                        onClick={() => navigate("/AddComment")}
                        style={{ color: "gold", cursor: "pointer" }}
                      >
                        {item?.bookingId}
                      </td>
                      <td>{item?.dateTime}</td>
                      <td>{item?.eventId}</td>
                      <td>{item?.eventName || ""}</td>
                      <td>{item?.dateTime}</td>
                      <td>{item?.userDetail}</td>
                      <td>{item?.Status}</td>
                      <td>
                        <Tooltip title="Block">
                          <IconButton>
                            <CheckCircleIcon style={{ fill: "green" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton>
                            <CancelIcon style={{ fill: "red" }} />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Table data={ticketData} headings={ticketHeadings} />
        </TabPanel>
      </Box>
    </Layout>
  );
};

export default Booking;
