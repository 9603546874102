import "./Auth.scss";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ResetPassword } from "../redux/actions";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Reset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("New Password is required!"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required!")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: () => {
      const body = {
        newPassword: formik.values.newPassword,
        id:'64afa5d3acdd5d589d6afcfa',
      };
      dispatch(ResetPassword.request(body, navigate));
    },
  });

  return (
    <div className="loginScreen">
      <div className="login">
        <div className="lLeft">
          <div className="logo">
            <h3>
              <span>Lo</span>go
            </h3>
          </div>
          <div className="title">
            <h2>
              Reset Password <span>lorem ipsum is a dummy</span>
            </h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="authForm">
              <div className="formControl">
                <TextField
                  className="text_field"
                  placeholder="Enter New Password"
                  sx={{ p: "0px", width: "100%" }}
                  type={showNewPassword ? "text" : "password"}
                  margin="none"
                  name="newPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge="end"
                        >
                          {!showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="formControl">
                <TextField
                  className="text_field"
                  placeholder="Enter Confirm Password"
                  sx={{ p: "0px", width: "100%" }}
                  type={showConfirmPassword ? "text" : "password"}
                  margin="none"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {!showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="saveBtn">
                <button>Save</button>
              </div>
            </div>
          </form>
        </div>
        <div className="right"></div>
      </div>
    </div>
  );
};

export default Reset;
