import { VenueConstant } from "../constant/VenueConstant";

const initialState = {
  venueLoading: false,
  venueData: null,
  venueDelete: null,
  editVenue: null,
};

export const VenueReducer = (state = initialState, action) => {
  switch (action.type) {
    case VenueConstant.GET_VENUE_LOADING:
      return {
        ...state,
        venueLoading: action.loading,
      };
    case VenueConstant.GET_VENUE_SUCCESS:
      return {
        ...state,
        venueLoading: action.loading,
        venueData: action.res,
      };
    case VenueConstant.GET_VENUE_ERROR:
      return {
        ...state,
        venueLoading: action.loading,
        venueData: null,
      };
    case VenueConstant.ADD_VENUE_LOADING:
      return {
        ...state,
        venueLoading: action.loading,
      };
    case VenueConstant.ADD_VENUE_SUCCESS:
      return {
        ...state,
        venueLoading: action.loading,
      };
    case VenueConstant.ADD_VENUE_ERROR:
      return {
        ...state,
        venueLoading: action.loading,
      };
    case VenueConstant.EDIT_VENUE_LOADING:
      return {
        ...state,
        venueLoading: action.loading,
      };
    case VenueConstant.EDIT_VENUE_SUCCESS:
      return {
        ...state,
        venueLoading: action.loading,
        editVenue: action.res,
      };
    case VenueConstant.EDIT_VENUE_ERROR:
      return {
        ...state,
        venueLoading: action.loading,
      };

       //by id 
       case VenueConstant.GET_VENUE_LOADING_By_Id:
        return {
          ...state,
          venueLoading: action.loading,
        };
      case VenueConstant.GET_VENUE_SUCCESS_By_Id:
        return {
          ...state,
          venueLoading: action.loading,
          editVenue: action.res,
        };
      case VenueConstant.GET_VENUE_ERROR_By_Id:
        return {
          ...state,
          venueLoading: action.loading,
        };
    //
    case VenueConstant.DELETE_VENUE_LOADING:
      return {
        ...state,
        venueLoading: action.loading,
      };
    case VenueConstant.DELETE_VENUE_SUCCESS:
      return {
        ...state,
        venueLoading: action.loading,
        venueDelete: action.res,
      };
    case VenueConstant.DELETE_VENUE_ERROR:
      return {
        ...state,
        venueLoading: action.loading,
        venueDelete: null,
      };
    default:
      return state;
  }
};
