import { TextField } from '@mui/material'
import React from 'react'

const InputField = (props) => {
  return (
    <TextField
      className='text_field'
      placeholder={props.placeholder}
      sx={{ p: "0px", width: "100%" }}
      type="text"
      margin="none"
      variant="standard"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      helperText={props.helperText}
    />

  )
}

export default InputField;
