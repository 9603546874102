import React from "react";
import Layout from "../../layout";
import Title from "../../component/Title";
// import SelectField from "../../component/SelectField";
import TextareaField from "../../component/TextareaField";
import SwitchBtn from "../../component/SwitchBtn";
import Button from "../../component/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useState } from "react";
import InputField from "../../component/InputField";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {
  AddEventAction,
  // DeleteTicketAction,
  EditEventAction,
  EditTicketAction,
  GetEntitiesAction,
  GetEventAction,
  GetEventActionById,
  GetVenueAction,
  uploadImageAction,
  // uploadMultipleImageAction,
} from "../../redux/actions";
// import { errorToast } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import moment from "moment";
// import { UPLOAD_MEDIA, UPLOAD_URL } from "../../constants/Urls";
import { MenuItem, Select, TextField } from "@mui/material";
import { isNumber } from "../../utils/validations";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import Box from "@mui/material/Box";

const AddEvent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  // const [type, setType] = useState();
  // const [final, setFinal] = useState([]);
  const [guest, setGuest] = useState(false);
  const [error, setError] = useState(false);
  const [ticket, setTicket] = useState(true);
  const [istable, setIsTable] = useState(false);
  const [myImages, setMyImages] = useState([]);

  // console.log("myImages", myImages);

  const [musicType, setMusicType] = useState("");

  const [venuetype, setVenuetype] = useState([]);
  const [eventVenue, setEventVenue] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [selectVenue, setSelectValue] = useState("");
  const [musictypeArr, setMusicTypeArr] = useState([]);
  const [bannerImages, setBannerImages] = useState("");

  console.log("bannerImages", bannerImages);

  const [arriveDateTime, setArriveDateTime] = useState("");
  const [startDateTime, setStartDateTime] = useState(null);
  // const [ticketTimeSlot, setTicketTimeSlot] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [ticketId, setTicketId] = useState("");
  const [editTicketBox, setEditTicketBox] = useState(false);
  const [imageType, setImageType] = useState(0);

  const { imageRes } = useSelector((state) => state.CommonReducer);

  // console.log("imageRes cc", imageRes);

  const { editEvent } = useSelector((state) => state.EventReducer);
  const { entitiesData } = useSelector((state) => state.EntitiesReducer);
  const venueData = useSelector((state) => state.VenueReducer.venueData);

  console.log("editEvent data", editEvent);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      eventName: pathname === `/editevent/${id}` ? editEvent?.name : "",
      description:
        pathname === `/editevent/${id}` ? editEvent?.description : "",
      isFeatured:
        pathname === `/editevent/${id}` ? editEvent?.isFeatured : false,
      isPopular: pathname === `/editevent/${id}` ? editEvent?.isPopular : false,
      isBookingAvailable:
        pathname === `/editevent/${id}` ? editEvent?.isBookingAvailable : false,
      isHighPriority:
        pathname === `/editevent/${id}` ? editEvent?.isHighPriority : false,
      isTickets: pathname === `/editevent/${id}` ? editEvent?.isTickets : false,
      isTable: pathname === `/editevent/${id}` ? editEvent?.isTable : true,
      noPeople:
        pathname === `/editevent/${id}`
          ? editEvent?.tableDetails?.totalPersons
          : "",
      tableNotes:
        pathname === `/editevent/${id}` ? editEvent?.tableDetails?.notes : "",
      tableDetail:
        pathname === `/editevent/${id}` ? editEvent?.tableDetails?.details : "",
      startDate: "",
      //is tickets
      ticketNote: "",
      ticketDetail: "",
      maleCount: "",
      femaleCount: "",
      coupleCount: "",
      malePrice: "",
      femalePrice: "",
      couplePrice: "",
      ticketTimeSlot: "",
      startTime:
        editEvent?.startTime && pathname === `/editevent/${id}`
          ? moment(editEvent?.startTime).format("YYYY-MM-DDTHH:mm")
          : "",
      endTime:
        editEvent?.endTime && pathname === `/editevent/${id}`
          ? moment(editEvent?.endTime).format("YYYY-MM-DDTHH:mm")
          : "",
    },
    validationSchema: Yup.object({
      eventName: Yup.string().required("Required!"),
      description: Yup.string().required("Required!"),
      isFeatured: Yup.boolean(),
      isPopular: Yup.boolean(),
      isBookingAvailable: Yup.boolean(),
      isHighPriority: Yup.boolean(),
      isTickets: Yup.boolean(),
      isTable: Yup.boolean(),
      noPeople: istable
        ? Yup.string().required("Required!")
        : Yup.string().notRequired(),
      tableNotes: istable
        ? Yup.string().required("Required!")
        : Yup.string().notRequired(),
      tableDetail: istable
        ? Yup.string().required("Required!")
        : Yup.string().notRequired(),
      endTime: Yup.string().required("Required!"),
      startTime: Yup.string().required("Required!"),
      //is ticket
      // ticketNote: ticket
      //   ? Yup.string().required("This field is required")
      //   : Yup.string().notRequired(),
      // ticketDetail: ticket
      //   ? Yup.string().required("This field is required")
      //   : Yup.string().notRequired(),
      // maleCount: ticket
      //   ? Yup.string().required("This field is required")
      //   : Yup.string().notRequired(),
      // femaleCount: ticket
      //   ? Yup.string().required("This field is required")
      //   : Yup.string().notRequired(),
      // coupleCount: ticket
      //   ? Yup.string().required("This field is required")
      //   : Yup.string().notRequired(),
      // malePrice: ticket
      //   ? Yup.string().required("This field is required")
      //   : Yup.string().notRequired(),
      // femalePrice: ticket
      //   ? Yup.string().required("This field is required")
      //   : Yup.string().notRequired(),
      // couplePrice: ticket
      //   ? Yup.string().required("This field is required")
      //   : Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      // let images = [];

      // if (myImages?.length) {
      //   await Promise.all(
      //     myImages?.map(async (item) => {
      //       console.log(item, "itemm");
      //       const formData = new FormData();
      //       formData.append("file", item.file);
      //       let headers = {
      //         Accept: "application/json",
      //       };
      //       try {
      //         const res = await fetch(UPLOAD_URL + UPLOAD_MEDIA, {
      //           method: "POST",
      //           headers,
      //           body: formData,
      //         });
      //         let response = await res.json();
      //         if (response?.statusCode === 200) {
      //           images?.push(response?.data?.image);
      //         }
      //       } catch (error) {
      //         console.log(error, ">>>>>>>>>");
      //       }
      //     })
      //   );
      // }

      if (!musicType || !eventVenue || !selectVenue || !bannerImages?.length) {
        return;
      }
      const body = {
        venueId: selectVenue,
        name: formik.values.eventName,
        description: formik.values.description,
        musicType: musicType,
        eventType: eventVenue,
        startTime: moment(formik.values.startTime)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endTime: moment(formik.values.endTime)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        // startTime: moment(startDateTime)
        //   .utc()
        //   .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        // endTime: moment(endDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        isFeatured: formik.values.isFeatured,
        isPopular: formik.values.isPopular,
        isBookingAvailable: true,
        isHighPriority: formik.values.isHighPriority,
        isTickets: formik.values.isTickets,
        isTable: formik.values.isTable,
        banners: bannerImages,
        // tableDetails: {
        //   totalPersons: String(formik.values.noPeople),
        //   notes: formik.values.tableNotes,
        //   details: formik.values.tableDetail,
        // },
        // ticketDetails: ticketDetails,
      };
      if (formik.values.isTickets && ticketDetails?.length && !id) {
        body.ticketDetails = ticketDetails;
      }
      if (formik.values.isTable) {
        body.tableDetails = {
          totalPersons: String(formik.values.noPeople),
          notes: formik.values.tableNotes,
          details: formik.values.tableDetail,
        };
      }
      // console.log(body, "body");
      if (pathname === `/editevent/${id}`) {
        dispatch(EditEventAction.request(id, body, navigate));
      } else {
        dispatch(AddEventAction.request(body, navigate));
      }
    },
  });

  useEffect(() => {
    let body = {
      type: [1, 4],
    };
    dispatch(GetEntitiesAction.request(body));
  }, []);

  useEffect(() => {
    if (entitiesData?.length) {
      const venueTypeFilter = entitiesData.filter((item) => item.type === 4);
      const musicFilter = entitiesData.filter((item) => item.type === 1);
      setVenuetype(venueTypeFilter);
      setMusicTypeArr(musicFilter);
    }
  }, [entitiesData]);

  // const guestList = (event) => {
  //   let guestVal = event.target.checked;
  //   if (guestVal == true) {
  //     setGuest(true);
  //   } else {
  //     setGuest(false);
  //   }
  // };

  // const ticketFun = (event) => {
  //   let ticket = event.target.checked;
  //   if (ticket === true) {
  //     setTicket(true);
  //   } else {
  //     setTicket(false);
  //   }
  // };

  // const tableFun = (event) => {
  //   let table = event.target.checked;
  //   if (table === true) {
  //     setIsTable(true);
  //   } else {
  //     setIsTable(false);
  //   }
  // };

  const emptyTicketDetail = () => {
    formik.setFieldValue("ticketNote", "");
    formik.setFieldValue("ticketDetail", "");
    formik.setFieldValue("ticketTimeSlot", "");

    formik.setFieldValue("maleCount", "");
    formik.setFieldValue("malePrice", "");
    formik.setFieldValue("femalePrice", "");
    formik.setFieldValue("femaleCount", "");
    formik.setFieldValue("coupleCount", "");
    formik.setFieldValue("couplePrice", "");
  };

  const addTicketDetail = () => {
    if (
      !formik.values.ticketNote ||
      !formik.values.ticketDetail ||
      !formik.values.maleCount ||
      // !formik.values.malePrice ||
      !formik.values.femaleCount ||
      // !formik.values.femalePrice ||
      !formik.values.coupleCount ||
      // !formik.values.couplePrice ||
      !formik.values.ticketTimeSlot
    ) {
      return;
    }
    const newTicketDetail = {
      notes: formik.values.ticketNote,
      details: formik.values.ticketDetail,
      timeSlot: formik.values.ticketTimeSlot,
      male: {
        count: formik.values.maleCount,
        price: formik.values.malePrice ? formik.values.malePrice : "0",
      },
      female: {
        count: formik.values.femaleCount,
        price: formik.values.femalePrice ? formik.values.femalePrice : "0",
      },
      couple: {
        count: formik.values.coupleCount,
        price: formik.values.couplePrice ? formik.values.couplePrice : "0",
      },
    };

    // Updating the state by adding the newTicketDetail to the existing array
    setTicketDetails([...ticketDetails, newTicketDetail]);
    emptyTicketDetail();
  };

  const deleteTicketDetail = (index) => {
    const updatedTicketDetails = [...ticketDetails];
    updatedTicketDetails.splice(index, 1);
    setTicketDetails(updatedTicketDetails);
  };

  // to upload  image to api
  // const uploadImage = (val) => {
  //   // const files = val.target;
  //   // const file = files?.files[0];
  //   const formData = new FormData();
  //   formData.append("file", val);
  //   dispatch(uploadImageAction.request(formData));
  // };

  // const uploadImage = (file) => {
  //   console.log(file?.url,'filllllllll');
  //   const formData = new FormData();
  //   formData.append("files", file?.url);
  //   dispatch(uploadMultipleImageAction.request(formData));
  // };

  // useEffect(() => {
  //   if (imageRes) {
  //     setBannerImages(imageRes?.image);
  //   }
  // }, [imageRes]);

  useEffect(() => {
    if (pathname === `/editevent/${id}`) {
      const body = {
        page: 1,
        limit: 10,
        search: "",
      };
      dispatch(GetEventActionById.request(id, body));
    }
  }, [id]);

  useEffect(() => {
    if (pathname === `/editevent/${id}`) {
      setEventVenue(editEvent?.eventType);
      setMusicType(editEvent?.musicType?._id);
      setSelectValue(editEvent?.venueId?._id);
      setStartDateTime(dayjs(editEvent?.startTime));
      setEndDateTime(dayjs(editEvent?.endTime));
      setArriveDateTime(dayjs(editEvent?.tableDetails?.startTime));
      setIsTable(editEvent?.isTable);
      setTicket(editEvent?.isTickets);
      setTicketDetails(editEvent?.ticketDetails);
      setBannerImages(editEvent?.banners);

      if (editEvent?.banners?.length) {
        let newObj = editEvent?.banners?.map((item) => {
          return {
            file: item?.$d,

            url: item,
          };
        });
        setMyImages(editEvent?.banners);
        // setMyImages((prevImages) => [
        //   ...prevImages,
        //   ...editEvent.banners.map((item) => ({
        //     file: item?.$d,
        //     url: item,
        //   })),
        // ]);
      }
    }
  }, [editEvent]);

  // const handleFileUpload = (val) => {
  //   const files = val.target;
  //   const file = files?.files[0];
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   dispatch(uploadImageAction.request(formData));
  // };

  useEffect(() => {
    if (imageRes && imageType === 1) {
      // console.log("img have");
      setMyImages((prevImages) => [...prevImages, imageRes.image]);
      setBannerImages((prevImages) => [...prevImages, imageRes.image]);
    }
  }, [imageRes]);

  const handleCrossClick = async (e, i) => {
    console.log("cross", i);
    e.stopPropagation();
    if (bannerImages.includes(i)) {
      console.log("eee", i);
      let arr = [];
      arr = bannerImages.filter((name) => name !== i);
      console.log("arr", arr);
      setBannerImages(arr);
    }
  };

  // const handleCrossClick = async (e, i) => {
  //   e.stopPropagation();
  //   if (bannerImages.includes(i)) {
  //     let arr = [];
  //     arr = bannerImages.filter((name) => name !== i);
  //     setBannerImages(arr);
  //   }
  // };

  const handleVenueGet = () => {
    const body = {
      page: 1,
      limit: 10,
      search: "",
    };
    dispatch(GetVenueAction.request(body, setTotalCount));
  };

  useEffect(() => {
    handleVenueGet();
  }, []);

  // const [age, setAge] = React.useState("Twenty");

  const handleChange = (event) => {
    setMusicType(event.target.value);
  };

  const editTicket = (id) => {
    setTicketId(id);
    setEditTicketBox(true);
    editEvent?.ticketDetails.map((item) => {
      if (id === item?._id) {
        // setTicketDetails([...ticketDetails]);
        formik.setFieldValue("ticketNote", item?.notes);
        formik.setFieldValue("ticketDetail", item?.details);
        formik.setFieldValue("ticketTimeSlot", item?.timeSlot);
        formik.setFieldValue("maleCount", item?.male.count);
        formik.setFieldValue("malePrice", item?.male.price);
        formik.setFieldValue("femalePrice", item?.female.price);
        formik.setFieldValue("femaleCount", item?.female.count);
        formik.setFieldValue("coupleCount", item?.couple.count);
        formik.setFieldValue("couplePrice", item?.couple.price);
      }
    });
  };

  const saveTicketDetail = () => {
    const body = {
      // isDelete: false,
      notes: formik.values.ticketNote,
      details: formik.values.ticketDetail,
      timeSlot: formik.values.ticketTimeSlot,
      male: {
        count: formik.values.maleCount,
        price: formik.values.malePrice,
      },
      female: {
        count: formik.values.femaleCount,
        price: formik.values.femalePrice,
      },
      couple: {
        count: formik.values.coupleCount,
        price: formik.values.couplePrice,
      },
    };
    dispatch(EditTicketAction.request(ticketId, body, navigate));
    const body1 = {
      page: 1,
      limit: 10,
      search: "",
    };
    dispatch(GetEventActionById.request(id, body1));
    emptyTicketDetail();
    setEditTicketBox(false);
  };

  // const handlerDeleteTicket = (ticket_id) => {
  //   let body = {
  //     isDelete: true,
  //   };
  //   dispatch(EditTicketAction.request(ticket_id, body, navigate));
  //   const body1 = {
  //     page: 1,
  //     limit: 10,
  //     search: "",
  //   };
  //   dispatch(GetEventActionById.request(id, body1));
  // };

  const uploadBannerImage = (val) => {
    setImageType(1);
    const files = val.target;
    const file = files?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadImageAction.request(formData));
  };

  return (
    <Layout>
      <div className="addData">
        {pathname === `/editevent/${id}` ? (
          <Title title="Edit Event" />
        ) : (
          <Title title="Add Event" />
        )}
        <div className="rightSide">
          <Button name="<" title="Back" onClick={() => navigate("/events")} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="formGroup">
            <div className="formControl">
              <label>Event Name</label>
              <InputField
                placeholder="Enter Event Name"
                name="eventName"
                value={formik.values.eventName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.eventName && formik.errors.eventName}
              />
            </div>
            <div className="formControl">
              <label>Description</label>
              <TextareaField
                placeholder="Enter Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.description && formik.errors.description ? (
                <p className="errorMsg">
                  {" "}
                  {formik.touched.description && formik.errors.description}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="formControl venueSelect">
              <label>Choose Venue </label>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Choose Venue
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectVenue}
                  onChange={(event) => setSelectValue(event.target.value)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {venueData?.length ? (
                    venueData?.map((item) => (
                      <MenuItem value={item?._id} key={item?._id}>
                        {item?.name} ({item?.venueCity?.value}), (
                        {item?.venueLocation?.subValue?.name})
                      </MenuItem>
                    ))
                  ) : (
                    <p>No data found</p>
                  )}
                </Select>
                {error && !selectVenue ? (
                  <p className="errorMsg">Required!</p>
                ) : (
                  ""
                )}
              </FormControl>
            </div>

            <div className="formControl venueSelect">
              <label>Event Type </label>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Choose Event Type
                </InputLabel>
                <Select
                  value={eventVenue}
                  label="music type"
                  onChange={(e) => setEventVenue(e.target.value)}
                >
                  {venuetype.map((item) => {
                    return <MenuItem value={item?._id}>{item?.value}</MenuItem>;
                  })}
                </Select>
                {error && !selectVenue ? (
                  <p className="errorMsg">Required!</p>
                ) : (
                  ""
                )}
              </FormControl>
              {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={eventVenue}
                label="music type"
                onChange={(e) => setEventVenue(e.target.value)}
              >
                {venuetype.map((item) => {
                  return <MenuItem value={item?._id}>{item?.value}</MenuItem>;
                })}
              </Select> */}
              {/* <SelectField
                label={"Choose Event Type"}
                names={venuetype}
                value={eventVenue}
                setValue={setEventVenue}
                // typeCount={4}
                // setType={setType}
              /> */}
              {/* {error && !eventVenue ? (
                <p className="errorMsg">Event type is required</p>
              ) : (
                ""
              )} */}
            </div>

            {/* <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={musicType}
              label="Age"
              onChange={(e) => setMusicType(e.target.value)}
            >
              {musictypeArr?.length
                ? musictypeArr?.map((item) => (
                    <MenuItem value={item?._id}>{item?.value || ""}</MenuItem>
                  ))
                : null}
            </Select> */}
            {/* {musictypeArr?.length ? ( */}
            <div className="formControl venueSelect">
              <label>Music Type </label>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Choose Music Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={musicType}
                  label="music type"
                  onChange={handleChange}
                >
                  {musictypeArr.map((item) => {
                    return <MenuItem value={item?._id}>{item?.value}</MenuItem>;
                  })}
                </Select>
                {error && !selectVenue ? (
                  <p className="errorMsg">Required!</p>
                ) : (
                  ""
                )}
              </FormControl>

              {/* {error && !musicType ? (
                <p className="errorMsg">Music type is required</p>
              ) : (
                ""
              )} */}
            </div>
            {/* ) : null} */}

            <div className="formControl dateTimeValue">
              <label>Start Date and Time </label>
              <TextField
                // inputProps={{
                //   min: new Date().toISOString().split("T")[0],
                // }}
                className="text_field"
                hiddenLabel
                type={"datetime-local"}
                name="startTime"
                variant="outlined"
                fullWidth
                id="startTime"
                value={formik.values.startTime}
                onChange={formik.handleChange}
                helperText={formik.touched.startTime && formik.errors.startTime}
              />
            </div>

            <div className="formControl dateTimeValue">
              <label>End Date and Time </label>
              <TextField
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
                className="text_field"
                hiddenLabel
                type={"datetime-local"}
                variant="outlined"
                fullWidth
                name="endTime"
                id="endTime"
                value={formik.values.endTime}
                onChange={formik.handleChange}
                helperText={formik.touched.endTime && formik.errors.endTime}
              />
              {error && formik.values.startTime > formik.values.endTime ? (
                <p className="errorMsg">
                  {" "}
                  Start date should be before than end date
                </p>
              ) : (
                ""
              )}
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["MobileDateTimePicker"]}>
                  <MobileDateTimePicker
                    disablePast
                    name="endDateTime"
                    value={endDateTime}
                    onChange={(date) => setEndDateTime(date?.$d)}
                    // onChange={(date) => setEndDateTime(date)}
                    // minDate={startDateTime}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {error && !endDateTime ? (
                <p className="errorMsg">End date & time is required</p>
              ) : (
                ""
              )}
              {error && endDateTime <= startDateTime ? (
                <p className="errorMsg">
                  You cannot select the end date and time less than or same to
                  the start date and time.
                </p>
              ) : null} */}
              {/* {error && moment(startDateTime).isSameOrAfter(endDateTime) ? (
                <p className="errorMsg">
                  You cannot select the start date and time to be greater than
                  or equal to the end date and time.
                </p>
              ) : (
                ""
              )} */}
            </div>
            {/* <div className="formControl">
              <label>Upload Banner</label>
              {myImages?.length ? (
                <ul className="imgMain">
                  {myImages?.map((item, i) => {
                    return (
                      <li key={i}>
                        <div className="uploaded">
                          <CloseIcon
                            onClick={(e) => handleCrossClick(e, item)}
                            className="close"
                          />
                          <figure>
                            <img src={item || ""} alt="" />
                          </figure>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : null}

              <label htmlFor="icon-button-files">
                <div className="uploadImg">
                  <input
                    id="icon-button-files"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileUpload}
                  />

                  <div className="bussinessimg">
                    <ul>
                      {myImages?.length < 3 ? (
                        <li>
                          <div className="upload">
                            <AddIcon />
                          </div>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                {error && !myImages?.length ? (
                  <p className="errorMsg">Required!</p>
                ) : (
                  ""
                )}
              </label>
            </div> */}

            <div className="formControl">
              <label>Upload Banners (max 6)</label>
              <ul className="imgMain">
                {bannerImages?.length
                  ? bannerImages?.map((item) => {
                      console.log(item);
                      return (
                        <li>
                          <div className="uploaded">
                            <CloseIcon
                              onClick={(e) => handleCrossClick(e, item)}
                              className="close"
                            />
                            <figure>
                              <img src={item} alt="" />
                            </figure>
                          </div>
                        </li>
                      );
                    })
                  : null}
              </ul>
              {bannerImages?.length < 6 ? (
                <label htmlFor="icon-button-file5">
                  <TextField
                    inputProps={{
                      accept: "image/*",
                    }}
                    id="icon-button-file5"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={uploadBannerImage}
                  />
                  <div className="upload">
                    <AddIcon />
                  </div>
                  {error && !bannerImages?.length ? (
                    <p className="errorMsg">Required!</p>
                  ) : (
                    ""
                  )}
                </label>
              ) : null}
            </div>

            <div className="formControl">
              <label>isFeatured</label>
              <SwitchBtn
                name="isFeatured"
                onChange={formik.handleChange}
                checked={formik.values.isFeatured}
              />
            </div>
            <div className="formControl">
              <label>Is Popular</label>
              <SwitchBtn
                name="isPopular"
                onChange={formik.handleChange}
                checked={formik.values.isPopular}
              />
            </div>
            <div className="formControl">
              <label>Is Priority High</label>
              <SwitchBtn
                name="isHighPriority"
                onChange={formik.handleChange}
                checked={formik.values.isHighPriority}
              />
            </div>
            <div className="formControl">
              <label>Is Table</label>
              <SwitchBtn
                name="isTable"
                onChange={formik.handleChange}
                checked={formik.values.isTable}
              />
            </div>

            {pathname === `/editevent/${id}` ? (
              <div className="formControl">
                <label>Is Tickets</label>
                {ticketDetails?.length ? (
                  <label> {ticketDetails?.length}</label>
                ) : (
                  <p>No</p>
                )}
              </div>
            ) : (
              <div className="formControl">
                <label>Is Tickets</label>
                <SwitchBtn
                  name="isTickets"
                  onChange={formik.handleChange}
                  checked={formik.values.isTickets}
                />
              </div>
            )}

            <div className="extraOption">
              <div className="tMain">
                {ticketDetails?.length
                  ? ticketDetails?.map((item, i) => (
                      <div className="ticketDetail" key={i}>
                        {id ? (
                          <div className="actions">
                            {/* edit */}
                            <EditIcon
                              onClick={() => editTicket(item?._id)}
                              className="green"
                            />
                            {/* delete */}
                            {/* {ticketDetails?.length > 1 ? (
                              <DeleteIcon
                                onClick={() => handlerDeleteTicket(item?._id)}
                                className="red"
                              />
                            ) : (
                              ""
                            )} */}
                          </div>
                        ) : (
                          <CloseIcon
                            onClick={() => deleteTicketDetail(i)}
                            className="closeIc"
                          />
                        )}
                        <div className="tInner">
                          <p>
                            <strong>Note :</strong> {item?.notes || ""}
                          </p>
                          <p>
                            <strong>Details :</strong> {item?.details || ""}
                          </p>
                          <p>
                            <strong>Male Count/Price : </strong>
                            {item?.male?.count || ""} / Rs.
                            {item?.male?.price || "0"}
                          </p>
                          <p>
                            <strong>Female Count/Price :</strong>{" "}
                            {item?.female?.count || ""} / Rs.
                            {item?.female?.price || "0"}
                          </p>
                          <p>
                            <strong>Couple Count/Price : </strong>
                            {item?.couple?.count || ""} / Rs.
                            {item?.couple?.price || "0"}
                          </p>
                          <p>
                            <strong> Time Slot : </strong>
                            {item?.timeSlot}
                            {/* {moment(item?.timeSlot).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )} */}
                          </p>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              {(formik.values.isTickets && !id) ||
              (id && editTicketBox === true) ? (
                <div className="guestRow">
                  <h3>Is Tickets</h3>
                  <div className="fGrp">
                    <div className="formControl">
                      <label>Notes</label>
                      <InputField
                        placeholder="Enter Notes"
                        name="ticketNote"
                        value={formik.values.ticketNote}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.ticketNote && formik.errors.ticketNote
                        }
                      />
                    </div>
                    <div className="formControl">
                      <label>Details</label>
                      <InputField
                        placeholder="Enter Details"
                        name="ticketDetail"
                        value={formik.values.ticketDetail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.ticketDetail &&
                          formik.errors.ticketDetail
                        }
                      />
                    </div>

                    <div className="formControl">
                      <label>Time Slot</label>
                      <InputField
                        placeholder="Enter Time Slot"
                        name="ticketTimeSlot"
                        value={formik.values.ticketTimeSlot}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.ticketTimeSlot &&
                          formik.errors.ticketTimeSlot
                        }
                      />
                      {/* {startDateTime && endDateTime ? (
                        <p
                          style={{
                            color: "#777",
                            fontSize: "12px",
                            paddingTop: "7px",
                          }}
                        >{`Time slot must be selected between ${
                          startDateTime
                            ? moment(startDateTime).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )
                            : ""
                        } - ${
                          endDateTime
                            ? moment(endDateTime).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )
                            : ""
                        }`}</p>
                      ) : null} */}
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["MobileDateTimePicker"]}>
                          <MobileDateTimePicker
                            disablePast
                            name="ticketTimeSlot"
                            value={ticketTimeSlot}
                            onChange={(date) => setTicketTimeSlot(date?.$d)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {error && !ticketTimeSlot ? (
                        <p className="errorMsg">This field is required</p>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <div className="isTicket">
                      <div className="formControl">
                        <label>Max Male Count</label>
                        <InputField
                          placeholder="Enter Male Count"
                          name="maleCount"
                          value={formik.values.maleCount}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.maleCount && formik.errors.maleCount
                          }
                        />
                      </div>
                      <div className="formControl">
                        <label>Max Female Count</label>
                        <InputField
                          placeholder="Enter Female Count"
                          name="femaleCount"
                          value={formik.values.femaleCount}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.femaleCount &&
                            formik.errors.femaleCount
                          }
                        />
                      </div>
                      <div className="formControl">
                        <label>Max Couple Count</label>
                        <InputField
                          placeholder="Enter Couple Count"
                          name="coupleCount"
                          value={formik.values.coupleCount}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.coupleCount &&
                            formik.errors.coupleCount
                          }
                        />
                      </div>
                    </div>
                    <div className="formControl"></div>
                    <div className="isTicket">
                      <div className="formControl">
                        <label>Male Price</label>
                        <InputField
                          placeholder="Enter Male Price"
                          name="malePrice"
                          value={formik.values.malePrice}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.malePrice && formik.errors.malePrice
                          }
                        />
                      </div>
                      <div className="formControl">
                        <label>Female Price</label>
                        <InputField
                          placeholder="Enter Female Price"
                          name="femalePrice"
                          value={formik.values.femalePrice}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.femalePrice &&
                            formik.errors.femalePrice
                          }
                        />
                      </div>
                      <div className="formControl">
                        <label>Couple Price</label>
                        <InputField
                          placeholder="Enter Couple Price"
                          name="couplePrice"
                          value={formik.values.couplePrice}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.couplePrice &&
                            formik.errors.couplePrice
                          }
                        />
                      </div>
                    </div>
                    <div className="formControl">
                      {/* <label>Enable Ratio</label>
                      <SwitchBtn /> */}
                    </div>
                    <div className="addSlot">
                      {id ? (
                        <h3
                          onClick={saveTicketDetail}
                          className="addtime"
                          style={{
                            padding: " 7px 10px",
                            borderRadius: "4px",
                            fontSize: "13px",
                            background: "#4cffa8",
                          }}
                        >
                          Update Ticket
                        </h3>
                      ) : (
                        <h3
                          onClick={addTicketDetail}
                          className="addtime"
                          style={{
                            padding: " 7px 10px",
                            borderRadius: "4px",
                            fontSize: "13px",
                            background: "#4cffa8",
                          }}
                        >
                          Add Ticket
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {formik.values.isTable ? (
                <div className="guestRow">
                  <h3>Is Table</h3>
                  <div className="fGrp">
                    <div className="formControl">
                      <label>Total No. of people</label>
                      <InputField
                        placeholder="Enter Total No. of people"
                        name="noPeople"
                        value={formik.values.noPeople}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.onBlur}
                        helperText={
                          formik.touched.noPeople && formik.errors.noPeople
                        }
                      />
                    </div>
                    {/* <div className="formControl datePicker">
                    <label className="mb0" style={{marginBottom: 0}}>Arrive Date and Time </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDateTimePicker"]}>
                        <MobileDateTimePicker name="arriveDateTime" value={arriveDateTime} onChange={(e) => setArriveDateTime(e?.$d)} />
                      </DemoContainer>
                    </LocalizationProvider>
                   {error && !arriveDateTime? <p className="errorMsg">This is required field</p> : ""}

                  </div> */}
                    <div className="formControl">
                      <label>Notes</label>
                      <TextareaField
                        placeholder="Enter Notes"
                        name="tableNotes"
                        value={formik.values.tableNotes}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.tableNotes && formik.errors.tableNotes ? (
                        <p className="errorMsg">
                          {" "}
                          {formik.touched.tableNotes &&
                            formik.errors.tableNotes}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="formControl">
                      <label>Details</label>
                      <TextareaField
                        placeholder="Enter Details"
                        name="tableDetail"
                        value={formik.values.tableDetail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.tableDetail &&
                      formik.errors.tableDetail ? (
                        <p className="errorMsg">
                          {" "}
                          {formik.touched.tableDetail &&
                            formik.errors.tableDetail}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="btnRow">
            <Button
              name="Save"
              onClick={() => setError(true)}
              disabled={
                formik.values.isTickets && !ticketDetails?.length ? true : false
              }
            />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddEvent;
