import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import Title from "../../component/Title";
import Table from "../../component/Table";
import Button from "../../component/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../redux/saga/NotificationSaga";
import { GetNotificationAction } from "../../redux/actions";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../../component/SearchBar";
import { Pagination } from "../../component";
import { isValidInput } from "../../utils/validations";

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const headings = ["#", "Title", "Notification"];
  const { getNotifData } = useSelector((state) => state.NotificationReducer);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const venueData = useSelector((state) => state.VenueReducer.venueData);
  const { venueDelete } = useSelector((state) => state.VenueReducer);
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handleNotificationGet = () => {
    const body = {
      page: page,
      limit: 10,
      search: debouncedSearchTerm.trim(),
    };
    dispatch(GetNotificationAction.request(body, setTotalCount));
  };

  useEffect(() => {
    handleNotificationGet();
  }, [debouncedSearchTerm, page]);

  return (
    <Layout>
      <div className="serachMain">
        <Title title="Notifications" />
        <SearchBar
          value={searchTerm}
          searchTerm={searchTerm}
          onCross={() => setSearchTerm("")}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          onChange={(val) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
      </div>
      <div className="rightSide">
        <Button name="+" onClick={() => navigate("/addnotification")} />
      </div>
      <div className="table-responsive">
        <table>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Message</th>
          </tr>
          {getNotifData && getNotifData.length > 0 ? (
            getNotifData.map((item, i) => {
              return (
                <tr key={item?.id}>
                  <td> {(page - 1) * 10 + i + 1}</td>
                  <td>{item?.title || ""}</td>
                  <td className="bigNote">
                    <Tooltip title={item?.message || ""}>
                      <p>{item?.message || ""}</p>
                    </Tooltip>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="no_data">
              <td colSpan="3">
                <div className="noData">
                  <figure>
                    <img src="./images/noData.jpg" alt="No Data" />
                  </figure>
                  <p>No Notifications Yet</p>
                </div>
              </td>
            </tr>
          )}
        </table>
      </div>
      <Pagination
        module={getNotifData}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </Layout>
  );
};

export default Notification;
