import { takeEvery, put, call, all } from "redux-saga/effects";
import * as actions from "../../actions";
import * as apis from "../../services";
import * as url from "../../../constants/Urls";
import { EventConstant } from "../../constant/EventConstant";
import { errorToast, successToast } from "../../../utils";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* getEvent(payload) {
  const { body, setTotalCount } = payload;
  try {
    yield put(actions.GetEventAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url.getEvent);
    if (res?.statusCode === 200) {
      yield put(actions.GetEventAction.success(res?.data?.Event));
      setTotalCount(res?.data?.totalEvent);
    } else {
      yield put(actions.GetEventAction.error(res?.message));
    }
  } catch (error) {
    yield put(actions.GetEventAction.error(error));
  }
}

export function* getEventById(payload) {
  const { id, body } = payload;
  try {
    yield put(actions.GetEventActionById.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url.getEvent + "/" + id);
    if (res?.statusCode === 200) {
      yield put(actions.GetEventActionById.success(res?.data));
    } else {
      yield put(actions.GetEventActionById.error(res?.message));
    }
  } catch (error) {
    yield put(actions.GetEventActionById.error(error));
  }
}

export function* AddEvent(payload) {
  const { body, navigate } = payload;
  try {
    yield put(actions.AddEventAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.postApi, body, url?.addEvent);
    if (res?.statusCode === 200) {
      yield put(actions.AddEventAction.success());
      successToast(res?.message || "");
      navigate("/events");
    } else {
      yield put(actions.AddEventAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.AddEventAction.error(error));
  }
}

export function* EditEvent(payload) {
  const { id, body, navigate } = payload;
  try {
    yield put(actions.EditEventAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.putApi, body, url.editEvent + id + "/");
    if (res?.statusCode === 200) {
      yield put(actions.EditEventAction.success(res?.data));
      if (body) {
        successToast(res?.message);
        navigate("/events");
      }
    } else {
      yield put(actions.EditEventAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.EditEventAction.error(error));
  }
}

export function* deleteEvent(payload) {
  const { id } = payload;
  try {
    yield put(actions.DeleteEventAction.loading(true));
    yield delay(1000);
    const deleteUrl = `${url.deleteEvent}/${id}`;
    const res = yield call(apis.delete_form, deleteUrl);
    if (res?.statusCode === 200) {
      yield put(actions.DeleteEventAction.success(res));
    } else {
      errorToast(res?.message || "");
    }
  } catch (error) {
    yield put(actions.DeleteEventAction.error(error));
  }
}

export function* EditTicket(payload) {
  const { id, body, navigate } = payload;
  try {
    yield put(actions.EditTicketAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.putApi, body, url.editTickets + id + "/");
    if (res?.statusCode === 200) {
      yield put(actions.EditTicketAction.success(res?.data));
      if (body) {
        successToast(res?.message);
      }
    } else {
      yield put(actions.EditTicketAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.EditTicketAction.error(error));
  }
}

export function* deleteTicket(payload) {
  const { id, body } = payload;
  try {
    yield put(actions.DeleteTicketAction.loading(true));
    yield delay(1000);
    const res = yield call(apis.putApi, body, url.editTickets + id + "/");
    if (res?.statusCode === 200) {
      yield put(actions.DeleteTicketAction.success(res?.data));
      if (body) {
        successToast(res?.message);
      }
    } else {
      yield put(actions.DeleteTicketAction.error(res?.message));
      errorToast(res?.message);
    }
  } catch (error) {
    yield put(actions.DeleteTicketAction.error(error));
  }
}

function* EventSaga() {
  yield all([takeEvery(EventConstant.GET_EVENT_REQUEST, getEvent)]);
  yield all([takeEvery(EventConstant.GET_EVENT_REQUEST_BY_ID, getEventById)]);
  yield all([takeEvery(EventConstant.ADD_EVENT_REQUEST, AddEvent)]);
  yield all([takeEvery(EventConstant.EDIT_EVENT_REQUEST, EditEvent)]);
  yield all([takeEvery(EventConstant.DELETE_EVENT_REQUEST, deleteEvent)]);
  yield all([takeEvery(EventConstant.EDIT_TICKET_REQUEST, EditTicket)]);
  yield all([takeEvery(EventConstant.DELETE_TICKET_REQUEST, deleteTicket)]);
}

export default EventSaga;
