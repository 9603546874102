import React from 'react'
import Layout from '../layout';
import Title from '../component/Title';


const Transaction = () => {
  return (
    <Layout>
    <Title title="Transactions" />
    </Layout>
  )
}

export default Transaction
