import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Checkbox, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { LoginAction } from "../redux/actions";
import { deleteCookie, getCookie, setCookie } from "cookies-next";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  const rememberData = getCookie("REMEMBER");
  // to handle eye icon in password field
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  // ------------end ----------//

  //formik form
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string().required("Password is required!"),
      // .matches(
      //   /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      //   "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
      // ),
    }),
    onSubmit: (values) => {
      const body = {
        email: formik.values.email,
        password: formik.values.password,
      };
      if (formik.values.remember) {
        setCookie("REMEMBER", JSON.stringify(body));
      } else {
        deleteCookie("REMEMBER");
      }
      dispatch(LoginAction.request(body, navigate));
    },
  });

  useEffect(() => {
    if (rememberData) {
      const remember = JSON.parse(`${rememberData}`);
      formik.setFieldValue("password", remember?.password);
      formik.setFieldValue("email", remember?.email);
      formik.setFieldValue("remember", true);
    }
  }, [rememberData]);

  return (
    <div className="loginScreen">
      <div className="login">
        <div className="lLeft">
          <div className="logo">
            <figure>
              <img src="../images/logo.png" alt="logo" />
            </figure>
          </div>
          <div className="title">
            <h2>
              Sign in <span>lorem ipsum is a dummy</span>
            </h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="authForm">
              <div className="formControl">
                <TextField
                  className="text_field"
                  placeholder="Enter Email Address"
                  sx={{ p: "0px", width: "100%" }}
                  // variant="standard"
                  type="text"
                  margin="none"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="formControl">
                <TextField
                  className="text_field"
                  placeholder="Enter Password"
                  sx={{ p: "0px", width: "100%" }}
                  margin="none"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="checkbox">
                <Checkbox
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                />
                <h4>Remember Me</h4>
              </div>
              <div className="saveBtn">
                <button type="submit">Next</button>
              </div>
              <div className="forgot">
                <h5 onClick={() => navigate("/forgot")}>Forgot Password?</h5>
              </div>
            </div>
          </form>
        </div>
        <div className="right"></div>
      </div>
    </div>
  );
};

export default Login;
