import React from "react";
import Sidebar from "./Sidebar";
import "./layout.scss";
import Header from "./Header";

const Layout = (props) => {
  return (
    <div className="layout">
      <div className="lLeft">
        <Header />
      </div>
      <div className="lRight">
        <Sidebar />
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;
