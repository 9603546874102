import React from "react";
import Layout from "../layout";
import { Title, Button, InputField, TextareaField } from "../component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AddFaqAction, GetCmsAction, UpdateFaqAction } from "../redux/actions";
import { useEffect } from "react";
import { useState } from "react";

const AddFaq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();

  const { getCms } = useSelector((state) => state.CmsReducer);
  console.log("getCm....s", getCms?.faq);

  const [editData, setEditData] = useState();
  console.log(editData, "editData");
  const pathname = location.pathname;

  useEffect(() => {
    getCms?.faq.map((x) => {
      if (x?._id === id) {
        setEditData(x);
      }
    });
  }, [getCms]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      question: pathname === `/edit-faq/${id}` ? editData?.question : "",
      answer: pathname === `/edit-faq/${id}` ? editData?.answer : "",
    },
    validationSchema: Yup.object({
      question: Yup.string().required("Required"),
      answer: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      const body = {
        question: formik.values.question,
        answer: formik.values.answer,
      };
      if (pathname === `/edit-faq/${id}`) {
        dispatch(UpdateFaqAction.request(id, body, navigate));
      } else {
        dispatch(AddFaqAction.request(body, navigate));
      }
    },
  });

  useEffect(() => {
    dispatch(GetCmsAction.request());
    setEditData();
  }, []);

  return (
    <Layout>
      <div className="rightSide">
        <Button name=">" onClick={() => navigate("/cms")} />
      </div>
      <div className="table-responsive">
        <form onSubmit={formik.handleSubmit}>
          <div className="addData">
            {pathname === `/edit-faq/${id}` ? (
              <Title title="Edit FAQ's" />
            ) : (
              <Title title="Add FAQ's" />
            )}

            <div className="formGroup">
              <div className="formControl">
                <label>Question</label>
                <InputField
                  placeholder="Enter Question"
                  name="question"
                  value={formik.values.question}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.question && formik.errors.question}
                />
              </div>
              <div className="formControl">
                <label>Answer</label>
                <TextareaField
                  placeholder="Enter Answer"
                  name="answer"
                  value={formik.values.answer}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.answer && formik.errors.answer ? (
                  <p className="errorMsg">
                    {formik.touched.answer && formik.errors.answer}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="btnRow">
              {pathname === `/edit-faq/${id}` ? (
                <Button name="Update" />
              ) : (
                <Button name="Save" />
              )}
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddFaq;
