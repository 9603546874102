import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Title, Button, SwitchBtn, Pagination } from "../../component";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// import StopCircleIcon from "@mui/icons-material/StopCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteEventAction,
  EditEventAction,
  GetEventAction,
  modalVisible,
} from "../../redux/actions";
import LockSharpIcon from "@mui/icons-material/LockSharp";
import LockOpenSharpIcon from "@mui/icons-material/LockOpenSharp";
import moment from "moment";
import { Modules } from "../../constants";
import SearchBar from "../../component/SearchBar";
import { isValidInput } from "../../utils/validations";

const EventManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { eventData } = useSelector((state) => state.EventReducer);
  const { eventDelete } = useSelector((state) => state.EventReducer);
  let totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  // table headings
  const headings = [
    "#",
    "Event Name",
    "Venue Name",
    "Description",
    "Start Date/Time",
    "End Date/Time",
    "Uploaded Banners",
    "Music Type",
    "Is Featured",
    "Is Popular",
    "Is Priority High",
    "Is Ticket",
    "Is Table",
    "Bookings",
    // "Pause Bookings",
    "Blocked/Unblocked",
    "Action",
  ];

  // block fun
  const handlerBlock = (id, block) => {
    let body = {
      isBlocked: !block,
    };
    dispatch(EditEventAction.request(id, body));
    handleEventGet();
  };

  // feature fun
  const handlerFeature = (id, feature) => {
    let body = {
      isFeatured: !feature,
    };
    dispatch(EditEventAction.request(id, body));
    handleEventGet();
  };
  // priority fun
  const handlerPriority = (id, isHighPriority) => {
    let body = {
      isHighPriority: !isHighPriority,
    };
    dispatch(EditEventAction.request(id, body));
    handleEventGet();
  };
  // popular fun
  const handlerPopular = (id, isPopular) => {
    let body = {
      isPopular: !isPopular,
    };
    dispatch(EditEventAction.request(id, body));
    handleEventGet();
  };
  // tickets fun
  // const handlerTickets = (id, isTickets) => {
  //   let body = {
  //     isTickets: !isTickets,
  //   };
  //   dispatch(EditEventAction.request(id, body));
  //   handleEventGet();
  // };
  // table fun
  // const handlerTable = (id, isTable) => {
  //   let body = {
  //     isTable: !isTable,
  //   };
  //   dispatch(EditEventAction.request(id, body));
  //   handleEventGet();
  // };

  const handleEventGet = () => {
    const body = {
      page: page,
      limit: 10,
      search: debouncedSearchTerm.trim(),
    };
    dispatch(GetEventAction.request(body, setTotalCount));
  };

  // for data get
  useEffect(() => {
    handleEventGet();
  }, [debouncedSearchTerm, page]);

  // for delete
  useEffect(() => {
    if (eventDelete?.statusCode === 200 || eventData?.statusCode === 200) {
      handleEventGet();
    }
  }, [eventDelete]);

  return (
    <Layout>
      <div className="serachMain">
        <Title title="Event Management" />
        <SearchBar
          value={searchTerm}
          searchTerm={searchTerm}
          onCross={() => setSearchTerm("")}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          onChange={(val) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
      </div>
      <div className="rightSide">
        <Button
          name="+"
          title="Add Event"
          onClick={() => navigate("/addevent")}
        />
      </div>
      <div className="table-responsive centerCls">
        <table>
          <thead>
            <tr>
              {headings.map((headings) => (
                <th key={headings}>{headings}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {eventData && eventData?.length > 0 ? (
              eventData.map((item, i) => {
                return (
                  <tr key={item?._id}>
                    <td> {(page - 1) * 10 + i + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.venueId?.name}</td>
                    <td className="bigNote">
                      <Tooltip title={item?.description}>
                        <p>{item?.description || ""}</p>
                      </Tooltip>
                    </td>
                    <td>
                      {moment(item?.startTime).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td>
                      {moment(item?.endTime).format("MMMM Do YYYY, h:mm:ss a")}
                    </td>
                    <td className="center">
                      <PhotoSizeSelectActualIcon
                        onClick={() =>
                          dispatch(
                            modalVisible.modalOpen(Modules.EVENT_BANNER, {
                              id: item?._id,
                            })
                          )
                        }
                      />
                    </td>
                    <td>{item?.musicType?.value}</td>
                    <td>
                      <SwitchBtn
                        checked={item?.isFeatured}
                        onChange={() =>
                          handlerFeature(item?._id, item?.isFeatured)
                        }
                      />
                    </td>
                    <td>
                      <SwitchBtn
                        checked={item?.isPopular}
                        onChange={() =>
                          handlerPopular(item?._id, item?.isPopular)
                        }
                      />
                    </td>
                    <td>
                      <SwitchBtn
                        checked={item?.isHighPriority}
                        onChange={() =>
                          handlerPriority(item?._id, item?.isHighPriority)
                        }
                      />
                    </td>
                    <td>
                      {item?.isTickets == true ? "Yes" : "No"}
                      {/* <SwitchBtn
                        checked={item?.isTickets}
                        onChange={() =>
                          handlerTickets(item?._id, item?.isTickets)
                        }
                      /> */}
                    </td>
                    <td>
                      {item?.isTable == true ? "Yes" : "No"}
                      {/* <SwitchBtn
                        checked={item?.isTable}
                        onChange={() => handlerTable(item?._id, item?.isTable)}
                      /> */}
                    </td>
                    <td className="center pauseBooking">
                      <VisibilityIcon
                        onClick={() => navigate("/eventbooking")}
                      />
                    </td>
                    {/* <td className="center pauseBooking">
                      <StopCircleIcon />
                    </td> */}
                    <td className="center pauseBooking">
                      {item?.isBlocked ? (
                        <Tooltip title="Blocked">
                          <IconButton>
                            <LockSharpIcon
                              onClick={() =>
                                handlerBlock(item?._id, item?.isBlocked)
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Unblocked">
                          <IconButton>
                            <LockOpenSharpIcon
                              onClick={() =>
                                handlerBlock(item?._id, item?.isBlocked)
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </td>
                    <td>
                      <Tooltip title="Edit">
                        <IconButton>
                          <EditIcon
                            onClick={() => navigate(`/editevent/${item?._id}`)}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton>
                          <DeleteIcon
                            style={{ fill: "red" }}
                            onClick={() =>
                              dispatch(DeleteEventAction.request(item?._id))
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="no_data">
                <td colSpan="16">
                  <div className="noData">
                    <figure>
                      <img src="./images/noData.jpg" alt="No Data" />
                    </figure>
                    <p>No Events Yet</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        module={eventData}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </Layout>
  );
};

export default EventManagement;
