import { toast } from "react-toastify";

export function successToast(message) {
  toast.dismiss();
  if (message?.length) {
    toast.success(message);
  } else {
    toast.error("Something went wrong");
  }
}
export function errorToast(message) {
  toast.dismiss();
  if (message?.length) {
    toast.error(message);
  } else {
    toast.error("Something went wrong");
  }
}
export function warnToast(message) {
  toast.dismiss();
  if (message?.length) {
    toast.warn(message);
  } else {
    toast.error("Something went wrong");
  }
}
