import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSelector } from "react-redux";
const MapModal = () => {
  const venueData = useSelector((state) => state.VenueReducer.venueData);
  const { modalPayload } = useSelector((state) => state.CommonReducer);

  console.log("venueData modal", venueData);
  console.log("venueData modalPayload", modalPayload);

  useEffect(() => {}, []);

  return (
    <>
      {/* {venueData?.length
        ? venueData?.map((item) => {
            return (
              <div style={{ height: "300px", width: "100%" }}>
                {item?._id === modalPayload?.id ? item?.name : " no"}
              </div>
            );
          })
        : null} */}

      {venueData?.length
        ? venueData?.map((item) => {
            return (
              <div key={item?.id}>
                {item?._id === modalPayload?.id ? (
                  <div style={{ height: "300px", width: "100%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyB6emRUjW-F6fFkBSVh_srIOwy2KRk3xJA",
                      }} // Replace with your Google Maps API key
                      defaultCenter={{
                        lat: item?.venueLatitude,
                        lng: item?.venueLongitude,
                      }}
                      defaultZoom={15}
                    >
                      <CustomMarker
                        lat={item?.venueLatitude}
                        lng={item?.venueLongitude}
                      />
                    </GoogleMapReact>
                  </div>
                ) : null}
              </div>
            );
          })
        : null}
    </>
  );
};

export default MapModal;

const CustomMarker = ({ text }) => (
  <div>
    <LocationOnIcon style={{ color: "red", fontSize: "40px" }} />
  </div>
);
