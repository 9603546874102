import { combineReducers } from "redux";
import { CommonReducer } from "./CommonReducer";
import { AuthReducer } from "./AuthReducer";
import { NotificationReducer } from "./NotificationReducer";
import { EventReducer } from "./EventReducer";
import { CmsReducer } from "./CmsReducer";
import { VenueReducer } from "./VenueReducer";
import { EntitiesReducer } from "./EntitiesReducer";
import { BannerReducer } from "./BannerReducer";
import { BookingReducer } from "./BookingReducer";
import { FaqReducer } from "./FaqReducer";

const reducer = combineReducers({
  CommonReducer,
  AuthReducer,
  NotificationReducer,
  EventReducer,
  CmsReducer,
  VenueReducer,
  EntitiesReducer,
  BannerReducer,
  BookingReducer,
  FaqReducer,
});

export default reducer;
