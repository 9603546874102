import React from "react";
import Layout from "../../layout";
import Title from "../../component/Title";
import InputField from "../../component/InputField";
import Button from "../../component/Button";


const AddComment = () => {
  return (
    <Layout>
    <div className="table-responsive">
    <table >
    <tr>
        <th>#</th>
        <th>Booking ID</th>
        <th>Booking Date/Time</th>
        <th>Event ID</th>
        <th>Event Name</th>
        <th>Event Date</th>
        <th>User Details</th>
        <th>Status</th>
    </tr>
    <tr>
    <td>1</td>
    <td>23</td>
    <td>23-09-2023/09:00PM	</td>
    <td>1234</td>
    <td>dummy</td>
    <td>23-02-2023	</td>
    <td>Jerry (9876543210)	</td>
    <td>Pending</td>
    </tr>
    </table>
    <table style={{margin: '20px 0'}}>
    <tr>
    <th>#</th>
    <th>Name/Email</th>
    <th>Date/Time</th>
    <th>Comment</th>
    </tr>
    <tr>
    <td>1</td>
    <td>jerry (jerry@yopmail.com)	</td>
    <td>08-02-2023/09:00PM	</td>
    <td>Data</td>
    </tr>
    </table>
      <Title title="Add Comment" />
      <div className="addData">
        <div className="formGroup">
          <div className="formControl">
            <label>Comment</label>
            <InputField placeholder="Add Comment" />
          </div>
        </div>
        <Button name="Save" />
      </div>
      </div>
    </Layout>
  );
};

export default AddComment;
