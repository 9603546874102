import { Box, Modal, Tab, Tabs, TextField, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "./Button";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate , withRouter} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  AddEntitiesAction,
  DeleteEntitiesAction,
  EditEntitiesAction,
  GetEntitiesAction,
  modalVisible,
} from "../redux/actions";
import CloseIcon from "@mui/icons-material/Close";
import { Modules } from "../constants";
import { borderRadius } from "@mui/system";

const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  outline:'none',
  borderRadius: 1
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const CommonTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {state} = location;
  
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('')
  const [value, setValue] = useState(state === 'city' ? 2: 0);
  const [venue, setVenue] = useState(false);
  const [venueVal, setVenueVal] = useState("");
  const [tempArr, setTempArr] = useState([]);
  const [music, SetMusic] = useState(false);
  const [musicVal, setMusicVal] = useState("");
  const [musicArr, setMusicArr] = useState([]);
  const [area, SetArea] = useState(false);
  const [areaVal, setAreaVal] = useState("");
  const [areaArr, setAreaArr] = useState([]);
  const [venueType, SetVenueType] = useState(false);
  const [venueTypeVal, setVenuTypeeVal] = useState("");
  const [venueTypeArr, setVenueTypeArr] = useState([]);
  const [event, SetEvent] = useState(false);
  const [eventVal, setEventVal] = useState("");
  const [eventArr, setEventArr] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setID] = useState("");
  const [error, setError] = useState(true);

  //disable btn
  const [disabled, setDisabled] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    navigate('/setting', { state: null });
  }, [state]);

  const handlePush = () => {
    switch (value) {
      case 0:
        setTempArr([...tempArr, venueVal]);
        setVenueVal("");
        break;
      case 1:
        setMusicArr([...musicArr, musicVal]);
        setMusicVal("");
        break;
      case 2:
        setAreaArr([...areaArr, areaVal]);
        setAreaVal("");
        break;
      case 3:
        setVenueTypeArr([...venueTypeArr, venueTypeVal]);
        setVenuTypeeVal("");
        break;
      case 4:
        setEventArr([...eventArr, eventVal]);
        setEventVal("");
        break;
      default:
        return value;
    }
  };

  // const deleteItem = (index) => {
  //   const updatedArr = tempArr.filter((_, i) => i !== index);
  //   const updatedArr2 = musicArr.filter((_, i) => i !== index);
  //   const updatedArr3 = areaArr.filter((_, i) => i !== index);
  //   const updatedArr4 = venueTypeArr.filter((_, i) => i !== index);
  //   const updatedArr5 = eventArr.filter((_, i) => i !== index);
  //   setTempArr(updatedArr);
  //   setMusicArr(updatedArr2);
  //   setAreaArr(updatedArr3);
  //   setVenueTypeArr(updatedArr4);
  //   setEventArr(updatedArr5);
  // };

  const dispatch = useDispatch();
  const { entitiesData } = useSelector((state) => state.EntitiesReducer);

  const editItem = (_id) => {
    setIsEdit(true);
    setVenue(true);
    entitiesData.forEach((item) => {
      if (item?._id === _id) {
        setVenueVal(item?.value);
      }
    });
  };

  const deleteItem = (_id) => {
    dispatch(DeleteEntitiesAction.request(_id));
    setTimeout(() => {
      dispatch(GetEntitiesAction.request({ type: value }));
    }, 1000);
    setOpen(false)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEdit && value === 0) {
      const body = {
        value: venueVal,
        type: 0,
      };
      dispatch(AddEntitiesAction.request(body));
      setVenueVal("");
      setTimeout(() => {
        dispatch(GetEntitiesAction.request({ type: 0 }));
      }, 1000);
    } else if (!isEdit && value === 1) {
      const body = {
        value: venueVal,
        type: 1,
      };
      dispatch(AddEntitiesAction.request(body));
      setVenueVal("");
      setTimeout(() => {
        dispatch(GetEntitiesAction.request({ type: 1 }));
      }, 1000);
    } else if (!isEdit && value === 3) {
      const body = {
        value: venueVal,
        type: 3,
      };
      dispatch(AddEntitiesAction.request(body));
      setVenueVal("");
      setTimeout(() => {
        dispatch(GetEntitiesAction.request({ type: 3 }));
      }, 1000);
    } else if (!isEdit && value === 4) {
      const body = {
        value: venueVal,
        type: 4,
      };
      dispatch(AddEntitiesAction.request(body));
      setVenueVal("");
      setTimeout(() => {
        dispatch(GetEntitiesAction.request({ type: 4 }));
      }, 1000);
    } else if (isEdit && value === 0) {
      const body = {
        value: venueVal,
        type: 0,
      };
      dispatch(EditEntitiesAction.request(id, body));
      setVenueVal("");
      setVenue(false);
      setIsEdit(false);
      setTimeout(() => {
        dispatch(GetEntitiesAction.request({ type: 0 }));
      }, 1000);
    } else if (isEdit && value === 1) {
      const body = {
        value: venueVal,
        type: 1,
      };
      dispatch(EditEntitiesAction.request(id, body));
      setVenueVal("");
      setVenue(false);
      setIsEdit(false);
      setTimeout(() => {
        dispatch(GetEntitiesAction.request({ type: 1 }));
      }, 1000);
    } else if (isEdit && value === 3) {
      const body = {
        value: venueVal,
        type: 3,
      };
      dispatch(EditEntitiesAction.request(id, body));
      setVenueVal("");
      setVenue(false);
      setIsEdit(false);
      setTimeout(() => {
        dispatch(GetEntitiesAction.request({ type: 3 }));
      }, 1000);
    } else if (isEdit && value === 4) {
      const body = {
        value: venueVal,
        type: 4,
      };
      dispatch(EditEntitiesAction.request(id, body));
      setVenueVal("");
      setVenue(false);
      setIsEdit(false);
      setTimeout(() => {
        dispatch(GetEntitiesAction.request({ type: 4 }));
      }, 1000);
    }
  };
  useEffect(() => {
    switch (value) {
      case 0:
        dispatch(GetEntitiesAction.request({ type: 0 }));
        break;
      case 1:
        dispatch(GetEntitiesAction.request({ type: 1 }));
        break;
      case 2:
        dispatch(GetEntitiesAction.request({ type: 2 }));
        break;
      case 3:
        dispatch(GetEntitiesAction.request({ type: 3 }));
        break;
      case 4:
        dispatch(GetEntitiesAction.request({ type: 4 }));
        break;
      default:
        return value;
    }
  }, [value]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: 224,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab label="Venue Features" {...a11yProps(0)} />
        <Tab label="Music Type" {...a11yProps(1)} />
        <Tab label="City and their Location (Area)" {...a11yProps(2)} />
        <Tab label="Venue Type" {...a11yProps(3)} />
        <Tab label="Event Type" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0} className="mainTab">
        <div className="bubbles">
          <div className="bLeft">
            <ul>
              {entitiesData &&
                entitiesData.map((item, i) => {
                  return (
                    <li key={item._id}>
                      <p>{item.value}</p>
                      <span className="deleteItem">
                        <EditIcon
                          onClick={() => {
                            editItem(item?._id);
                            setID(item?._id);
                          }}
                        />
                        <CancelIcon
                          onClick={() =>{setOpen(true);setDeleteId(item?._id)}}
                        />
                        {/* <CancelIcon onClick={() => deleteItem(item?._id)} /> */}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="bRight">
            {venue ? (
              <form onSubmit={handleSubmit}>
                <div className="closeModal">
                  <CloseIcon onClick={() => {setVenue(false); setVenueVal(''); setIsEdit(false)}} />
                </div>
                <div className="formControl">
                  <TextField
                    placeholder="Enter Venue Features"
                    sx={{ p: "0px", width: "100%" }}
                    variant="standard"
                    type="text"
                    margin="none"
                    value={venueVal}
                    onChange={(e) => setVenueVal(e.target.value)}
                  />
                  {isEdit ? (
                    <button disabled={!venueVal} type="submit">
                      Update
                    </button>
                  ) : (
                    <button disabled={!venueVal} type="submit">
                      Save
                    </button>
                  )}
                </div>
              </form>
            ) : (
              <div className="addOption" onClick={() => setVenue(true)}>
                <AddIcon className="addIcon" />
              </div>
            )}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className="mainTab">
        <div className="bubbles">
          <div className="bLeft">
            <ul>
              {entitiesData &&
                entitiesData.map((item, i) => {
                  return (
                    <li key={item?._id}>
                      <p>{item?.value}</p>
                      <span className="deleteItem">
                        <EditIcon
                          onClick={() => {
                            editItem(item?._id);
                            setID(item?._id);
                          }}
                        />
                        <CancelIcon
                          onClick={() =>{setOpen(true);setDeleteId(item?._id)}}
                        />
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="bRight">
            {venue ? (
              <form onSubmit={handleSubmit}>
                <div className="closeModal">
                  <CloseIcon  onClick={() => {setVenue(false); setVenueVal(''); setIsEdit(false)}} />
                  
                </div>
                <div className="formControl">
                  <TextField
                    placeholder="Music Type"
                    sx={{ p: "0px", width: "100%" }}
                    variant="standard"
                    type="text"
                    margin="none"
                    value={venueVal}
                    onChange={(e) => setVenueVal(e.target.value)}
                  />
                  {isEdit ? (
                    <button disabled={!venueVal} type="submit">
                      Update
                    </button>
                  ) : (
                    <button disabled={!venueVal} type="submit">
                      Save
                    </button>
                  )}
                </div>
              </form>
            ) : (
              <div className="addOption" onClick={() => setVenue(true)}>
                <AddIcon className="addIcon" />
              </div>
            )}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2} className="mainTab">
        <div className="bubbles">
          <div className="locationCity">
            <div className="right">
              <div
                className="addOption"
                onClick={() => navigate("/addCityLocation")}
              >
                <AddIcon className="addIcon" />
              </div>
            </div>
            <div className="table-responsive">
              <table style={{ padding: 0 }}>
                <thead>
                  <tr>
                    <th>City</th>
                    <th>Location</th>
                    <th className="center">Action</th>
                  </tr>
                </thead>
                {entitiesData &&
                  entitiesData.map((item) => {
                    return (
                      <tr key={item?._id}>
                        <td>{item?.value}</td>
                        <td>
                          {item?.subValue?.map((val) => {
                            return (
                              <li className="locationCityVal">
                                {val?.name || ""}
                              </li>
                            );
                          })}
                        </td>
                        <td className="center">
                          <Tooltip title="Edit">
                            <IconButton>
                              <EditIcon
                                onClick={() =>
                                  navigate(`/editCityLocation/${item?._id}`)
                                }
                                style={{ fill: "green" }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton>
                              <DeleteIcon
                                style={{ fill: "red" }}
                                onClick={() =>{setOpen(true);setDeleteId(item?._id)}}
                              />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3} className="mainTab">
        <div className="bubbles">
          <div className="bLeft">
            <ul>
              {entitiesData &&
                entitiesData.map((item, i) => {
                  return (
                    <li key={item?._id}>
                      <p>{item?.value}</p>
                      <span className="deleteItem">
                        <EditIcon
                          onClick={() => {
                            editItem(item?._id);
                            setID(item?._id);
                          }}
                        />
                        <CancelIcon  onClick={() =>{setOpen(true);setDeleteId(item?._id)}}/>
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="bRight">
            {venue ? (
              <form onSubmit={handleSubmit}>
                <div className="closeModal">
                  <CloseIcon  onClick={() => {setVenue(false); setVenueVal(''); setIsEdit(false)}} />
                </div>
                <div className="formControl">
                  <TextField
                    placeholder="Enter Venue Type"
                    sx={{ p: "0px", width: "100%" }}
                    variant="standard"
                    type="text"
                    margin="none"
                    value={venueVal}
                    onChange={(e) => setVenueVal(e.target.value)}
                  />
                  {isEdit ? (
                    <button disabled={!venueVal} type="submit">
                      Update
                    </button>
                  ) : (
                    <button disabled={!venueVal} type="submit">
                      Save
                    </button>
                  )}
                </div>
              </form>
            ) : (
              <div className="addOption" onClick={() => setVenue(true)}>
                <AddIcon className="addIcon" />
              </div>
            )}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={4} className="mainTab">
        <div className="bubbles">
          <div className="bLeft">
            <ul>
              {entitiesData &&
                entitiesData.map((item, i) => {
                  return (
                    <li key={item?._id}>
                      <p>{item?.value}</p>
                      <span className="deleteItem">
                        <EditIcon
                          onClick={() => {
                            editItem(item?._id);
                            setID(item?._id);
                          }}
                        />
                        <CancelIcon   onClick={() =>{setOpen(true);setDeleteId(item?._id)}} />
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="bRight">
            {venue ? (
              <form onSubmit={handleSubmit}>
                <div className="closeModal">
                  <CloseIcon  onClick={() => {setVenue(false); setVenueVal(''); setIsEdit(false)}} />
                </div>
                <div className="formControl">
                  <TextField
                    placeholder="Even Type Features"
                    sx={{ p: "0px", width: "100%" }}
                    variant="standard"
                    type="text"
                    margin="none"
                    value={venueVal}
                    onChange={(e) => setVenueVal(e.target.value)}
                  />
                  {isEdit ? (
                    <button disabled={!venueVal} type="submit">
                      Update
                    </button>
                  ) : (
                    <button disabled={!venueVal} type="submit">
                      Save
                    </button>
                  )}
                </div>
              </form>
            ) : (
              <div className="addOption" onClick={() => setVenue(true)}>
                <AddIcon className="addIcon" />
              </div>
            )}
          </div>
        </div>
      </TabPanel>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <Box sx={style}>
        <div className="logout_modal">
      <h3>Are you sure, you want to delete?</h3>
      <div className="btn_div">
        <button className="btn" onClick={() => deleteItem(deleteId)}>
          Yes
        </button>
        <button
          className="out-btn"
          onClick={() => setOpen(false)}
        >
          No
        </button>
      </div>
    </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default CommonTab;

