export const CmsConstant = {
    GET_CMS_REQUEST: "GET_CMS_REQUEST",
    GET_CMS_LOADING: "GET_CMS_LOADING",
    GET_CMS_SUCCESS: "GET_CMS_SUCCESS",
    GET_CMS_ERROR: "GET_CMS_ERROR",

    Add_CMS_REQUEST: "Add_CMS_REQUEST",
    Add_CMS_LOADING: "Add_CMS_LOADING",
    Add_CMS_SUCCESS: "Add_CMS_SUCCESS",
    Add_CMS_ERROR: "Add_CMS_ERROR",
}