import { AuthConstant } from "../constant";

const initialState = {
  isLoading: false,
  getProfileData: null,
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthConstant.LOGIN_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.LOGIN_ERROR:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.LOGOUT_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.LOGOUT_ERROR:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.FORGOT_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.FORGOT_SUCCESS:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.FORGOT_ERROR:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.FORGOT_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.FORGOT_SUCCESS:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.FORGOT_ERROR:
      return {
        ...state,
        isLoading: action.loading,
      };
    //reset password
    case AuthConstant.RESET_PASSWORD_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.RESET_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: action.loading,
      };
    //reset password
    case AuthConstant.CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.UPDATE_PROFILE_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.GET_PROFILE_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case AuthConstant.GET_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: action.loading,
        getProfileData: action.res,
      };
    case AuthConstant.GET_PROFILE_ERROR:
      return {
        ...state,
        isLoading: action.loading,
        getProfileData: null,
      };
    default:
      return state;
  }
};
