import React, { useState, useEffect } from "react";
import Layout from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { DeleteBannerAction, GetBannerAction } from "../../redux/actions";
import { Button } from "../../component";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const HomeBanner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bannerData } = useSelector((state) => state.BannerReducer);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    dispatch(GetBannerAction.request());
  }, []);

  return (
    <Layout>
      {bannerData?.length < 6 ? (
        <div className="rightSide">
          <Button
            name="+"
            title="Add Banner"
            onClick={() => navigate("/add-banner")}
          />
        </div>
      ) : null}
      <div className="banners">
        <div className="activeBanner">
          <figure>
            <img
              src={
                bannerData?.length ? bannerData[activeIndex]?.bannerImage : null
              }
              alt="Banner Img"
            />
          </figure>
        </div>
        <ul className="listbanner">
          {bannerData &&
            bannerData.map((item, index) => (
              <li key={item?.id} onClick={() => handleItemClick(index)}>
                <figure>
                  <img src={item?.bannerImage} alt="Banner Img" />
                </figure>
                <div className="close">
                  <CloseIcon
                    onClick={() =>
                      dispatch(DeleteBannerAction.request(item?._id))
                    }
                  />
                </div>
              </li>
            ))}
        </ul>
      </div>
    </Layout>
  );
};

export default HomeBanner;
