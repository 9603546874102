import React from "react";

const Button = (props) => {
  const { name, onClick, title, disabled } = props;
  return (
    <button disabled={disabled} type="submit" title={title} onClick={onClick}>
      {name}
    </button>
  );
};

export default Button;
