import { BannerConstant } from "../constant";

const GetBannerAction = {
  request: (body, setTotalCount) => ({
    type: BannerConstant.GET_BANNER_REQUEST,
    body,
    setTotalCount,
  }),
  loading: () => ({
    type: BannerConstant.GET_BANNER_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: BannerConstant.GET_BANNER_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: BannerConstant.GET_BANNER_ERROR,
    loading: false,
  }),
};

const AddBannerAction = {
  request: (body, navigate) => ({
    type: BannerConstant.ADD_BANNER_REQUEST,
    body,
    navigate,
  }),
  loading: () => ({
    type: BannerConstant.ADD_BANNER_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: BannerConstant.ADD_BANNER_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: BannerConstant.ADD_BANNER_ERROR,
    loading: false,
  }),
};

const DeleteBannerAction = {
  request: (id) => ({
    type: BannerConstant.DELETE_BANNER_REQUEST,
    id,
  }),
  loading: () => ({
    type: BannerConstant.DELETE_BANNER_LOADING,
    loading: true,
  }),
  success: (res) => ({
    type: BannerConstant.DELETE_BANNER_SUCCESS,
    loading: false,
    res,
  }),
  error: () => ({
    type: BannerConstant.DELETE_BANNER_ERROR,
    loading: false,
  }),
};

export { GetBannerAction, AddBannerAction, DeleteBannerAction };
